import { storeInstance } from "../../Services/axios.service";
import { hundred } from "../../Utils";
import { newReserveHelper, TOKEN_DETAILS } from "../../interfaces/commonInterfaces";
import { getDeadline, timeoutPromise } from "../libfiService_Common";
import { newtokensAllowanceHelperFunction } from "./TokensAllowancesHelper";
import { handleErrorNoRetryButton, handlePendingApproval, handleSuccess, handleError } from "./TransactionModalHelper";
import { addBreadcrumb, captureException } from './../../SentryContext';
import LibfiServiceAAM from "../libfiService_AAM";


// Slippage
const { slippage, TransactionDeadline } : { slippage: number, TransactionDeadline: number } = storeInstance?.getState()?.user;


/**
* This Helper Function calculates the Reserves of A Particular Pool
* @param data is an object that contains various params required for this function
* @returns reserves of the token in the input field that user has not selected
**/
const getReservesHelper = async (data: newReserveHelper) => {
  const {
    pairAddress,
    tokenOneAddress,
    decimals0,
    tokenTwoAddress,
    decimals1,
    input1,
    input2,
    selectedField,
    max
  } = data;

  try {
    const convertedAmountOne: bigint = max
      ? BigInt(input1)
      : BigInt( input1 * 10 ** decimals0 );

    const convertedAmountTwo: bigint = max
      ? BigInt(input2)
      : BigInt( input2 * 10 ** decimals1 );

    const [
      reserves,
      tokens
    ] = await Promise.all([
        timeoutPromise(LibfiServiceAAM.getReserves(pairAddress), 10000),
        timeoutPromise(LibfiServiceAAM.getTokensThroughPairAddress(pairAddress), 10000)
    ]);

    const _reserve0 = reserves[0];
    const _reserve1 = reserves[1];
    const tokenA = tokens.token0;
    const tokenB = tokens.token1;

    if (Number(_reserve0) === 0 || Number(_reserve1) === 0) return "0";

    let res: string;

    if (selectedField == "field1") {
      if (tokenOneAddress?.toLowerCase() == tokenA?.toLowerCase()) {
        let ratio = ( BigInt(convertedAmountOne) * BigInt(_reserve1) ) /  BigInt(_reserve0);
        return ratio;
      } else {
        let ratio = ( BigInt(convertedAmountOne) * BigInt(_reserve0) ) /  BigInt(_reserve1);
        return ratio;
      }
    } else if (selectedField == "field2") {
      if (tokenTwoAddress?.toLowerCase() == tokenA?.toLowerCase()) {
        let ratio = ( BigInt(convertedAmountTwo) * BigInt(_reserve1) ) / BigInt(_reserve0);
        return ratio;
      } else {
        let ratio = ( BigInt(convertedAmountTwo) * BigInt(_reserve0) ) / BigInt(_reserve1);
        return ratio;
      }
    }
  } catch (error) {
    return "0";
  }
};


/**
* This Helper Function Handles the Addition of Liquidity for both Native and Non-Native Tokens
**/
const addLiquidityHelperFunction = async (
  tokenDetails: TOKEN_DETAILS,
  inputOne: { inputValue: any; convertedValue: any },
  inputTwo: { inputValue: any; convertedValue: any },
  walletAddress: string,
  handleOpen: Function,
  setModalData: Function,
  t: (key: string) => string,
) => {
  try {

    handlePendingApproval(setModalData, t('ammPoolsAddLiquidityHeading'), t('ammPoolsAddLiquidityInit'));
    handleOpen();

    const inputdata = {
      tokenDetails,
      input1: inputOne,
      input2: inputTwo,        
      walletAddress, 
      selectedField: "None",
      t
    };

    addBreadcrumb('LiquidityAddition', '[addLiquidityHelperFunction] Input Data', 'info', { inputdata: inputdata })
    if ( !inputdata.tokenDetails || !inputdata.input1 || !inputdata.input2|| !inputdata.walletAddress || !inputdata.selectedField ) throw new Error("Input Validation Failed...");
    addBreadcrumb('LiquidityAddition', 'Input Validation Successful', 'info');

    const TokensAllowanceHelperResponse = await newtokensAllowanceHelperFunction(setModalData, inputdata);

    handlePendingApproval(setModalData, t('ammPoolsAddLiquidityHeading'), 
      t('ammPoolsTrxConfirmText').replace('{{token1}}', tokenDetails?.token0Name || "").replace('{{token2}}', tokenDetails?.token1Name || ""));

    let addLiquidityResponse;

    if (tokenDetails?.isToken0Native || tokenDetails?.isToken1Native) {
            
      const amountTokenMin: bigint = tokenDetails?.isToken0Native
      ? BigInt(inputTwo?.convertedValue) * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred) 
      : BigInt(inputOne?.convertedValue) * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred); 

      const amountTokenETHMin: bigint = tokenDetails?.isToken0Native
      ? BigInt(inputOne?.convertedValue) * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred) 
      : BigInt(inputTwo?.convertedValue) * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred);

      addLiquidityResponse = await LibfiServiceAAM.addETHLiquidity({
        inputOne: tokenDetails?.isToken0Native
          ? BigInt(inputOne?.convertedValue)
          : BigInt(inputTwo?.convertedValue),
        inputTwo: tokenDetails?.isToken0Native
          ? BigInt(inputTwo?.convertedValue)
          : BigInt(inputOne?.convertedValue),
        NonNativeTokenAddress: tokenDetails?.isToken0Native
          ? tokenDetails?.token1Address
          : tokenDetails?.token0Address,
        amountTokenMin,
        amountTokenETHMin,
        walletAddress,
        deadLine: getDeadline()
      });

    } else {
    
      const amountTokenAMin: bigint = BigInt(inputOne?.convertedValue) * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred);
      const amountTokenBMin: bigint = BigInt(inputTwo?.convertedValue) * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred);

      addLiquidityResponse = await LibfiServiceAAM.addLiquidity({
        tokenOneAddress: tokenDetails?.token0Address,
        tokenTwoAddress: tokenDetails?.token1Address,
        amountTokenADesired: BigInt(inputOne?.convertedValue),
        amountTokenBDesired: BigInt(inputTwo?.convertedValue),
        amountTokenAMin: amountTokenAMin,
        amountTokenBMin: amountTokenBMin,
        walletAddress,
        deadLine: getDeadline()
      });

    }
    
    addBreadcrumb('LiquidityAddition', '[addLiquidityHelperFunction] Liquidity Added', 'info');

    return handleSuccess(setModalData, t('ammPoolsAddLiquidityHeading'),
      t('ammPoolsTrxSuccessText').replace('{{token1}}', tokenDetails?.token0Name || "").replace('{{token2}}', tokenDetails?.token1Name || ""), addLiquidityResponse.transactionHash);

  } catch (error) {
    addBreadcrumb('LiquidityAddition', '[addLiquidityHelperFunction] Error Occured', 'info', { errorMessage: error });
    if (error && !error.toString().includes("User rejected the request")) {
      captureException(error);
    }
    return handleError(setModalData, t('ammPoolsAddLiquidityFailedHeading'), 
      t('ammPoolsTrxFailedText').replace('{{token1}}', tokenDetails?.token0Name || "").replace('{{token2}}', tokenDetails?.token1Name || ""));
  }
};

export {
  getReservesHelper,
  addLiquidityHelperFunction
};
