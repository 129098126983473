import "./OrderDetailsModal.scss";
import { Modal } from "react-bootstrap";
import { CloseIcon } from "../../../../../Assets/Images/Icons/SvgIcons";
import { ClassNames } from "@emotion/react";


type OrderTransferDetailsProps = {
    show: boolean;           // Indicates whether the transfer details modal is shown or hidden
    handleClose: Function;   // Function to handle closing the transfer details modal
    className: string;       // CSS class name for styling the transfer details modal
}

const OrderTransferDetailsModal: React.FC<React.PropsWithChildren<OrderTransferDetailsProps>> = ({ children, show, handleClose, className }) => {
    return (
        <Modal
            backdropClassName="order-details-bg"
            centered
            show={show}
            className="order-details-modal"
        >
            <button
                className="modal_close_btn"
                onClick={() => handleClose()}
            >
                <CloseIcon />
            </button>
            <Modal.Body>
                <div className={className}>
                    {children}
                </div>
            </Modal.Body>
        </Modal>

    )
}

export default OrderTransferDetailsModal;