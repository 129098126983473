import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import SubHeader from "../../../../Common/SubHeader/SubHeader";
import "./OnOffRamp.scss";
const OnOffRamp = () => {
    const { t } = useTranslation();
    const navigationLinks = [
        {
            id: 1,
            label: t('onOffRampBuy'),
            to: "/on-off-ramp/trade/buy"
        },
        {
            id: 2,
            label: t('onOffRampSell'),
            to: "/on-off-ramp/trade/sell",
        },
    ];
    return (
        <section>
            <Row>
                <Col lg={12}>
                    <SubHeader navigationLinks={navigationLinks} className="on-off-ramp-subheader"/>
                    <Outlet />
                </Col>
            </Row>
        </section>
    )
}

export default OnOffRamp;