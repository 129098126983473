  import React, { useEffect, useState } from "react";
  import { useUserUpdateRequest, useOtpVerificationRequest } from "@dynamic-labs/sdk-react-core";
  import { Container } from "react-bootstrap";
  import CommonHeading from "../../../Common/Heading/Heading";
  import { Modal } from "react-bootstrap";
  import { CameraIcon, CloseIcon, EditIcon } from "../../../../Assets/Images/Icons/SvgIcons";
  import { useFormik } from "formik";
  import ButtonCustom from "../../../Common/Button/ButtonCustom";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation, useNavigate } from "react-router-dom";
  import { custmizeAddress } from "../../../../Services/common.service";
  import { createUserProgress } from "../../../../Redux/Actions/user.action";
  import { loginSchema } from "../../../FormikSchemas/addProfileSchema";
  import InputCustom from "../../../Common/FormInputs/Input/Input";
  import { imageUpload } from "./uploadImageFile";
  import { setUserOnboardingData } from "../../../../Redux/Slices/user.slice";
  import { DATA_INSERTED, GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
  import { handleUserProgress } from "../../../../Redux/Actions/user.action";
  import { VIDEO_URL } from "../../../../Utils";
  import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
  import { useTranslation } from "react-i18next";
  import toast from "react-hot-toast";
  import OtpInput from 'react-otp-input';
  import { addWallet } from "../../../../Redux/Actions/user.action";
  import "./AddProfile.scss";



  const AddProfile = () => {

    // State variables
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { verifyOtp } = useOtpVerificationRequest();
    const { updateUser } = useUserUpdateRequest();
    const { user, primaryWallet } = useDynamicContext();
    const { t, i18n } = useTranslation();
    const userwalletAddress: string = useSelector( (state: any) => state?.user?.walletAddress );
    const userDetailsData: any = useSelector( (state: any) => state?.user?.userDetails );
    const [imageUrl, setImageUrl] = useState<string>("");
    const [imagePath, setImagePath] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otp, setOtp] = useState<string>();
    const [verificationUUID, setVerificationUUID] = useState<string>();
    const [otpError, setOtpError] = useState<{ status: boolean, message: string }>({ status: false, message: "" });


    // Hooks

    /**
    * This function initializes the "useFormik" hook with initial
    * values from userPreviewDetails and validates the name and the email
    **/  
    const formik = useFormik({
      initialValues: {
        name: userDetailsData?.name,
        email: userDetailsData?.emailAddress,
      },
      // Validate Name & Email
      validationSchema: (values) => loginSchema(t),
      onSubmit() {},
    });

    /**
    * This useEffect hook listens for browser navigation events (like back/forward)
    **/ 
    useEffect(() => {
      window?.addEventListener("popstate", function (event) {
        navigate(location?.pathname);
      });
    }, [location]);

    /**
    * This useEffect hook retrieves the user profile from the database
    * NB: This is particularyly needed in case the user clicks on the back button when it is on the Terms & Conditions
    **/ 
    useEffect(() => {
      if (user?.userId?.length) getUserDetails();
    }, []);


    // Functions

    /**
    * This function retrieves information from the user profile and 
    * initializes the "useFormik" hook with initial values as well as the image preview
    **/ 
    const getUserDetails = async () => {
      const result: GET_USER | undefined = await handleUserProgress({
        dispatch,
        userId: user?.userId,
        from: "Profile",
      });
      if (result) {
        if (result?.data != null && result?.data != undefined) {

          formik.setFieldValue("name", result.data.name);
          formik.setFieldValue("email", result.data.emailAddress);
          
          if (result.data.image) {
            setImageUrl(VIDEO_URL + result.data.image);
            setImagePath(result.data.image);
          }

        } 
      }
    };

    /**
    * This function checks if a selected file is valid;
    * If so, it updates the UI to display a preview of the file
    **/
    const handleFileSelect = async (event: any) => {
      try {
        setError("");
        const file = event.target.files[0];
        const successfulFileCheck = await checkFileValidity(file);

        if (successfulFileCheck) {
          const image = new Image();
          image.onload = async () => {
            // Set the image path and URL
            const result: any = await imageUpload(file,userwalletAddress + "_" + file.name);
            setImagePath(result?.request?.params?.Key);
            const imageUrl = URL.createObjectURL(file);
            setImageUrl(imageUrl);
          };
          image.src = URL.createObjectURL(file);
        }
      } catch (err) {
        console.log(err);
      }
    };

    /**
    * This function checks the file format and size
    **/
    const checkFileValidity = async (file: any) => {
      const maxSize = 4 * 1024 * 1024; // Maximum file size in bytes (= 1MB)

      // This is the list of allowed image formats
      const allowedFormats = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/tiff",
        "image/svg+xml",
        "image/webp",
      ];

      // Validate file format & Size
      if (!allowedFormats.includes(file.type)) {
        toast.error(t('updateProfileInvalidImageFormatError'), { id: "FileValidity" } );
        return false;
      } else if (file.size > maxSize) {
        toast.error(t('updateProfileFileSizeExceedError').replace('{{size}}', '4MB'), { id: "FileValidity" } );
        return false;
      } else {
        return true;
      }
    }

    /**
    * This function resets the preview to the default profile image
    **/
      const handleDeleteImage = () => {
        setImageUrl("");
        setImagePath("");
        setError("");
      };

    /**
    * This function pushes the profile information that have been updated 
    * to the LibFi database and to the Dynamic.xyz database if need be
    **/
    const handleProfileSubmit = async () => {

      const addUserProfileInformation: DATA_INSERTED | false = await createUserProgress({
        userId: user?.userId,
        email: formik?.values?.email,
        name: formik?.values?.name,
        image: imagePath,
        language: i18n.language,
        shariah: false,
        value: 90,
        label: "/onboarding/terms-conditions",
        dispatch
      });
      if (!addUserProfileInformation) return;

      const walletAddress = primaryWallet?.address;

      if (walletAddress?.length) {
        const addWalletResponse: DATA_INSERTED | false = await manageWalletAddition(walletAddress);
        if (!addWalletResponse) return;
      }

      dispatch( setUserOnboardingData({ progress: 90, path: "/onboarding/terms-conditions" }) );
      navigate("/onboarding/terms-conditions");
      updateDynamicUserInfo(formik?.values?.name);

    };

    /**
    * This Function manages the Addition
    * of the Wallet Address into the User Profile 
    **/
    const manageWalletAddition = async (walletAddress) => {
      const addWalletResponse: DATA_INSERTED | false = await addWallet({
        userId: user?.userId,
        address: walletAddress,
        dispatch
      });
      return addWalletResponse;
    };

    /**
    * This function enables to skip this step 
    * and go to the Terms and Conditions
    **/
    const handleSkip = async () => {

      const walletAddress = primaryWallet?.address;

      if (walletAddress) {
        const addWalletResponse: DATA_INSERTED | false = await manageWalletAddition(walletAddress);
        if (!addWalletResponse) return;
      }

      dispatch(setUserOnboardingData({ progress: 75, path: "/onboarding/terms-conditions" }));
      navigate("/onboarding/terms-conditions");
    };

    /**
    * This function pushes some profile information to the Dynamic.xyz database
    **/
    const updateDynamicUserInfo = async (name) => {
      try {
        // Call the updateUser function with the new values entered by the user
        await updateUser({
          username: name,
        });
      } catch (e) {
        console.log("Error", e);
      }
    };

    /**
    * This function pushes the new email address to the 
    * Dynamic.xyz database. This generates an OTP email for validation.
    **/
    const validateEmailAddress = async (email) => {
      try {
        // Call the Dynamic.xyz updateUser function with the new email address
        const { isEmailVerificationRequired, updateUserProfileResponse } = await updateUser({ email: email });

      // If email verification is required, show the OTP modal
      if (isEmailVerificationRequired) {
        setVerificationUUID(updateUserProfileResponse.emailVerification?.verificationUUID);
        setShowOtpModal(true);
        } else {
          handleProfileSubmit();
        }
      } catch (e) {
        showOtpModal ? setOtpError({ status: true, message: t('otpErrorGenericCode') }) : toast.error(t('otpErrorGenericCode'), { id: "ValidateEmailAddress" } );
      }
    };

    /**
    * This function verifies the OTP code 
    * received on the new email address 
    **/
    const verifyOTP  = async () => {
      if (!otp || otp.length < 6) return false;
      try {
        const verificationToken = {
          verificationToken: otp,
          verificationUUID: verificationUUID
        };
        await verifyOtp(verificationToken.verificationToken, "email" , verificationToken.verificationUUID);
        handleProfileSubmit();
      } catch (error: any) {
        if (error.toString().includes("This email is associated to another account")) {
          setShowOtpModal(false);
          toast.error(t('otpErrorExistingEmail'), { id: "EmailAlreadyExists" } );
        } else {
          setOtpError({ status: true, message: t('otpErrorGenericCode') });
        }
      } finally {
        setOtp("");
      }
      return false;
    };

    return (
      <>
        <section className="AddProfile">
            <Container>
              <CommonHeading
                heading={
                  primaryWallet?.connector?.isEmbeddedWallet
                    ? `${userDetailsData?.firstname}, It's Time To Customize Your Profile! `
                    : primaryWallet?.connected
                      ? `${t('onboardWalletGreeting')} ${custmizeAddress(primaryWallet?.address)}!`
                      : ""
                }
                subText={t('onboardWalletDesc')}
                centered
                className="AddProfileHeading"
              />
              <form>
                {!imageUrl ? (
                  <div className="inputFileBox">
                    <label className="inputFile">
                      <input
                        type="file"
                        onChange={handleFileSelect}
                        accept="image/*"
                      />
                      <>
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt={t('updateProfileSelectedFile')}
                            className="profile_img"
                          />
                        ) : (
                          <>
                            <CameraIcon />
                            <span>{t('onboardAddAvatar')}</span>
                            {error && <p className="error_msg text-center">{error}</p>}
                          </>
                        )}
                      </>
                    </label>
                  </div>
                ) : (
                  <button type="button" className="inputFilePreview">
                    <img src={imageUrl} alt="" />
                    <div className="inputFilePreviewIcons">
                      <span onClick={handleDeleteImage}>
                        <CloseIcon />
                      </span>
                      <span>
                        <input
                          type="file"
                          onChange={handleFileSelect}
                          accept="image/*"
                        />
                        <EditIcon />
                      </span>
                    </div>
                  </button>
                )}
                <InputCustom
                  name="name"
                  value={formik?.values?.name}
                  onChange={(e: any) => formik?.handleChange(e)}
                  maxLength={20}
                  error={formik?.errors?.name ? formik?.errors?.name : null}
                  label={
                    <>
                      {t('onboardProfileName')}
                    </>
                  }
                  className="inputField"
                />
                { !primaryWallet?.connector?.isEmbeddedWallet && (
                  <>
                  <InputCustom
                    name="email"
                    type="email"
                    value={formik?.values?.email}
                    onChange={(e: any) => {
                      formik?.handleChange(e);
                    }}
                    label={
                      <>
                        {t('onboardEmail')}
                      </>
                    }
                    isInvalid={formik?.touched?.email && !!formik?.errors?.email}
                    className="inputField mb-0"
                    error={formik?.errors?.email ? formik?.errors?.email : null}
                  />
                  <p className="usedText">
                    {t('onboardEmailDesc')}
                  </p>
                </>
                )}
                <ButtonCustom
                  title={t('onboardSaveProfile')}
                  fluid
                  onClick={() => formik?.values?.email ? validateEmailAddress(formik?.values?.email) : handleProfileSubmit() }
                  className="buttonSpacing"
                  disabled= { primaryWallet?.connector?.isEmbeddedWallet ? ( imageUrl.length == 0 && !formik?.values?.name || Boolean(formik?.errors?.name) ) : ( imageUrl.length == 0 && !formik?.values?.name && !formik?.values?.email || Boolean(formik?.errors?.name) || Boolean(formik?.errors?.email) )}
                />
                <ButtonCustom
                  title={t('onboardSkipStep')}
                  fluid
                  onClick={() => handleSkip()}
                />
              </form>
            </Container>


          {/* Display the email verification modal */}
          {showOtpModal && (
            <Modal
              centered
              className="profile_modal"
              backdrop= "static"
              keyboard= { false }
              show={ true }
              onHide={() => {
                setOtpError({ status: false, message: "" });
                setShowOtpModal(false);
                setOtp("");
              }}
            >
            <Modal.Header>
              <Modal.Title>
                { t('otpValidateYourEmail') }
              </Modal.Title>
              <button
                className="modal_close_btn"
                onClick={() => {
                  setOtpError({ status: false, message: "" });
                  setShowOtpModal(false);
                  setOtp("");
                }}
              >
                <CloseIcon />
              </button>
            </Modal.Header>
            <Modal.Body> 
              <div className="otp-section">
                <div className="otp-body">
                  <div className="otp-input-box otp-box">

                    {otpError.status && (
                      <div className="otp-error-msg"> { otpError.message } </div>
                    )}

                    <OtpInput
                        value={otp}
                        onChange={(value) => {
                            if (otpError.status) { setOtpError({ status: false, message: "" }); };
                            setOtp(value);
                        }}
                        numInputs={6}
                        containerStyle="otp-container Input input-custom-margin"
                        inputStyle="otp-input"
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                    />

                    <div className="d-flex align-items">
                      <ButtonCustom
                        title={t('otpClearInput')}
                        className="bordered-blue"
                        fluid
                        onClick={() => {
                          if (otpError.status) { setOtpError({ status: false, message: "" }); };
                            setOtp("");
                          }}
                      />
                      <ButtonCustom
                        title={t('otpInputConfirmation')}
                        fluid
                        className="bordered-blue"
                        disabled={ !otp || otp.length < 6 }
                        onClick={() => {
                          verifyOTP();
                        }}
                      />
                    </div>

                    <div className="otp-resend">
                      <span style={{ marginRight: '5px' }}>{t('otpVerificationOTPNotReceived')}</span> 
                      <a href="javascript:void(0)"
                          onClick={(e) => {
                            validateEmailAddress(formik?.values?.email); 
                            toast.success(t('otpSuccessResent'));
                            if (otpError.status) { setOtpError({ status: false, message: "" }); };
                              setOtp("");
                            }}>{t('otpVerificationResend')}
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </Modal.Body>
            </Modal>
          )}
        </section>
      </>
    );
  };

  export default AddProfile;
