import { commonDynamicSwapData } from "../../interfaces/contractCallInterfaces";
import { commonDynamicSwapTokens } from "../../interfaces/commonInterfaces";
import { storeInstance } from "../../Services/axios.service";
import { newtokensAllowanceHelperFunction } from "./TokensAllowancesHelper";
import { getDeadline } from "../libfiService_Common";
import { handleWriteContractForETH, handleWriteContractForTokens } from "../libfiService_Swap";
import { hundred } from "../../Utils"
import { handleError, handlePendingApproval, handleSuccess } from "./TransactionModalHelper";
import { addBreadcrumb, captureException } from './../../SentryContext';


// Slippage & Transaction Deadline
const { slippage, TransactionDeadline } : { slippage: number, TransactionDeadline: number } = storeInstance?.getState()?.user;

export const newSwapHelperFunction = async (setModalData, setShow, inputdata: commonDynamicSwapTokens): Promise<boolean> => {

    const {
        tokenDetails,
        input1,
        input2,
        walletAddress,
        selectedField,
        t
    } = inputdata;

    try {

        setShow(true)

        addBreadcrumb('Swap', '[SwapHelper] Input Data', 'info', { inputdata: inputdata })
        if ( !tokenDetails || !input1 || !input2.convertedValue || !walletAddress || !selectedField ) throw new Error("Input Validation Failed...");
        addBreadcrumb('Swap', 'Input Validation Successful', 'info');

        addBreadcrumb('Swap', '[SwapHelper] Slippage', 'info', { Slippage: slippage + "%" });
        addBreadcrumb('Swap', '[SwapHelper] Transaction Deadline', 'info', { TxDeadline: TransactionDeadline + " minutes" });
               
        handlePendingApproval(setModalData, t('tradeMarketSwapHeading'), t('tradeMarketSwapInit'));

        const TokensAllowanceHelperResponse = await newtokensAllowanceHelperFunction(setModalData, inputdata);

        handlePendingApproval(setModalData, t('tradeMarketSwapHeading'), t('tradeMarketSwapTrxConfirmText').replace('{{token1}}', tokenDetails?.token0Name  || "").replace('{{token2}}', tokenDetails?.token1Name || ""));

        let data: commonDynamicSwapData | undefined;
        let contractMethod: string = "";

        if (selectedField == "field1") {

            data = {
                walletAddress,
                amountIn: BigInt(input1.convertedValue),
                amountOutMin: BigInt(input2.convertedValue),
                path: [tokenDetails.token0Address, tokenDetails.token1Address],
                to: walletAddress,
                deadLine: getDeadline(),
                slippageTolerance: slippage,
                field: selectedField
            };

            if (!data.amountOutMin) {
                throw new Error("data.amountOutMin is undefined...");
            }

            const amountOutMinWithSlippageTolerance = data.amountOutMin * ( BigInt(100) - BigInt(data.slippageTolerance) ) / BigInt(hundred);
            data.amountOutMinWithSlippageTolerance = amountOutMinWithSlippageTolerance;

            contractMethod = tokenDetails.isToken0Native
                ? "swapExactETHForTokens"
                : tokenDetails.isToken1Native
                    ? "swapExactTokensForETH"
                    : "swapExactTokensForTokens";

        } else if (selectedField == "field2") {

            data = {
                walletAddress,
                amountOut: BigInt(input2.convertedValue),
                amountInMax: BigInt(input1.convertedValue),
                path: [tokenDetails.token0Address, tokenDetails.token1Address],
                to: walletAddress,
                deadLine: getDeadline(),
                slippageTolerance: slippage,
                field: selectedField
            };

            if (!data.amountInMax) {
                throw new Error("data.amountInMax is undefined...");
            }

            const amountInMaxWithSlippageTolerance = BigInt(data.amountInMax) * ( BigInt(100) + BigInt(data.slippageTolerance) ) / BigInt(hundred);
            data.amountInMaxWithSlippageTolerance = amountInMaxWithSlippageTolerance;

            contractMethod = tokenDetails.isToken0Native
                ? "swapETHForExactTokens"
                : tokenDetails.isToken1Native
                    ? "swapTokensForExactETH"
                    : "swapTokensForExactTokens";
            
        }

        const swapResponse = tokenDetails.isToken0Native
            ? await handleWriteContractForETH(contractMethod, data)
            : await handleWriteContractForTokens(contractMethod, data);

        addBreadcrumb('Swap', '[SwapHelper] Swap Executed', 'info');

        return handleSuccess(setModalData, t('tradeMarketSwapHeading'), 
            t('tradeMarketSwapTrxSuccessText').replace('{{token1}}', tokenDetails?.token0Name  || "").replace('{{token2}}', tokenDetails?.token1Name || ""), swapResponse.transactionHash)

    } catch (error) {
        addBreadcrumb('Swap', '[SwapHelper] Error Occured', 'info', { errorMessage: error });
        if (error && !error.toString().includes("User rejected the request")) {
            captureException(error);
        }
        return handleError(setModalData, 'tradeMarketSwapHeading', t('tradeMarketSwapTrxFailedText').replace('{{token1}}', tokenDetails?.token0Name || "").replace('{{token2}}', tokenDetails?.token1Name || ""));
    }
};
