import React, { ReactNode, useState, useEffect } from "react";
import {
  RightArrowCirlce,
  WalletIcon,
  InfiniteSpinner,
} from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../Button/ButtonCustom";
import Shimmer from "../../Shimmer/Shimmer";
import { useTranslation } from "react-i18next";
import { useDynamicContext, DynamicWidget, useIsLoggedIn } from '@dynamic-labs/sdk-react-core';
import "./CommonCard.scss";


type propTypes = {
  cardTitle?: string;
  viewAllNavigate?: string;
  className?: string;
  children?: ReactNode;
  contentBg?: boolean;
  contentClassName?: string;
  noHeaderSpacing?: boolean;
  forceDisplay?: boolean;
  loader?: boolean;
  scrollingBar?: boolean; 
};


const CommonCard = (props: propTypes) => {

  const {
    cardTitle,
    className,
    children,
    contentClassName,
    contentBg,
    noHeaderSpacing,
    forceDisplay,
    loader,
    scrollingBar,
  } = props;

  const { primaryWallet, setShowDynamicUserProfile } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className={scrollingBar ? "CommonCard-container scrolling" : "CommonCard-container"}>
      <div
        className={`Card ${noHeaderSpacing ? "headerNoSpacing" : ""} ${
          className || ""
        }`}
      >
        <div className="CardHeader">
          <h3>{cardTitle}</h3>
        </div>

        <div
          className={`position-relative ${contentClassName || ""} ${
            contentBg ? "contentBg" : ""
          }`}
        >
          {loader ? <Shimmer height={440} width={349} /> : children}

          { !isLoggedIn && !forceDisplay ?
            <>
              <div className="wallet_not_connected">
                <DynamicWidget variant='modal' />
              </div>
            </>
            : null 
          }

          {
            isLoggedIn && !primaryWallet?.address && !forceDisplay ? (
              <div className="wallet_not_connected">
                <ButtonCustom
                  title={
                    <>
                      <WalletIcon /> 
                      Select A Wallet
                    </>
                  }
                  show={show}
                  setShow={setShow}
                  onClick={() => setShowDynamicUserProfile(true)}
                />
              </div>
            ) : null
          }

        </div>
      </div>
    </div>
  );
};

CommonCard.defaultProps = {
  forceDisplay: false,
};

export default CommonCard;
