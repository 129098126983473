import { callApiPostMethod } from "../../Redux/Actions/api.action";
import { POOLS } from "../../Redux/Actions/apiResponseInterfaces";
import { ZERO_ADDRESS } from "../../Utils";
import { tokenCollection } from "../../Services/dynamicContractDetails";
import LibfiServiceCommon, { timeoutPromise } from "../libfiService_Common";
import { getTotalReservesOfTokensInPool } from "../libfiService_AAM";
import { divideWithDecimal } from "../../Services/common.service";


export const getPoolData = async ({
  dispatch,
  walletAddress,
  sortBy,
  reverse,
}: {
    dispatch: any;
    walletAddress: string;
    sortBy?: string;
    reverse?: boolean;
  }) => {

  const result = await dispatch(
    callApiPostMethod(
      "GET_POOLS",
      sortBy ? { sort: sortBy, reverse } : {},
      false,
      false
    )
  );

  if (result) {
    
  // Create a map for faster lookup of token details and dollar values
  const tokenDetails = new Map(
      tokenCollection.map((info: any) => [info.address?.toLowerCase(), { img: info.img, isNative: info.isNative }])
    );
      
    // Function to handle each data item
    const processData = async (data: any) => {
      if (data?.pairAddress?.toLowerCase() === ZERO_ADDRESS?.toLowerCase()) {
        return null;
      }

      try {
        const [
          totalLiquidity,
          reserves,
          LpDecimals,
        ] = await Promise.all([
          timeoutPromise(LibfiServiceCommon.getTotalSupply(data?.pairAddress, 'pair'), 10000),
          timeoutPromise(getTotalReservesOfTokensInPool(data?.pairAddress, data?.token0Address, data?.token1Address), 10000),
          timeoutPromise(LibfiServiceCommon.getDecimals(data?.pairAddress, 'pair'), 10000),
        ]);

        data.totalLPAmount = divideWithDecimal(totalLiquidity, LpDecimals)

        data.token0Reserve = reserves._reserve0;
        data.token1Reserve = reserves._reserve1;

        data.LPDecimals = LpDecimals;

        data.token0URI = tokenDetails.get(data?.token0Address?.toLowerCase())?.img;
        data.token1URI = tokenDetails.get(data?.token1Address?.toLowerCase())?.img;

        data.token0Native = tokenDetails.get(data?.token0Address?.toLowerCase())?.isNative;
        data.token1Native = tokenDetails.get(data?.token1Address?.toLowerCase())?.isNative;

        data.volumeInUSD = data.volume;
        data.TVL = data?.token0Reserve * data?.token0DollarValue + data?.token1Reserve * data?.token1DollarValue;

        const swapFees = 0.25/100;
        data.apr = (data?.volumeInUSD * swapFees / data?.TVL) * 365; 

        if (walletAddress) {

          const userLPBalance = await LibfiServiceCommon.getBalance(walletAddress, data?.pairAddress, 'pair');
          data.userLPBalance = userLPBalance.calculatedBalance;

          data.userShare = totalLiquidity !== BigInt(0)
                        ? ( Number(userLPBalance.bigIntBalance) * 100 ) / Number(totalLiquidity)
                        : "0.00%";

          data.userShareDollarValue = data.userShare !== 0
                                        ? data.userShare * data.TVL / 100
                                        : 0;

          data.userToken0Balance =  data.userShare !== 0
                                      ? data.userShare * Number(data.token0Reserve) / 100
                                      : 0;
        
          data.userToken1Balance =  data.userShare !== 0
                                      ? data.userShare * Number(data.token1Reserve) / 100
                                      : 0; 
          
        } else {
          data.userLPBalance = 0;
          data.userShare = 0;
          data.userShareDollarValue = 0;
          data.userToken0Balance = 0;
          data.userToken1Balance = 0;
        }
        return data;
      } catch (error) {
        return null;
      }
    };

    // Process all pools data in parallel
    let poolData: POOLS["data"] = await Promise.all(result?.data?.map(processData) || []);

    // Filter out null values
    poolData = poolData.filter(pool => pool !== null);

    return poolData;
  } else {
    return [];
  }
};

