import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/logo/logo.svg";
import ProgressLine from "../../Common/ProgressLine/ProgressLine";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset, setUserOnboardingData, userDetails } from "../../../Redux/Slices/user.slice";
import { handleUserProgress } from "../../../Redux/Actions/user.action";
import { GET_USER } from "../../../Redux/Actions/apiResponseInterfaces";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { changeLanguage, supportedLngs } from "../../../i18n";
import { useDynamicContext, useUserWallets, useEmbeddedWallet, useSwitchWallet, useDynamicEvents } from "@dynamic-labs/sdk-react-core";
import "./MainLayout.scss";


const MainLayout = () => {

  // State variables

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { user, isAuthenticated, primaryWallet } = useDynamicContext();
  const userSteps: any = useSelector( (state: any) => state?.user?.userOnboardingData );


  // Hooks

  /**
  * This useEffect hook redirects users to the LibFi homepage 
  * if they are not authenticated and are on any of the onboarding pages
  **/
  useEffect(() => {
    if (!isAuthenticated && location?.pathname?.includes("onboarding")) {
      dispatch(reset());
      navigate("/");
    }
  }, []);

  /**
  * This useEffect hook triggers the verifyUserProgress function 
  * when the user becomes authenticated
  **/
  useEffect(() => {
    if (isAuthenticated) {
      if (user?.userId?.length) {
        verifyUserProgress();
      }
    }
  }, [isAuthenticated, user]);

  /**
  * This event listener triggers when the user logs out from Dynamic.xyz 
  **/ 
  useDynamicEvents("logout", async () => {
    dispatch(reset());
    console.log("Logout Complete...");
  });

  
  // Functions

  /**
  * This Function redirects users to the appropriate page based on their profile information
  **/
  const verifyUserProgress = async () => {

    // This retrieves the user profile based on the user ID from Dynamic.xyz
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      userId: user?.userId,
      from: "MainL",
    });

    if (!result?.error) {
      dispatch(userDetails(result?.data));
      // If "value" is empty, it shows the user hasn't initiated the onboarding process.
      // Hence, the user is sent back to the onboarding start.
      if (!result?.data?.value) {

      // Avoid redirecting to Welcome page from "add-profile" or "kyc-profile"
      if (primaryWallet?.connected) {
          if (!location?.pathname?.includes("/onboarding") || !location?.pathname?.includes("onboarding/add-profile")) {
            dispatch(setUserOnboardingData({ progress: 0, path: "/onboarding" }));
            navigate("/onboarding");
          }
        } else {
          if (!location?.pathname?.includes("/onboarding") || !location?.pathname?.includes("onboarding/kyc-profile")) {
            dispatch(setUserOnboardingData({ progress: 0, path: "/onboarding" }));
            navigate("/onboarding");
          }
        }
      } else {
        dispatch(setUserOnboardingData({
            progress: result?.data?.value,
            path: result?.data?.label,
          })
        );
        // Any user who has completed the onboarding process will not be redirected to the homepage page
        if ( result?.data?.label?.includes("onboarding") ) {
          navigate(`${result?.data?.label}`);
        }
      }
    };
  };

  return (
    <>
      <main className="MainLayout">
        <header className="MainLayoutHeader">
            <img src={logo} alt="logo" />
          <ul>
            <li>
            <Dropdown>
              <Dropdown.Toggle className="HeaderLanguageDDL">
                {i18n.language?.toUpperCase()}
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    Object.keys(supportedLngs).map((lang) => (<Dropdown.Item onClick={() => changeLanguage(lang)}>{supportedLngs[lang]}</Dropdown.Item>))
                  }                  
              </Dropdown.Menu>
            </Dropdown>
            </li>
            </ul>
        </header>
        <div>
          <Outlet />
        </div>
        <ProgressLine done={userSteps?.progress} />
      </main>
    </>
  );
};

export default MainLayout;
