import { amanaVaultAllowance, commonDynamicSwapTokens } from "../../interfaces/commonInterfaces";
import { handlePendingApproval, handleErrorNoRetryButton, handleSuccess, handleSuccessNoTxHash } from "./TransactionModalHelper";
import { addBreadcrumb } from './../../SentryContext';
import LibfiServiceCommon from "../libfiService_Common";
import LibfiServiceAmanaVault from "../libfiService_Amana_Vault";


export const newtokensAllowanceHelperFunction = async (setModalData, inputdata: commonDynamicSwapTokens) => {

    const {
        tokenDetails,
        input1,
        input2,
        walletAddress,
        selectedField,
        t
    } = inputdata;

    try {

        addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Input Data', 'info', { inputdata: inputdata })
        if ( !tokenDetails || !input1 || !input2|| !walletAddress || !selectedField ) throw new Error("Input Validation Failed...");
        addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Input Validation Successful', 'info');

        const tokenAddresses: { tokenName, tokenAddress; amount; }[] = [];

        if (tokenDetails.isToken0Native) {
            addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Allowance Not Required', 'info')
            return true;
        } else if (tokenDetails.isToken1Native) {
            tokenAddresses.push({ tokenName: tokenDetails.token0Name, tokenAddress: tokenDetails.token0Address, amount: input1.convertedValue });
        } else {
            tokenAddresses.push({ tokenName: tokenDetails.token0Name, tokenAddress: tokenDetails.token0Address, amount: input1.convertedValue }, 
                                    { tokenName: tokenDetails.token1Name, tokenAddress: tokenDetails.token1Address, amount: input2.convertedValue });
        }

        /**
        * Iterate through tokenAddresses to get token allowances and get approvals if need be
        **/ 
        for (const tokenAddress of tokenAddresses) {

            const getTokenAllowance = await LibfiServiceCommon.getAllowance(walletAddress, tokenAddress.tokenAddress, 'dynamic');
            
            if (Number(tokenAddress.amount) > Number(getTokenAllowance) ) {

                handlePendingApproval(setModalData, t('ApprovalHeadingText'), 
                    t('tradeMarketSwapApprovalConfirmText').replace('{{token0}}', tokenAddress.tokenName || ""))
               
                const getTokenApproval = await LibfiServiceCommon.getApproval(tokenAddress.tokenAddress, 'dynamic');  
                
                addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Allowance Provided for ' + tokenAddress.tokenName, 'info');

                handleSuccess(setModalData, 'ApprovalHeadingText', 
                    t('tradeMarketSwapApprovalSuccessText1').replace('{{token}}', tokenAddress.tokenName || ""), "SUCCESS");

            } else {
                addBreadcrumb('AllowanceHelper', '[tokensAllowanceHelper] Sufficient Allowance for ' + tokenAddress.tokenName, 'info');
            }
        }

        return true;

    } catch (error) {
        handleErrorNoRetryButton(setModalData, 'ApprovalHeadingText', t('ApprovalFailText'));
        addBreadcrumb('[AllowanceHelper]', '[tokensAllowanceHelper] Error Occured', 'info',  { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};


export const LPAllowanceHelper = async (setModalData, inputdata) => {
    try {

        const {
            pairAddress,
            walletAddress,
            userLPTokenstoRemove,
            t
        } = inputdata;

        addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] Input Data', 'info', { inputdata: inputdata })

        if ( !pairAddress || !userLPTokenstoRemove ) throw new Error("Input Validation Failed...");

        const getLPAllowance = await LibfiServiceCommon.getAllowance(walletAddress, pairAddress, 'pair');

        if (Number(userLPTokenstoRemove) > Number(getLPAllowance)) {

            handlePendingApproval(setModalData, t('ApprovalHeadingText'), t('ApprovalPendingText'))

            const getLPApproval = await LibfiServiceCommon.getApproval(pairAddress, 'pair');

            addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] LP Allowance Provided', 'info');

            handleSuccess(setModalData, 'ApprovalHeadingText', t('ApprovalSuccessText'), "SUCCESS");
            
        } else {
            addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] Sufficient LP Allowance', 'info');
        }

        return true;
    
    } catch (error) {
        handleErrorNoRetryButton(setModalData, 'ApprovalHeadingText', inputdata.t('ApprovalFailText'));
        addBreadcrumb('AllowanceHelper', '[LPAllowanceHelper] Error Occured', 'info',  { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};


export const amanaVaultAllowanceHelper = async (setModalData, inputdata: amanaVaultAllowance) => {

    const {
        tokenDetails,
        inputValue,
        walletAddress,
        amanaVaultAddress,
        t
    } = inputdata;

    try {

        addBreadcrumb('AllowanceHelper', '[amanaVaultAllowanceHelper] Input Data', 'info', { inputdata: inputdata })
        if ( !tokenDetails || !inputValue || !walletAddress ) throw new Error("Input Validation Failed...");
        addBreadcrumb('AllowanceHelper', '[amanaVaultAllowanceHelper] Input Validation Successful', 'info');

        if (tokenDetails.isTokenNative) {
            addBreadcrumb('AllowanceHelper', '[amanaVaultAllowanceHelper] Allowance Not Required', 'info')
            return true;
        } else {

            const getTokenAllowance = await LibfiServiceAmanaVault.getAllowance(amanaVaultAddress, walletAddress, tokenDetails.tokenAddress, 'amanaVault');

            if (Number(inputValue) > Number(getTokenAllowance) ) {

                handlePendingApproval(setModalData, t('ApprovalHeadingText'), 
                    t('tradeMarketSwapApprovalConfirmText').replace('{{token0}}', tokenDetails.tokenName || ""))
                
                const getTokenApproval = await LibfiServiceAmanaVault.getApproval(amanaVaultAddress, tokenDetails.tokenAddress, 'amanaVault');  
                
                addBreadcrumb('AllowanceHelper', '[amanaVaultAllowanceHelper] Allowance Provided for ' + tokenDetails.tokenName, 'info');

                handleSuccess(setModalData, 'ApprovalHeadingText', 
                    t('tradeMarketSwapApprovalSuccessText1').replace('{{token}}', tokenDetails.tokenName || ""), "SUCCESS");

            } else {
                addBreadcrumb('AllowanceHelper', '[amanaVaultAllowanceHelper] Sufficient Allowance for ' + tokenDetails.tokenName, 'info');
            }
        }

        return true;

    } catch (error) {
        handleErrorNoRetryButton(setModalData, 'ApprovalHeadingText', t('ApprovalFailText'));
        addBreadcrumb('[AllowanceHelper]', '[amanaVaultAllowanceHelper] Error Occured', 'info',  { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};
