import ROUTER_ABI from "../Abi/RouterABI.json";
import FACTORY_ABI from "../Abi/FactoryABI.json";
import TOKEN_ABI from "../Abi/DynamicABI.json";
import PAIR_ABI from "../Abi/PairABI.json";
import DYNAMIC_ABI from "../Abi/DynamicABI.json"
import LIMIT_ORDER_ABI from "../Abi/LimitOrderABI.json";
import AMANA_VAULT_ABI from "../Abi/AmanaVaultABI.json";
import libertyfinanceIcon from "../Assets/Images/Icons/tokens/libertyfinance.svg";
import arbitrumIcon from "../Assets/Images/Icons/tokens/arb.png";
import usdtIcon from "../Assets/Images/Icons/tokens/usdticon.svg";
import usdcIcon from "../Assets/Images/Icons/tokens/usdcIcon.svg";
import ethIcon from "../Assets/Images/Icons/tokens/ether.png";
import { SHOW_IP_USDC } from "../Utils";
import libfiService_Common from "../LibfiServices/libfiService_Common";
  
export const dynamicContractDetails = [
  {
    symbol: "router",
    address: process.env.REACT_APP_ROUTER_CONTRACT_ADDRESS,
    abi: ROUTER_ABI,
  },
  {
    symbol: "dynamic",
    address: process.env.REACT_APP_ROUTER_CONTRACT_ADDRESS,
    abi: DYNAMIC_ABI,
  },
  {
    symbol: "pair",
    address: process.env.REACT_APP_ROUTER_CONTRACT_ADDRESS,
    abi: PAIR_ABI,
  },
  {
    symbol: "factory",
    address: process.env.REACT_APP_FACTORY_CONTRACT_ADDRESS,
    abi: FACTORY_ABI,
  },
  {
    symbol: "limitOrder",
    address: process.env.REACT_APP_LIMIT_CONTRACT_ADDRESS,
    abi: LIMIT_ORDER_ABI,
  },
  {
    symbol: "amanaVault",
    address: "",
    abi: AMANA_VAULT_ABI,
  },
];

export const tokenNativeInfo = {
  name: "ETH",
  symbol: "ETH",
  img: ethIcon,
  decimals: 18,
  isNative: true,
  shariahCompliant: false,
};

export const tokenCollection = [
  {
    name: "LIBFI",
    symbol: "LIBFI",
    address: process.env.REACT_APP_LIBFI_ADDRESS,
    abi: TOKEN_ABI,
    isSelected: false,
    img: libertyfinanceIcon,
    decimals: 18,
    isNative: false,
    shariahCompliant: true,
  },
  {
    name: "ARB",
    symbol: "ARB",
    address: process.env.REACT_APP_ARB_ADDRESS,
    abi: TOKEN_ABI,
    isSelected: false,
    img: arbitrumIcon,
    decimals: 18,
    isNative: false,
    shariahCompliant: false,
  },
  {
    name: "WETH",
    symbol: "WETH",
    address: process.env.REACT_APP_WETH_ADDRESS,
    abi: TOKEN_ABI,
    isSelected: false,
    img: ethIcon,
    decimals: 18,
    isNative: false,
    shariahCompliant: false,
  },
  {
    name: "USDT",
    symbol: "USDT",
    address: process.env.REACT_APP_USDT_ADDRESS,
    abi: TOKEN_ABI,
    isSelected: false,
    img: usdtIcon,
    decimals: 6,
    isNative: false,
    shariahCompliant: false,
  },
];

export const timeDuration = [
  {
    label: "1 Minute",
    convertedTime: "60",
    id: "1",
    isSelected: false,
  },
  {
    label: "10 Minute",
    convertedTime: "600",
    id: "2",
    isSelected: false,
  },
  {
    label: "1 Hour",
    convertedTime: "3600",
    id: "3",
    isSelected: false,
  },
  {
    label: "1 Day",
    convertedTime: "86400",
    id: "4",
    isSelected: false,
  },
  {
    label: "3 Days",
    convertedTime: "259200",
    id: "5",
    isSelected: false,
  },
  {
    label: "7 Days",
    convertedTime: "604800",
    id: "6",
    isSelected: false,
  },
  {
    label: "30 Days",
    convertedTime: "2592000",
    id: "7",
    isSelected: false,
  },
  {
    label: "3 Months",
    convertedTime: "7889229",
    id: "8",
    isSelected: false,
  },
  {
    label: "6 Months",
    convertedTime: "15778458",
    id: "9",
    isSelected: false,
  },
  {
    label: "1 Year",
    convertedTime: "31556926",
    id: "10",
    isSelected: false,
  },
  {
    label: "2 Year",
    convertedTime: "63113852",
    id: "11",
    isSelected: false,
  },
];
