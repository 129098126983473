import { Hex, isAddress, TransactionReceipt } from "viem";
import { ethers } from 'ethers';
import { fromWeiConvert, divideWithDecimal } from "../Services/common.service";
import { dynamicContractDetails } from "../Services/dynamicContractDetails";
import { getPublicClient, estimateGas, readContract, writeContract, simulateContract, getBalance, waitForTransactionReceipt } from '@wagmi/core'
import { WagmiConfig} from "../WagmiConfig";
import { storeInstance } from "../Services/axios.service";
import { MAX_APPROVAL, ZERO_ADDRESS } from "../Utils";
import moment from "moment";
import { addBreadcrumb } from '../SentryContext';

// Slippage
const { TransactionDeadline } : { slippage: number, TransactionDeadline: number } = storeInstance?.getState()?.user;

export const contractHelper = (key: string) => {
    const contractData = dynamicContractDetails?.find((data) => data.symbol === key);
    return { 
        abi: contractData?.abi ?? [], 
        contractAddress: contractData?.address ?? ZERO_ADDRESS 
    };
};

export function getDeadline() {
    const currentTime = moment().unix();
    const deadline = currentTime + (TransactionDeadline * 60);
    return deadline;
  }

export function timeoutPromise<T>(promise: Promise<T>, timeout: number): Promise<T> {
    return Promise.race([
        promise,
        new Promise<T>((_, reject) =>
            setTimeout(() => reject(new Error('Promise timed out')), timeout)
        ),
    ]);
}

export const getContractDetails = (type: string) => {
    const contractHelperResponse = contractHelper(type);
    switch (type) {
        case "dynamic":
            return {
                abi: contractHelperResponse.abi,
                contractAddress: contractHelperResponse.contractAddress
            };
        case "router":
            return {
                abi: contractHelperResponse.abi,
                contractAddress: contractHelperResponse.contractAddress
            };
        case "pair":
            return {
                abi: contractHelperResponse.abi,
                contractAddress: contractHelperResponse.contractAddress
            };
        case "amanaVault":
            return {
                abi: contractHelperResponse.abi,
                contractAddress: contractHelperResponse.contractAddress
            };
        default:
            return {
                abi: [],
                contractAddress: ZERO_ADDRESS
            };
    }
};


const LibfiServiceCommon = {

    getNativeTokenInfo: async (): Promise<{name: string; symbol: string; decimals: number;}> => {
        const publicClient = getPublicClient(WagmiConfig)
        const chain = publicClient.chain
        
        return {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol,
            decimals: chain.nativeCurrency.decimals,
        }
    },

    getEstimatedGas: async (to, encodedData, value?): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('EstimateGas', '[getEstimatedGas] Input Data', 'info', { to: to, encodedData: encodedData, value: value });
            if (!isAddress(to)) {
                throw new Error("Address Validation Failed...");
            }

            if (!encodedData) {
                throw new Error("encodedData Validation Failed...");
            }

            if (value && BigInt(value) < 0n) {
                throw new Error("Amount Validation Failed...");
            }
            addBreadcrumb('EstimateGas', '[getEstimatedGas] Input Validation Successful', 'info');

            addBreadcrumb(
                'getEstimatedGas', 
                'estimatedGas Input Data', 
                'info', 
                { 
                    to: to as Hex,
                    data: encodedData, 
                    ...(value !== undefined && { value: value })
                }
            );

            const estimatedGas = await estimateGas(WagmiConfig, {
                to: to as Hex,
                data: encodedData, 
                ...(value !== undefined && { value: value })
            });

            addBreadcrumb('EstimateGas', '[getEstimatedGas] Output Data', 'info', { Output: estimatedGas });

            // Output Validation
            if (typeof estimatedGas !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            return estimatedGas;

        } catch (error) {
            addBreadcrumb('EstimateGas', '[getEstimatedGas] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 
   
    waitForTransactionReceipt: async (txHash): Promise<TransactionReceipt> => {
        try {
            // Input Validation
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] Input Data', 'info', { txHash: txHash });
            if (!ethers.utils.isHexString(txHash, 32)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] Input Validation Successful', 'info');

            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] transactionReceipt Input Data', 'info', { hash: txHash as Hex });
            const transactionReceipt = await waitForTransactionReceipt(WagmiConfig, {
                hash: txHash as Hex,
            })
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] waitForTransactionReceipt Output Data', 'info', { Output: transactionReceipt });

            return transactionReceipt;

        } catch (error) {
            addBreadcrumb('TransactionConfirmation', '[waitForTransactionReceipt] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getDecimals: async (address, type: string): Promise<number>  => {
        try {
            // Input Validation
            addBreadcrumb('Decimals', '[getDecimals] Input Data', 'info', { 'token/pairAddress': address, type: type });
            if (!isAddress(address)) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('Decimals', '[getDecimals] Input Validation Successful', 'info');

            const contractDetails = getContractDetails(type);
            
            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }

            addBreadcrumb('Decimals', '[getDecimals] Input Data', 'info', 
                {
                    address: address as Hex, 
                    //abi: contractDetails.abi,
                    functionName: "decimals",
                    args: []
                }
            );

            const decimals = await readContract(WagmiConfig, {
                address: address as Hex, 
                abi: contractDetails.abi,
                functionName: "decimals",
                args: [],
              });

            addBreadcrumb('Decimals', '[getDecimals] Data Output', 'info', { Output: decimals });

            // Output Validation
            if (typeof decimals !== 'number') {
                throw new Error("Unexpected Return From Contract");
            }

            return decimals;

        } catch (error) {
            addBreadcrumb('Decimals', '[getDecimals] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getAllowance: async (walletAddress, address, type: string): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Data', 'info', { walletAddress: walletAddress, 'token/pairAddress': address, type: type });
            if (!isAddress(walletAddress) || !isAddress(address) || !type ) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Validation Successful', 'info');

            const contractDetails = getContractDetails(type);
            
            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }

            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Data', 'info', 
                {
                    address: address as Hex, 
                    //abi: contractDetails.abi,
                    functionName: "allowance",
                    args: [
                        walletAddress as Hex,
                        contractDetails.contractAddress as Hex,
                    ]
                }
            );

            const tokenAllowance = await readContract(WagmiConfig, {
                address: address as Hex, 
                abi: contractDetails.abi,
                functionName: "allowance",
                args: [
                    walletAddress as Hex,
                    contractDetails.contractAddress as Hex,
                ]
            });
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Data Output', 'info', { Output: tokenAllowance });

            // Output Validation
            if (typeof tokenAllowance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Converted Data Output', 'info', { tokenAllowance: tokenAllowance });

            return tokenAllowance;

        } catch (error) {
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getApproval: async (address, type: string): Promise<TransactionReceipt> => {
        try {
            // Input Validation
            addBreadcrumb('TokenApproval', '[getTokenApproval] Input Data', 'info', { 'token/pairAddress': address, type: type });
            if (!isAddress(address)) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('TokenApproval', '[getTokenApproval] Input Validation Successful', 'info');
    
            const contractDetails = getContractDetails(type);
            
            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }
    
            addBreadcrumb('TokenApproval', '[getTokenApproval] simulateContract Input Data', 'info', {
                address: address as Hex, 
                //abi: contractDetails.abi,
                functionName: "approve",
                args: [
                    contractDetails.contractAddress as Hex,
                    MAX_APPROVAL
                ],
            });
    
            const preparedTx = await simulateContract(WagmiConfig, {
                address: address as Hex, 
                abi: contractDetails.abi,
                functionName: "approve",
                args: [
                    contractDetails.contractAddress as Hex,
                    MAX_APPROVAL
                ],
            });
    
            addBreadcrumb('TokenApproval', '[getTokenApproval] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });
    
            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('TokenApproval', '[getTokenApproval] Transaction Submitted', 'info', { Output: result });
            
            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
    
            return waitForTransactionReceipt;
    
        } catch (error) {
            addBreadcrumb('TokenApproval', '[getTokenApproval] Error Occurred', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getNativeTokenBalance: async (walletAddress): Promise<{ bigIntBalance: bigint, calculatedBalance: Number }> => {
        try {
            // Input Validation
            if (!isAddress(walletAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const result = await getBalance(WagmiConfig, {
                address: walletAddress as `0x${string}`,
                unit: 'ether', 
            });
            
            const nativeTokenBalance = result.value;

            // Output Validation
            if (typeof nativeTokenBalance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            const calculatedBalance: Number = Number(fromWeiConvert(nativeTokenBalance));
            
            //console.log('getNativeTokenBalance Result:', { res: Number(nativeTokenBalance), calculatedBalance });
            return { bigIntBalance: nativeTokenBalance, calculatedBalance };

        } catch (error) {
            //console.error('Error getNativeTokenBalance:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getBalance: async (walletAddress, address, type: string): Promise<{ bigIntBalance: bigint, calculatedBalance: Number }> => {
        try {
            // Input Validation
            addBreadcrumb('Balance', '[getBalance] Input Data', 'info', { walletAddress: walletAddress, 'token/pairAddress': address, type: type });
            if (!isAddress(walletAddress) || !isAddress(address)) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('Balance', '[getBalance] Input Validation Successful', 'info');

            const contractDetails = getContractDetails(type);

            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }
    
            addBreadcrumb('Balance', '[getBalance] simulateContract Input Data', 'info', {
                address: address as Hex, 
                //abi: contractDetails.abi,
                functionName: "balanceOf",
                args: [ walletAddress as Hex, ]
            });

            const balance = await readContract(WagmiConfig, {
                address: address as Hex, 
                abi: contractDetails.abi,
                functionName: "balanceOf",
                args: [ walletAddress as Hex, ]
            });

            addBreadcrumb('Balance', '[getBalance] Data Output', 'info', { Output: balance });

            // Output Validation
            if (typeof balance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            const decimals = await LibfiServiceCommon.getDecimals(address, type)
			const calculatedBalance: Number = Number(divideWithDecimal(balance, decimals));

			return { bigIntBalance: balance, calculatedBalance };

        } catch (error) {
            addBreadcrumb('Balance', '[getBalance] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getTotalSupply: async (address, type: string): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('TotalSupply', '[getTotalSupply] Input Data', 'info', { 'token/pairAddress': address, type: type });
            if (!isAddress(address)) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('TotalSupply', '[getTotalSupply] Input Validation Successful', 'info');

            const contractDetails = getContractDetails(type);
            
            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }

            addBreadcrumb('TotalSupply', '[getTotalSupply] simulateContract Input Data', 'info', {
                address: address as Hex, 
                //abi: contractDetails.abi,
                functionName: "totalSupply",
                args: []
            });

            const totalSupply = await readContract(WagmiConfig, {
                address: address as Hex, 
                abi: contractDetails.abi,
                functionName: "totalSupply",
                args: []
            });   

            addBreadcrumb('TotalSupply', '[getTotalSupply] Data Output', 'info', { Output: totalSupply });

            // Output Validation
            if (typeof totalSupply !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            return totalSupply;

        } catch (error) {
            addBreadcrumb('TotalSupply', '[getTotalSupply] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

};

export default LibfiServiceCommon;
