import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callApiPostMethod } from "../../../../../Redux/Actions/api.action";
import { formatDateTime, toCustomRoundedFixed } from "../../../../../Services/common.service";
import { tokenCollection } from "../../../../../Services/dynamicContractDetails";
import { TOKENS } from "../../../../../interfaces/commonInterfaces";
import ButtonCustom from "../../../../Common/Button/ButtonCustom";
import CommonCard from "../../../../Common/Cards/CommonCard/CommonCard";
import CustomTable from "../../../../Common/Table/Table";
import OrderDetailsModal, { ORDER_DB_TYPES, PAYMENT_DB_STATUSES } from "../OrderDetails/OrderDetailsModal";
import "./OrderHistory.scss";

// This component is used to show all on/off ramp orders
const OrderHistory = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { user, primaryWallet } = useDynamicContext()
    const userwalletAddress: any = primaryWallet?.address;

    // Stores the fetched order details
    const [orders, setOrders] = useState<any[]>([]);

    // Stores the fetched order details based on the sorting options selected
    const [orderList, setOrderList] = useState<any[]>([]);

    // Stores the selected order details
    const [selectedOrder, setSelectedOrder] = useState<any>();

    // Indicates whether user has opened order details or not
    const [show, setShow] = useState<boolean>(false);

    // Check The Table and Add The Vertical Line Accordingly
    const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

    // Stores Order Sorting details
    const [sorting, setSorting] = useState<{ field: string; upOrDown: string }>({
        field: "createdAt", //default sort by date created
        upOrDown: "down",
    });

    // Display date based on selected langauge
    moment.locale(i18n.language);

    // Enum List of all PAYMENT_STATUES
    enum PAYMENT_STATUSES {
        INITIATED,
        ONGOING,
        COMPLETED,
        FAILED,
        DECLINED,
        EXPIRED
    }

    useEffect(() => {
        if (!primaryWallet?.connected) {
            navigate('/on-off-ramp/trade/buy')
        }
    }, [primaryWallet?.connected])

    useEffect(() => {
        if (!show) {
            // Set currently selected order to blank
            setSelectedOrder(null);
            // Fetch order details on component load (default value of show is false, fetch details after closing the order details page also)
            fetchOrderHistory();
        }
    }, [show, userwalletAddress])

    // To Show vertical line
    useEffect(() => {
        if (!isLastColumnVisible) {
            document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
        } else {
            document.documentElement.style.setProperty('--VerticalLine', 'none');
        }
    }, [isLastColumnVisible]);

    // Set filtered list of orders to display once sorting options or orders changed
    useEffect(() => {
        if (orders && orders.length > 0) {
            setOrderList(sortData(orders));
        }
    }, [sorting, orders])

    useEffect(() => {
        setShow(!!selectedOrder);
    }, [selectedOrder])

    // Handle table resize operations
    const tableRef = React.useCallback(node => {
        if (node !== null) {
            const lastColumn = node.querySelector('th:last-child');
            const handleResize = () => {
                if (lastColumn) {
                    const rect = lastColumn.getBoundingClientRect();
                    setIsLastColumnVisible(rect.right <= window.innerWidth);
                    //console.log("Last Column Width:", rect.width, "Window Width:", window.innerWidth, "Is Visible:", rect.right <= window.innerWidth);
                }
            };
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    /**
     * 
     * This method will format the crypto currencies based on selected tokens decimal value. Default set to 6 decimals.
     * 
     * @param amount Crypto amount which needs to be formatted
     * @param decimals Crypto Decimal points
     * @returns 
     */
    const formatCryptoAmount = (amount: number, decimals: number) => {
        return Number(toCustomRoundedFixed(amount, decimals || 6))
    }

    /**
     * This method will format the currency amount. Set to 6 decimals.
     * 
     * @param amount Currency amount which needs to be formatted
     * @returns 
     */
    const formatCurrencyAmount = (amount: number) => {
        return Number(toCustomRoundedFixed(amount, 2))
    }

    /**
     * This method will fetch order details based on the connected wallet address and set few additional details like status and tokendetails
     */
    const fetchOrderHistory = async () => {
        const result: any = await dispatch(callApiPostMethod("ON_OFF_RAMP_ORDER_HISTORY", { walletAddress: userwalletAddress }, false, false));
        if (result?.data) {
            let orderData = result?.data;
            orderData = orderData.map(order => ({
                ...order,
                status: getStatus(order.stages),
                tokenDetails: tokenCollection.find((t: TOKENS) => t.symbol === order.token)
            }))
            setOrders(orderData);
        }
    }

    /**
     * Find the status of the order based on latest timestamp and then mapStatuses from db statues to current statuses
     * 
     * @param stages List of stages
     * @returns 
     */
    const getStatus = (stages: any[]) => {
        if (!stages) return "";
        // If two stages have same timestamp and latest inserted stage status should be displayed
        stages = _.orderBy(stages.reverse(), "timestamp", "desc");
        return mapStatuses(stages?.[0]?.stage);
    }

    /**
     * Converts the enum of PAYMENT_STATUES to display name to support multi language.
     * 
     * @param status PAYMENT_STATUSES enum values
     * @returns 
     */
    const displayStatus = (status: PAYMENT_STATUSES) => {
        if (PAYMENT_STATUSES.INITIATED === status) return t('onOffRampPaymentStatusInitiated');
        if (PAYMENT_STATUSES.ONGOING === status) return t('onOffRampPaymentStatusOngoing');
        if (PAYMENT_STATUSES.COMPLETED === status) return t('onOffRampPaymentStatusCompleted');
        if (PAYMENT_STATUSES.FAILED === status) return t('onOffRampPaymentStatusFailed');
        if (PAYMENT_STATUSES.DECLINED === status) return t('onOffRampPaymentStatusDeclined');
        if (PAYMENT_STATUSES.EXPIRED === status) return t('onOffRampPaymentStatusExpired');
        return "";
    }

    /**
     * This method will map all Database statuses to the enum PAYMENT_STATUSES
     * 
     * @param status Database status
     * @returns 
     */
    const mapStatuses = (status: string) => {
        if (!status) return "";
        if (PAYMENT_DB_STATUSES.COMPLETED === status) return PAYMENT_STATUSES.COMPLETED;
        else if (PAYMENT_DB_STATUSES.DECLINED === status) return PAYMENT_STATUSES.DECLINED;
        else if (PAYMENT_DB_STATUSES.FAILED === status) return PAYMENT_STATUSES.FAILED;
        else if (PAYMENT_DB_STATUSES.PAYMENT_RECEIVED === status) return PAYMENT_STATUSES.ONGOING;
        else if (PAYMENT_DB_STATUSES.INITIATED === status) return PAYMENT_STATUSES.INITIATED;
        return "";
    }

    /**
     * Sort the array based on sorting options selected
     * 
     * @param array List of stages
     * @returns 
     */
    const sortData = (array: any) => {
        const { field, upOrDown } = sorting;
        const order = upOrDown === "up" ? "asc" : "desc";
        return _.orderBy(array, [field], [order]);
    }

    /**
     * This method will set Selected order to currently selected item and then opens the order details modal
     * 
     * @param item Currently selected order
     */
    const showDetails = (item: any) => {
        setSelectedOrder(item);
    }

    /**
     * helper function to fetch which field user wants to sort
     * @param data
     * @param sortType
     */
    const updateSortedData = (data: any, sortType: string) => {
        setSorting({ field: data, upOrDown: sortType });
    };

    /**
     * This method will return the CSS style class based on the payment status of the order
     * 
     * @param status enum PAYMENT_STATUSES
     * @returns 
     */
    const statusClass = (status: PAYMENT_STATUSES) => {
        if (PAYMENT_STATUSES.ONGOING === status) return "ongoing";
        else if (PAYMENT_STATUSES.COMPLETED === status) return "completed";
        else if (PAYMENT_STATUSES.DECLINED === status || PAYMENT_STATUSES.FAILED === status) return "failed";
        else if (PAYMENT_STATUSES.EXPIRED === status) return "expired"
        return "";
    }

    /**  
    * This method checks if the order's expiry date has expired
    */
    const checkExpired = (date) => {
        return new Date(date).getTime() <= Date.now()
    }

    return (
        <CommonCard
            noHeaderSpacing
            cardTitle={t('onOffRampOrderHistory')}
            className="OrderHistory"
        // viewAllNavigate="/dashboard/portfolio"
        >
            <CustomTable
                sortIcons           // Show sorting icons
                scrollingBar={true} // Enable scrolling
                fixedHeader={true}  // Makes header fixed
                fields={[
                    {
                        key: "type",
                        label: t('onOffOrderHistoryType')
                    },
                    {
                        key: "status",
                        label: t('onOffOrderHistoryStatus')
                    },
                    {
                        key: "amount",
                        label: t('onOffOrderHistoryAmount')
                    },
                    {
                        key: "expiry",
                        label: t('onOffOrderHistoryExpiry')
                    },
                    {
                        key: "",
                        label: ""
                    }
                ]}
                loader={false}                          // No Loader for the table
                updateSortedData={updateSortedData}     // Updates the sorting order based on the updated sorting data
                tableRef={tableRef}
            >
                {orderList?.length > 0 &&
                    orderList?.map((item: any) => (
                        <tr>
                            <td>{item.type}</td>
                            <td className={checkExpired(item.expiry) ? statusClass(PAYMENT_STATUSES.EXPIRED) : statusClass(item.status)}>{checkExpired(item.expiry) ? displayStatus(PAYMENT_STATUSES.EXPIRED) : displayStatus(item.status)}</td>
                            <td>
                                <span>{item.type === ORDER_DB_TYPES.ONRAMP ? formatCurrencyAmount(item.amount) : formatCryptoAmount(item.amount, item.tokenDetails?.selectedToken?.decimals || 6)}</span>
                                <span className="mx-1"></span>
                                <span>{item.type === ORDER_DB_TYPES.ONRAMP ? item.currency : item.token}</span>
                            </td>
                            <td>{
                                // Expiry date will be only visible when Payment status is initiated because it is a transaction expiry date
                                PAYMENT_STATUSES.INITIATED === item.status && formatDateTime(item.expiry)
                            }</td>
                            <td>
                                <ButtonCustom
                                    className="tradeBtn bordered-blue"
                                    title={t('onOffOrderHistoryDetailsBtn')}
                                    onClick={() => showDetails(item)}
                                />
                            </td>
                        </tr>
                    ))}
                <OrderDetailsModal
                    show={show}
                    handleClose={() => setShow(false)}
                    orderNumber={selectedOrder?.orderNumber}
                />
            </CustomTable>
        </CommonCard>
    )
}

export default OrderHistory