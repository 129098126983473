import { useEffect, useState, } from "react";
import OtpInput from 'react-otp-input';
import { useUserUpdateRequest, useOtpVerificationRequest } from "@dynamic-labs/sdk-react-core";
import Switch from "react-switch";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { CloseIcon, UploadIcon, } from "../../../../Assets/Images/Icons/SvgIcons";
import InputCustom from "../../FormInputs/Input/Input";
import ButtonCustom from "../../Button/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema } from "../../../FormikSchemas/addProfileSchema";
import { imageUpload } from "../../../Pages/Public/AddProfile/uploadImageFile";
import { handleUserProgress, updateUserModal } from "../../../../Redux/Actions/user.action";
import { RESPONSES, VIDEO_URL } from "../../../../Utils";
import profileImg from "../../../../Assets/Images/profile-img.png";
import { userDetails } from "../../../../Redux/Slices/user.slice";
import { GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import "./ProfileModal.scss";


const ProfileModal = ({
  show,
  handleClose,
  updateImage,
}: {
  show: boolean;
  handleClose: Function;
  updateImage: Function;
}) => {

  // State variables

  const dispatch: any = useDispatch();
  const { verifyOtp } = useOtpVerificationRequest();
  const { updateUser } = useUserUpdateRequest();
  const { t } = useTranslation();
  const { isAuthenticated, user } = useDynamicContext();
  const userDetailsData: any = useSelector( (state: any) => state?.user?.userDetails );
  const [userPreviewDetails, setUserPreviewDetails] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>("");
  const [imagePreview, setImagePreview] = useState<string>("");
  const [imageCancelICon, setImageCancelICon] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [shariahState, setShariahState] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState<string>();
  const [verificationUUID, setVerificationUUID] = useState<string>();
  const [otpError, setOtpError] = useState<{ status: boolean, message: string }>({ status: false, message: "" });
  const [emailModified, setEmailModified] = useState<boolean>(false);
  const [usernameModified, setUsernameModified] = useState<boolean>(false);
  const [shariahStateModified, setShariahStateModified] = useState<boolean>(false);


  // Hooks
  
  /**
  * This useEffect hook fetches the user information
  * each time the "Update Profile" modal appears
  * email address has been added as dependency because every time email changes in store it will refetch and show the latest email
  **/
  useEffect(() => {
    if (isAuthenticated) getUserDetails();
  }, [show,userDetailsData?.emailAddress]);

  /**
  * This function initializes the "useFormik" hook with initial
  * values from userPreviewDetails and validates the name and the email
  **/  
  const formik = useFormik({
    initialValues: {
      name: userPreviewDetails?.name,
      email: userPreviewDetails?.emailAddress,
    },
    // Validate Name & Email
    validationSchema: (values) => loginSchema(t),
    onSubmit() {},
  });

  /**
  * This useEffect hook updates the image preview and syncs name and email values with formik
  **/
  useEffect(() => {
    if (isAuthenticated && userDetailsData?.image) {
      const profilePic = VIDEO_URL + userDetailsData?.image;
      setImagePreview(profilePic);
      setImageCancelICon(true);
    } else {
      setImagePreview(profileImg);
      setImageCancelICon(false);
    }
    if (userPreviewDetails) {
      formik.values.name = userPreviewDetails?.name;
      formik.values.email = userPreviewDetails?.emailAddress;
      setShariahState(userPreviewDetails?.shariah)
    }
  }, [userPreviewDetails, isAuthenticated]);

  /**
  * This useEffect hook monitors changes to the user's email address
  **/
  useEffect(() => {
    if ( userDetailsData?.emailAddress != formik?.values?.email && !Boolean(formik?.errors?.email) ) {
      setEmailModified(true);
    } else if ( userDetailsData?.emailAddress == formik?.values?.email ) {
      setEmailModified(false);
    }
  }, [formik?.values?.email, userDetailsData]);

  /**
  * This useEffect hook monitors changes to the user's name
  **/
  useEffect(() => {
    if ( userDetailsData?.name != formik?.values?.name && !Boolean(formik?.errors?.name) ) {
      setUsernameModified(true);
    } else if ( userDetailsData?.name == formik?.values?.name ) {
      setUsernameModified(false);
    }
  }, [formik?.values?.name, userDetailsData]);

  /**
  * This useEffect hook monitors changes to the user's shariah preference
  **/
  useEffect(() => {
    if ( userDetailsData?.shariah != shariahState ) {
      setShariahStateModified(true);
    } else {
      setShariahStateModified(false);
    }
  }, [shariahState, userDetailsData]);


  // Functions

  /**
  * This function retrieves user information, updates the 
  * "setUserPreviewDetails" local state, and dispatches it
  **/
  const getUserDetails = async () => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      userId: user?.userId,
      from: "Profile",
    });
    if (result) {
      setUserPreviewDetails(result?.data);
      dispatch(userDetails(result?.data));
    }
  };

  /**
  * This function checks if a selected file is valid;
  * If so, it updates the UI to display a preview of the file
  **/
  const handleFileSelect = async (event: any) => {
    try {
      const successfulFileCheck = await checkFileValidity(event?.target?.files[0]);

      if (successfulFileCheck) {
        setImageUrl(event);
        let output = URL.createObjectURL(event?.target?.files[0]);
        setImagePreview(output);
        setImageCancelICon(true);
        setIsReset(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
  * This function checks the file format and size
  **/
  const checkFileValidity = async (file: any) => {
    const maxSize = 4 * 1024 * 1024; // Maximum file size in bytes (= 1MB)

    // This is the list of allowed image formats
    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/svg+xml",
      "image/webp",
    ];

    // Validate file format & Size
    if (!allowedFormats.includes(file.type)) {
      toast.error(t('updateProfileInvalidImageFormatError'), { id: "FileValidity" } );
      return false;
    } else if (file.size > maxSize) {
      toast.error(t('updateProfileFileSizeExceedError').replace('{{size}}', '4MB'), { id: "FileValidity" } );
      return false;
    } else {
      return true;
    }
  }

  /**
  * This function resets the preview to the default profile image
  **/
  const handleDeleteImage = () => {
    setImageUrl("");
    setImagePreview(profileImg);
    setImageCancelICon(false);
    setIsReset(true);
  };

  /**
  * This function pushes the profile information that have been updated 
  * to the LibFi database and to the Dynamic.xyz database if need be
  **/
  const handleProfileSubmit = async () => {
  
    const updatedFields: { userId: any; image?: any; name?: string; email?: string; shariah?: boolean; } = { userId: user?.userId };

    if (usernameModified) { updatedFields.name = formik.values.name; }
    if (emailModified) { updatedFields.email = formik.values.email; }
    if (shariahStateModified) { updatedFields.shariah = shariahState; }
      
    if (imageUrl || isReset) {
      let file: File;

      if (isReset) {
        file = await createFileFromImportedImage(profileImg);
        setIsReset(false);
      } else {
        file = imageUrl?.target?.files[0];
      }

      const Imageresult: any = await imageUpload(file, user?.userId + "-" + file.name);
      let newImagePath: any = Imageresult?.request?.params?.Key;    
      updatedFields.image = newImagePath;
    }

    try {
      const result = await updateUserModal({
        ...updatedFields,
        dispatch,
      });
        
      if (result?.status == RESPONSES?.SUCCESS) {
        if (updatedFields.image) updateImage(updatedFields.image);
        if (emailModified) updateDynamicUserInfo(updatedFields.name);
        toast.success(t('updateProfileSuccessText'), { id: "ProfileSubmit" } );
      } else {
        toast.error(t('updateProfileErrorText'), { id: "ProfileSubmit" } );
      }
    } catch (e) {
      toast.error(t('updateProfileErrorText'), { id: "ProfileSubmit" } );
    } finally {
      handleClose();
    }
  };

  /**
  * This function transforms the default Avatar image to a File object
  **/
  async function createFileFromImportedImage(importedImage) {
    try {
      const response = await fetch(importedImage);
      const blob = await response.blob();
      const filename = "DefaultAvatar";
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error("Error creating file from imported image:", error);
      throw error;
    }
  }

  /**
  * This function pushes some profile information to the Dynamic.xyz database
  **/
  const updateDynamicUserInfo = async (name) => {
    try {
      // Call the updateUser function with the new values entered by the user
      await updateUser({
        username: name,
      });
    } catch (e) {
      console.log("Error", e);
    }
  };

  /**
  * This function pushes the new email address to the 
  * Dynamic.xyz database. This generates an OTP email for validation.
  **/
  const validateEmailAddress = async (email) => {
    try {
      // Call the Dynamic.xyz updateUser function with the new email address
      const { isEmailVerificationRequired, updateUserProfileResponse } = await updateUser({ email: email });

     // If email verification is required, show the OTP modal
     if (isEmailVerificationRequired) {
      setVerificationUUID(updateUserProfileResponse.emailVerification?.verificationUUID);
      setShowOtpModal(true);
      }
    } catch (e) {
      showOtpModal ? setOtpError({ status: true, message: t('otpErrorGenericCode') }) : toast.error(t('otpErrorGenericCode'), { id: "ValidateEmailAddress" } );
    }
  };

  /**
  * This function verifies the OTP code 
  * received on the new email address 
  **/
  const verifyOTP  = async () => {
    if (!otp || otp.length < 6) return false;
    try {
      const verificationToken = {
        verificationToken: otp,
        verificationUUID: verificationUUID
      };
      await verifyOtp(verificationToken.verificationToken, "email" , verificationToken.verificationUUID);
      handleProfileSubmit();
    } catch (e) {
      setOtpError({ status: true, message: t('otpErrorGenericCode') });
    } finally {
      setOtp("");
    }
    return false;
  };

  return (
    <Modal
      centered
      className="profile_modal"
      show={show}
      onHide={() => {
        handleDeleteImage();
        setOtpError({ status: false, message: "" });
        setShowOtpModal(false);
        setOtp("");
        formik.values.name = "";
        formik.values.email = "";
        setEmailModified(false);
        setUsernameModified(false);
        setShariahStateModified(false);
        handleClose();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          { !showOtpModal ? t('updateProfileTitle') : t('otpValidateYourEmail') }
        </Modal.Title>
        <button
          className="modal_close_btn"
          onClick={() => {
            setOtpError({ status: false, message: "" });
            setShowOtpModal(false);
            setOtp("");
            if ( !showOtpModal ) {
              handleDeleteImage();
              formik.values.name = "";
              formik.values.email = "";
              setEmailModified(false);
              setUsernameModified(false);
              setShariahStateModified(false);
              handleClose();
            }
          }}
        >
          <CloseIcon />
        </button>
      </Modal.Header>
      <Modal.Body>
        {!showOtpModal && (
          <div>

            <div className="inputFilePreview">
              <div className="inputFilePreviewInner">
                <span className="upload_icon">
                  <input
                    type="file"
                    onChange={(e) => handleFileSelect(e)}
                    accept="image/*"
                    id="imgInp"
                  />
                  <UploadIcon />
                </span>
                { imageCancelICon && (
                  <span className="cancel_icon" onClick={handleDeleteImage}>
                    <CloseIcon />
                  </span>
                )}
              </div>
              <img src={imagePreview} alt={t('updateProfileSelectedFile')} className="profile_img" />
            </div>

            <InputCustom
              placeholder={t('updateProfileEnterNamePlaceholder')}
              label={
                <>
                  {t('onboardProfileName')}
                </>
              }
              name="name"
              value={formik?.values?.name}
              onChange={(e: any) => {
                e.preventDefault();
                formik?.handleChange(e);
              }}
              maxLength={20}
              error={formik?.errors?.name ? formik?.errors?.name : null}
              spellcheck="false"
            />

            <InputCustom
              className="input-no-margin"
              placeholder={t('updateProfileEnterEmailPlaceholder')}
              label={
                <>
                  {t('onboardEmail')}
                </>
              }
              name="email"
              type="email"
              value={formik?.values?.email}
              onChange={(e: any) => {
                e.preventDefault();
                formik?.handleChange(e);
              }}
              isInvalid={formik?.touched?.email && !!formik?.errors?.email}
              error={formik?.errors?.email ? formik?.errors?.email : null}
              spellcheck="false"
            />

            <div className="shariah-experience-container">
                    <Switch
                      onChange={() => setShariahState(!shariahState)}
                      checked={shariahState}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                  <p>
                    {t('updateProfileShariahExperience')}
                  </p>
            </div>

            <div className="d-flex align-items">
              <ButtonCustom
                title={t('updateProfileCancelButton')}
                className="bordered-blue"
                fluid
                onClick={() => {
                  handleClose();
                }}
              />
              <ButtonCustom
                title={t('updateProfileUpdateButton')}
                fluid
                className="bordered-blue"
                disabled ={ imageUrl.length == 0 && !isReset && !emailModified && !usernameModified && !shariahStateModified || Boolean(formik?.errors?.name) || Boolean(formik?.errors?.email) } 
                onClick={(e) => {
                  emailModified ? validateEmailAddress(formik?.values?.email) : handleProfileSubmit();
                }}
              />
            </div>

          </div>
        )}

        {/* Render the email verification form if needed */}
        {showOtpModal && (

          <div className="otp-section">
            <div className="otp-body">
              <div className="otp-input-box otp-box">

                {otpError.status && (
                  <div className="otp-error-msg"> { otpError.message } </div>
                )}

                <OtpInput
                    value={otp}
                    onChange={(value) => {
                        if (otpError.status) { setOtpError({ status: false, message: "" }); };
                        setOtp(value);
                    }}
                    numInputs={6}
                    containerStyle="otp-container Input input-custom-margin"
                    inputStyle="otp-input"
                    inputType="number"
                    renderInput={(props) => <input {...props} />}
                />

                <div className="d-flex align-items">
                  <ButtonCustom
                    title={t('otpClearInput')}
                    className="bordered-blue"
                    fluid
                    onClick={() => {
                      if (otpError.status) { setOtpError({ status: false, message: "" }); };
                        setOtp("");
                      }}
                  />
                  <ButtonCustom
                    title={t('otpInputConfirmation')}
                    fluid
                    className="bordered-blue"
                    disabled={ !otp || otp.length < 6 }
                    onClick={() => {
                      verifyOTP();
                    }}
                  />
                </div>

                <div className="otp-resend">
                  <span style={{ marginRight: '5px' }}>{t('otpVerificationOTPNotReceived')}</span> 
                  <a href="javascript:void(0)"
                      onClick={(e) => {
                        validateEmailAddress(formik?.values?.email); 
                        toast.success(t('otpSuccessResent'));
                        if (otpError.status) { setOtpError({ status: false, message: "" }); };
                          setOtp("");
                        }}>{t('otpVerificationResend')}
                  </a>
                </div>

              </div>
            </div>
          </div>
        )}

      </Modal.Body>
    </Modal>
  );
};

export default ProfileModal;
