import { TOKEN_DETAILS_SINGLE } from "../../interfaces/commonInterfaces";
import { amanaVaultAllowanceHelper } from "./TokensAllowancesHelper";
import { handleErrorNoRetryButton, handlePendingApproval, handleSuccess, handleError } from "./TransactionModalHelper";
import { addBreadcrumb, captureException } from './../../SentryContext';
import LibfiServiceAmanaVault from "../libfiService_Amana_Vault";


/**
* This Helper Function Handles the Addition of Liquidity for both Native and Non-Native Tokens
**/
const addAmanaVaultHelperFunction = async (
  tokenDetails: TOKEN_DETAILS_SINGLE,
  inputOne: { inputValue: any; convertedValue: any },
  walletAddress: string,
  amanaVaultAddress: string,
  handleOpen: Function,
  setModalData: Function,
  t: (key: string) => string,
) => {
  try {

    handlePendingApproval(setModalData, t('ammPoolsAddLiquidityHeading'), t('ammPoolsAddLiquidityInit'));
    handleOpen();

    const inputData = {
      tokenDetails,
      inputValue: inputOne.convertedValue,
      walletAddress, 
      amanaVaultAddress,
      t
    };

    addBreadcrumb('AmanaLiquidityAddition', '[addLiquidityHelperFunction] Input Data', 'info', { inputdata: inputData })
    if ( !inputData.tokenDetails || !inputOne.inputValue || !inputData.walletAddress ) throw new Error("Input Validation Failed...");
    addBreadcrumb('AmanaLiquidityAddition', 'Input Validation Successful', 'info');

    const amanaVaultAllowanceHelperResponse = await amanaVaultAllowanceHelper(setModalData, inputData);

    handlePendingApproval(setModalData, t('ammPoolsAddLiquidityHeading'), 
      t('amanaVaultsTrxConfirmText').replace('{{token1}}', tokenDetails?.tokenName || ""));

    const addLiquidityResponse = await LibfiServiceAmanaVault.addAmanaVault({
        amanaVaultAddress,
        amount: inputOne.convertedValue,
        walletAddress
      });
    
    addBreadcrumb('AmanaLiquidityAddition', '[addLiquidityHelperFunction] Liquidity Added', 'info');

    return handleSuccess(setModalData, t('ammPoolsAddLiquidityHeading'),
      t('amanaVaultsTrxSuccessText').replace('{{token1}}', tokenDetails?.tokenName || ""), addLiquidityResponse.transactionHash);

  } catch (error) {
    addBreadcrumb('AmanaLiquidityAddition', '[addLiquidityHelperFunction] Error Occured', 'info', { errorMessage: error });
    if (error && !error.toString().includes("User rejected the request")) {
      captureException(error);
    }
    return handleError(setModalData, t('ammPoolsAddLiquidityFailedHeading'), 
      t('amanaVaultsTrxFailedText').replace('{{token1}}', tokenDetails?.tokenName || ""));
  }
};

export {
  addAmanaVaultHelperFunction
};
