import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./ShariahOverview.scss";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import InputCustom from "../../../Common/FormInputs/Input/Input";
import { SearchIconTwo } from "../../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from 'react-i18next';
import { SHARIAH_TOKEN } from '../../../../Redux/Actions/apiResponseInterfaces';
import { useDispatch } from 'react-redux';
import { callAdminApiGetMethod } from "../../../../Redux/Actions/api.action";

const ShariahOverview = () => {

  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const [tokens, setTokens] = useState<SHARIAH_TOKEN[]>([]);
  const [filteredTokens, setFilteredTokens] = useState<SHARIAH_TOKEN[]>(tokens);
  // When all is retrieved, it is set to False
  const [Loader, setLoader] = useState<boolean>(false);
  const [notLoaded, setNotLoaded] = useState<boolean>(false);


  // TO BE IMPROVED - END


  // Manage Speedometer & Text Color
  const updateCompliance = (degrees) => {
    let compliance;
    let textColor;
    if (degrees <= 0 && degrees > -36) {
      compliance = t('shariahCompliant');
      textColor = "#498DE1";
    } else if (degrees <= -36 && degrees > -90) {
      compliance = t('shariahQuestionable');
      textColor = "yellow";
    } else {
      compliance = t('shariahNotCompliant');
      textColor = "#CD6868";
    }
    return { compliance, textColor };
  };


  // Manage Search
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filteredTokens = tokens.filter((token) =>
      token.token_name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredTokens(filteredTokens);
  };


  const getShariahTokens = async () => {
    try {
      const result: SHARIAH_TOKEN[] = await dispatch(
        callAdminApiGetMethod("SHARIAH_TOKENS", {}, false, false)
      );

      result.forEach(res => {
        const averageScore = (Number(res.fundamental_score) + Number(res.function_score) + Number(res.transparency_score) + Number(res.reputation_score) + Number(res.volatility_score)) / 5;
        const speedometer = (averageScore - 100) * 180 / 100;
        res["speedometer"] = speedometer;
        res["token_image"] = res.token_image;
      })
      
      setTokens(result);
      setFilteredTokens(result);
      setLoader(false);

    } catch (error) {
      setNotLoaded(true);
    }
  };

  useEffect(() => {
    setLoader(true);
    getShariahTokens();
  }, []);


  return (
    <div>
      {/* Title */}
      <div>
        <h1></h1>
      </div>
      {/* End Title */}

      {/* Search */}
      <div className="shariah-token-overview-search">
        <InputCustom
          leftIcon={<SearchIconTwo />}
          placeholder={t('shariahSearchPlaceholder')}
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {/* End Search */}

      {/* Panel 
      <div className="shariah-token-overview-section"> */}
      <div className={`shariah-token-overview-section ${ ( Loader && tokens.length == 0 ) || notLoaded ? '' : ' grid'}`}>
        {!Loader ? (
          filteredTokens.map((token, index) => {
            const itemId = token.token_name;
            return (
              <div key={itemId}>
                {/* HEADER */}
                <div className="shariah-token-overview-container" key={itemId}>
                  <div className="shariah-token-overview-sub-container">
                    <div className="shariah-token-overview-header">
                      <img
                        className="shariah-token-overview-token-icon"
                        src={token?.token_image}
                        alt=""
                      />
                      <span>
                        <span>{token?.token_name}</span>
                        <span className="mx-1"></span>
                        <span>({token?.token_symbol}</span>
                        <span>)</span>
                      </span>
                    </div>
                  </div>
                  {/* SPEEDOMETER */}
                  <div className="shariah-token-overview-speedometer-section">
                    <div className="shariah-token-overview-speedometer-container-graph style" id="style">
                      <div className="shariah-token-overview-speedometer-container-graph-scale">
                        <div className="shariah-token-overview-speedometer-container-graph-scale-border">
                        </div>
                        <div className="shariah-token-overview-speedometer-maskwrap-container">
                          <div className="shariah-token-overview-speedometer-mask" style={{ transform: `rotate(${token?.speedometer}deg)` }}>
                            <div className="shariah-token-overview-speedometer-mask-color" style={{ transform: `rotate(calc(${token?.speedometer}deg*-1))` }}>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="shariah-token-overview-speedometer-container-arrow">
                        <div className="shariah-token-overview-speedometer-arrow" style={{ transform: `rotate(calc(90deg + ${token?.speedometer}deg))` }}>
                          <span className="shariah-token-overview-speedometer-icon" role="img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 77" width="8" height="77">
                              <path fill="currentColor" d="M4 0h1l.93 69.5a4 4 0 1 1-3.86 0L3 0h1z">
                              </path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="shariah-token-overview-speedometer-title">
                      <span style={{ color: updateCompliance(token?.speedometer).textColor }}>
                        {updateCompliance(token?.speedometer).compliance}
                      </span>
                    </div>
                  </div>
                  {/* BUTTON */}
                  <div className="shariah-token-overview-button">
                    <Link to='/Shariah/analysis' state={{ token }} >
                      <ButtonCustom
                        className="bordered-blue"
                        title={t('shariahGetFullAnalysis')}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })
        ) : notLoaded ? (
          <p>{t('failedloading')}</p>
        ) : (
          <p>{t('loading')}</p>
        )
        
        
        }
      </div>
      {/* End Panel */}
    </div>

  );
};

export default ShariahOverview;