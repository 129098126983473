import i18next from "i18next";


export const handlePendingApproval = (setModalData, headingKey, bodyText) => {
  setModalData({
      heading: i18next.t(headingKey),
      bodyText: bodyText,
      hideCloseButton: true,
      status: "pending",
      txHash: null,
  });
};

export const handleSuccessNoTxHash = (setModalData, headingKey, bodyText) => {
  setModalData({
      heading: i18next.t(headingKey),
      bodyText: bodyText,
      status: "success",
      txHash: null,
  });
  return true;
};

export const handleSuccess = (setModalData, headingKey, bodyText, txHash) => {
  setModalData({
      heading: i18next.t(headingKey),
      bodyText: bodyText,
      status: "success",
      txHash: txHash,
  });
  return true;
};

export const handleError = (setModalData, headingKey, bodyText) => {
  setModalData({
      heading: i18next.t(headingKey),
      bodyText: bodyText,
      status: "error",
      txHash: null,
  });
  return false;
};

export const handleErrorNoRetryButton = (setModalData, headingKey, bodyText) => {
  setModalData({
      heading: i18next.t(headingKey),
      bodyText: bodyText,
      hideRetryButton: true,
      status: "error",
      txHash: null,
  });
  return false;
};
