import React from 'react';
import ETHIcon from '../../../../Assets/Images/Icons/tokens/Eth.svg';
import libertyfinanceIcon from '../../../../Assets/Images/Icons/tokens/libertyfinance-white.png';
import usdtIcon from '../../../../Assets/Images/Icons/tokens/usdticon.svg';
import ButtonCustom from '../../../Common/Button/ButtonCustom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AssetStakingcards.scss';


const AssetStakingcards = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const data = [
        {
            id: 1,
            icon: ETHIcon,
            name: "ARB",
            subText: (t('assetGetLIBFISubText')).replace("{{asset}}", "ARB"),
            percentage: "14.93%",
        },
        {
            id: 2,
            icon: libertyfinanceIcon,
            name: "LIBFI",
            subText: (t('assetGetRealYieldSubText')).replace("{{asset}}", "LIBFI"),
            percentage: "22.80%",
        },
        {
            id: 3,
            icon: usdtIcon,
            name: "USDT",
            subText: (t('assetGetRealYieldSubText')).replace("{{asset}}", "USDT"),
            percentage: "12.80%",
        },
    ]
    return (
        <>
            <div className='EarnAssetStakingInner'>
                <div className='EarnAssetCards'>
                    {
                        data.map(item => (
                            <div key={item.id} className="assetBox">
                                <div className='assetBoxInner'>
                                    <img src={item.icon} alt="" />
                                    <h3>{item.name}</h3>
                                    <p>{item.subText}</p>
                                    <div className='aprText'>
                                        <h3>{item.percentage}</h3>
                                        <p>{t('assetEstimagedRate')}</p>
                                    </div>
                                    <ButtonCustom
                                        fluid
                                        title={t('assetEarn')}
                                        onClick={() => navigate('/asset-staking/stake')}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default AssetStakingcards;