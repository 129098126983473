  import React, { useEffect, useState } from "react";
  import { ComplyCube } from "@complycube/api";
  import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
  import { Container } from "react-bootstrap";
  import CommonHeading from "../../../Common/Heading/Heading";
  import { VerifiedBadge, FailedBadge } from "../../../../Assets/Images/Icons/SvgIcons";
  import { useFormik } from "formik";
  import ButtonCustom from "../../../Common/Button/ButtonCustom";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation, useNavigate } from "react-router-dom";
  import { createUserProgress, addWallet } from "../../../../Redux/Actions/user.action";
  import { loginSchema } from "../../../FormikSchemas/addProfileSchema";
  import { setUserOnboardingData, userDetails } from "../../../../Redux/Slices/user.slice";
  import { DATA_INSERTED, GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
  import { handleUserProgress } from "../../../../Redux/Actions/user.action";
  import { useTranslation } from "react-i18next";
  import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
  import { useEmbeddedWallet, useDynamicEvents } from "@dynamic-labs/sdk-react-core";
  import { Status, CHECK_STATE } from "../../../../interfaces/commonInterfaces"
  import "./AddKycDocument.scss";

  
  const AddKycProfile = () => {

    // State variables
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, handleLogOut } = useDynamicContext();
    const { createEmbeddedWallet, userHasEmbeddedWallet } = useEmbeddedWallet();
    const { t, i18n } = useTranslation();
    const userDetailsData: any = useSelector( (state: any) => state?.user?.userDetails );
    const [complyCubeToken, setComplyCubeToken] = useState<string>();
    const [complyCubeUserID, setComplyCubeUserID] = useState<string>();
    const [docCheckId, setDocCheckId] = useState<string>();
    const [docCheckStatus, setDocCheckStatus] = useState<CHECK_STATE>({ status: '', outcome: '' });
    const [enhancedIdCheckId, setEnhanceIdCheckId] = useState<string>();
    const [enhancedIdCheckStatus, setEnhancedIdChecktatus] = useState<CHECK_STATE>({ status: '', outcome: '' });
    const [identityCheckFailed, setIdentityCheckFailed] = useState<Boolean>(false);
    const [loader, setLoader] = useState<Boolean>(false);
    const [locale, setLocale] = useState<string>();
    const email = user?.email;

        
    // Hooks

    /**
    * This function initializes the "useFormik" hook with initial
    * values from userPreviewDetails and validates the name and the email
    **/  
    const formik = useFormik({
      initialValues: {
        firstname: userDetailsData?.name,
        lastname: userDetailsData?.emailAddress,
      },
      // Validate Name & Email
      validationSchema: (values) => loginSchema(t),
      onSubmit() {},
    });

    /**
    * This useEffect hook allows to reflect the 
    * user language preference within Dynamic.xyz
    **/
    useEffect(() => {
      const language = i18n?.languages[0];
      setLocale(language);
    }, [ i18n.languages ]);

    /**
    * This useEffect hook sets up an event listener 
    * to handle browser back and forward navigation 
    **/
    useEffect(() => {
      window?.addEventListener("popstate", function (event) {
        navigate(location?.pathname);
      });
    }, [location]);

    /**
    * This useEffect hook loads the ComplyCube SDK script and stylesheet 
    * , then starts the verification process. It cleans up on unmount.
    **/
    useEffect(() => {
      if (complyCubeToken) {

        const script = document.createElement("script");
        script.src =
            "https://assets.complycube.com/web-sdk/v1/complycube.min.js";
        script.async = true;
        document.body.appendChild(script);

        const styleLink = document.createElement("link");
        styleLink.rel = "stylesheet";
        styleLink.href = "https://assets.complycube.com/web-sdk/v1/style.css";
        document.head.appendChild(styleLink);

        script.onload = () => {
          startVerification(complyCubeToken);
        };

        return () => {
            document.body.removeChild(script);
            document.head.removeChild(styleLink);
        };
      }
    }, [complyCubeToken]);

    /**
    * This useEffect hook retrieves the user profile information 
    * and trigger the checks status function if need be
    **/
    useEffect(() => {
      const fetchUserProfile = async () => {
        if (email) {
          const response = await getUserDetails();
          if (!response?.error) {
            if (response?.data?.kyc && response?.data?.kyc?.isCompleted) {
              const kyc = response?.data.kyc.documents;
              if (kyc?.documentCheck?.status == Status.Pending || kyc?.enhancedIdCheck?.status == Status.Pending ) {
                setDocCheckId(kyc?.documentCheck?.id);
                setEnhanceIdCheckId(kyc?.enhancedIdCheck?.id);
                if ( kyc?.documentCheck?.status && kyc?.documentCheck?.outcome ) setDocCheckStatus({ status: kyc?.documentCheck?.status, outcome: kyc?.documentCheck?.outcome });
                if ( kyc?.enhancedIdCheck?.status && kyc?.enhancedIdCheck?.outcome ) setEnhancedIdChecktatus({ status: kyc?.enhancedIdCheck?.status, outcome: kyc?.enhancedIdCheck?.outcome });
               
                if (( kyc?.documentCheck?.status === "complete" && kyc?.documentCheck?.outcome !== "clear" ) || 
                ( kyc?.enhancedIdCheck?.status === "complete" && kyc?.enhancedIdCheck?.outcome !== "clear" )) {
                  setIdentityCheckFailed(true);
                  console.log("Identity Check Failed...");
                } else {
                  getChecksStatus(kyc?.documentCheck?.id, kyc?.enhancedIdCheck?.id);
                }
              }
          }
          }
        }
      };
      fetchUserProfile();
    }, []);

        
    // Functions

    /**
    * This function retrieves information from the user profile and 
    * initializes the "useFormik" hook with initial values as well as the image preview
    **/ 
    const getUserDetails = async () => {
      const result: GET_USER | undefined = await handleUserProgress({
        userId: user?.userId,
        from: "Profile",
        dispatch
      });
      if (result) {
        dispatch(userDetails(result.data));
      }
      return result;
    };

    /**
    * This function initializes the verification process using ComplyCube
    **/
    const startVerification = (complyCubeToken) => {
      console.log("Verification Started...")
      if (ComplyCube) {
        window.complycube = window.ComplyCube.mount({
          token: complyCubeToken,
          containerId: "complycube-mount",
          language: locale,
          disableClientAnalytics: true,
          stages: [
              {
                name: "documentCapture",
                options: {
                  crossDeviceOnly: true,
                  documentTypes: {
                    passport: true,
                    driving_license: false,
                    national_identity_card: true,
                  },
                },
              },
              {
                  name: "faceCapture",
                  options: {
                      mode: "video",
                  },
              },
              {/*
              {
                name: "completion",
                options: {
                  heading: "Thank you for completing the process",
                },
              },
              */}
          ],
          onComplete: (veriData) => {
              complycCubeStartChecks(veriData);
              window.complycube.updateSettings({ isModalOpen: false });
              window.complycube.unmount();
              console.log("Documents Submitted...");
          },
          onModalClose: () => {
              window.complycube.unmount();
              console.log("Modal closed...");
          },
          onError: ({ type, message }) => {
            window.complycube.unmount();
            if (type === 'token_expired') {
              console.log("Token Expired...");
              complycCubeGenerateSDKToken();
            } else {
              console.log("Document Submission Failed...");
            }
          },
        });
      }
    };

    /**
    * This Function generates a ComplyCube SDK Token
    **/
    const complycCubeGenerateSDKToken = async () => {
      const userProfile = await getUserDetails();

      let formattedDate;
      if (userProfile?.data?.birthDate) {
        const dateObject = userProfile?.data?.birthDate;
        formattedDate = dateObject.toString().split('T')[0];
      }

      const data = {
        type: "person",
        email: email,
        personDetails: {
          firstName: userProfile?.data?.firstname,
          lastName: userProfile?.data?.lastname,
          dob: formattedDate,
        } 
      };

      const response: any = await dispatch(callApiPostMethod("CREATE_COMPLYCUBE_USER", data ));
      const complyCubeToken = response?.data?.complyCubeToken;
      const complyCubeClient = response?.data?.complyCubeClient; 

      if (complyCubeToken) {
        console.log("SDK Token Retrieved...")   
        setComplyCubeToken(complyCubeToken);
        setComplyCubeUserID(complyCubeClient?.id);
      }
    };

    /**
    * This Function Initiates the Documents & ID Checks
    * from ComplyCube and Stores the Check IDs in the User Profile
    **/
    const complycCubeStartChecks = async (veriData) => {  
      setLoader(true);
      const docCheckResponse: any = await dispatch(callApiPostMethod("START_COMPLYCUBE_CHECK", { clientId: complyCubeUserID, data: { documentId: veriData.documentCapture.documentId, type: "document_check" } }));
      const idCheckResponse: any = await dispatch(callApiPostMethod("START_COMPLYCUBE_CHECK", { clientId: complyCubeUserID, data: { liveVideoId: veriData.faceCapture.liveVideoId, documentId: veriData.documentCapture.documentId, type: "enhanced_identity_check" } }));
      setDocCheckId(docCheckResponse.data);
      setEnhanceIdCheckId(idCheckResponse.data);

      const result: DATA_INSERTED | false = await createUserProgress({
        userId: user?.userId,
        kyc: { isCompleted: true , id: complyCubeUserID, documents: { documentCheck: { id: docCheckResponse.data, status: Status.Pending, outcome: "unknown" }, enhancedIdCheck: { id: idCheckResponse.data, status: Status.Pending, outcome: "unknown" } } },
        dispatch
      });

      getChecksStatus(docCheckResponse.data, idCheckResponse.data);
    };

    /**
    * This function enables periodic checks of the ongoing ComplyCube checks
    **/ 
    const getChecksStatus = async (docCheckId, enhancedIdCheckId) => {  
      let intervalId = setInterval(async () => {
        try {
          let updatedDocCheckStatus = docCheckStatus;
          let updatedIdCheckStatus = enhancedIdCheckStatus;
  
          if (docCheckStatus.status !== "complete") {
            updatedDocCheckStatus = await complycCubeCheckStatus(docCheckId);
            setDocCheckStatus({ status: updatedDocCheckStatus.status, outcome: updatedDocCheckStatus.outcome });
          }
  
          if (enhancedIdCheckStatus.status !== "complete") {
            updatedIdCheckStatus = await complycCubeCheckStatus(enhancedIdCheckId);
            setEnhancedIdChecktatus({ status: updatedIdCheckStatus.status, outcome: updatedIdCheckStatus.outcome });
          }

          if ((updatedDocCheckStatus.status === "complete" && updatedDocCheckStatus.outcome !== "clear") || 
              (updatedIdCheckStatus.status === "complete" && updatedIdCheckStatus.outcome !== "clear")) {
            clearInterval(intervalId);
            setLoader(false);
            setIdentityCheckFailed(true);
            console.log("Identity Check Failed...");
          }
  
          if (updatedDocCheckStatus.status === "complete" && updatedIdCheckStatus.status === "complete") {
            clearInterval(intervalId);
            console.log("All Documents Reviewed!");
            if (updatedDocCheckStatus.outcome === "clear" && updatedIdCheckStatus.outcome === "clear") {
              console.log("Identity Check Complete...");
              userHasEmbeddedWallet() ? await handleKycDocumentsUpdated() : await createEmbeddedWallet();
            } 
          }
        } catch (error) {
          //console.error("Error in interval function:", error);
        }
      }, 5000);
    
      return () => clearInterval(intervalId); // Cleanup function to clear interval
    };

    /**
    * This Function Fetches a Check Status from ComplyCube
    **/
    const complycCubeCheckStatus = async ( checkId ) => {  
      const checkStatusResponse: any = await dispatch(callApiPostMethod("GET_COMPLYCUBE_CHECK_STATUS", { checkId: checkId }));
      const status = checkStatusResponse?.data?.status;
      const outcome = checkStatusResponse?.data?.outcome;
      return { status, outcome }
    };

    /**
    * This event listener triggers when an embedded wallet is created, 
    * updating the user profile and handling redirection.
    **/ 
    useDynamicEvents("embeddedWalletCreated", async (args) => {
      console.log("Embedded Wallet Created!");
      handleEmbeddedWalletCreated(args.address)
    });

    /**
    * This function updates the user profile 
    * and redirects the user to the 'passkey-setup' page
    **/ 
    const handleEmbeddedWalletCreated = async ( embeddedWalletAddress ) => {
      try {
        const result: DATA_INSERTED | false = await addWallet({
          userId: user?.userId,
          address: embeddedWalletAddress,
          kyc: { isCompleted: true , id: complyCubeUserID, documents: { documentCheck: { id: docCheckId, status: Status.Complete, outcome: "clear" }, enhancedIdCheck: { id: enhancedIdCheckId, status: Status.Complete, outcome: "clear" } } },
          value: 50,
          label: "/onboarding/passkey-setup",
          dispatch
        }); 
        if (result) {
          setLoader(false);
          dispatch( setUserOnboardingData({ progress: 50, path: "/onboarding/passkey-setup" }) );
          navigate("/onboarding/passkey-setup");
        }
      } catch (error) {
        console.error('Error adding embedded wallet in DB:', error);
        return false;
      }
    };

    /**
    * This function only updates the KYC documents if the user already 
    * has an embedded wallet and redirects to the 'passkey-setup' page.
    **/ 
    const handleKycDocumentsUpdated = async () => {
      try {
        const result: DATA_INSERTED | false = await createUserProgress({
          userId: user?.userId,
          kyc: { isCompleted: true , id: complyCubeUserID, documents: { documentCheck: { id: docCheckId, status: Status.Complete, outcome: "clear" }, enhancedIdCheck: { id: enhancedIdCheckId, status: Status.Complete, outcome: "clear" } } },
          value: 50,
          label: "/onboarding/passkey-setup",
          dispatch
        }); 
        if (result) {
          setLoader(false);
          dispatch( setUserOnboardingData({ progress: 50, path: "/onboarding/passkey-setup" }) );
          navigate("/onboarding/passkey-setup");
        }
      } catch (error) {
        console.error('Error adding embedded wallet in DB:', error);
        return false;
      }
    };

    /**
    * This function allows restarting the kyc process from scratch
    **/
    const handleKycRestart = async () => {
      try {
        const result: DATA_INSERTED | false = await createUserProgress({
          userId: user?.userId,
          kyc: {},
          value: 25,
          label: "/onboarding/kyc-profile",
          dispatch
        });
        if (result) {
          dispatch( setUserOnboardingData({ progress: 25, path: "/onboarding/kyc-profile" }) );
          navigate("/onboarding/kyc-profile");
        }
      } catch (error) {
        console.error('Error adding embedded wallet in DB:', error);
        return false;
      }
    };

    /**
    * This function enables to cancel the onboarding 
    * process for embedded wallets
    **/
    const handleCancel = async () => {
      handleLogOut();
      navigate("/");
    };

    return (
      <>
        <section className="AddProfile">
            <Container>
              { !loader && (
                <>
                  <CommonHeading
                    heading="Validate Your Identity"
                    subText={ identityCheckFailed ? "We apologize, but we were unable to validate your identity successfully. Please try again. If the problem persists, contact support for assistance." : "This is necessary to verify your identity and to assist you with any account-related issues you may encounter." }
                    centered
                    className="AddProfileHeading"
                  />
                </>
              )};

              <form>
                { !loader && (
                  <>
                    <div className="kyc-document-Container-Icon">
                      <div className="kyc-document-Icon">
                        {identityCheckFailed ? (
                          <FailedBadge />
                        ) : (
                          <VerifiedBadge />
                        )}
                      </div>
                    </div>

                    <div>
                      {/* This is where ComplyCube SDK will mount its UI */}
                      <div id="complycube-mount"></div>        
                    </div>

                    <div className="d-flex align-items">
                      <ButtonCustom
                        title="Cancel"
                        className="bordered-blue"
                        fluid
                        onClick={() => {
                          handleCancel();
                        }}
                      />
                      <ButtonCustom
                        title={ identityCheckFailed ? "Restart KYC" : "Upload Documents" }
                        fluid
                        className="bordered-blue"
                        onClick={() => { identityCheckFailed ? handleKycRestart() : complycCubeGenerateSDKToken(); }}                 
                      />
                    </div>
                  </>
                )};

                { loader && (
                  <>
                    <div className="scan">
                      <div className="fingerprint"></div>
                    </div>
                  </>
                )}

              </form>

              { loader && (
                <>
                  <h3 className="scan">Hold On! We are checking your identity...</h3>
                </>
              )}

            </Container>


        </section>
      </>
    );
  };

  export default AddKycProfile;
