import CommonCard from "../CommonCard/CommonCard";
import "./RecentTrades.scss";
import CustomTable from "../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  cryptoDecimals,
  tofixFunctionSliced,
} from "../../../../Services/common.service";
import moment from "moment";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { fetchRecentTrade } from "../../../../Redux/Slices/user.slice";
import { Dispatch, useEffect, useState } from "react";
import { getPairService } from "../../../../Services/contractCallService";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";
import { dynamicContractDetails } from "../../../../Services/dynamicContractDetails";
import { COMMON_DATA, TOKENS } from "../../../../interfaces/commonInterfaces";
import { RECENT_TRNX } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";

const RecentTrades = (props: { showTime?: boolean }) => {
  const dispatch: any = useDispatch();
  const { primaryWallet } = useDynamicContext();
  const { t } = useTranslation();
  const { getRecentTrade }: { getRecentTrade: boolean; } = useSelector((state: any) => state?.user);
  const { tokenOne, tokenTwo }: { tokenOne: TOKENS; tokenTwo: TOKENS } = useSelector((state: any) => state?.tokens);
  const [Loader, setLoader] = useState<boolean>(false);
  const [recentHistoryData, setRecentHistoryData] = useState<RECENT_TRNX["data"]>([]);


  useEffect(() => {
    getRecentTransaction();
  }, [tokenOne, tokenTwo, primaryWallet]);

  useEffect(() => {
    if (getRecentTrade) getRecentTransaction();
  }, [getRecentTrade]);

  /**
  * function to fetch and modify recent transaction of swaps
  */
  const getRecentTransaction = async () => {
    dispatch(fetchRecentTrade(false));
    try {
      setLoader(true);
      //console.log("[getRecentTransaction] Starting to fetch recent transactions");
      let data: COMMON_DATA = {
        tokenOneAddress: tokenOne?.address,
        tokenTwoAddress: tokenTwo?.address,
        dispatch: dispatch,
      };
      const pairAddress: string = await getPairService(data);
      //console.log(`[getRecentTransaction] Pair address obtained: ${pairAddress}`);
      const result: RECENT_TRNX = await dispatch(
        callApiPostMethod(
          "GET_RECENT_TRANSACTIONS",
          { pairAddress: pairAddress },
          false,
          false
        )
      );
      //console.log("[getRecentTransaction] API call result:", result);
      const USDTInfo = dynamicContractDetails.find((value: any) => {
        return value?.symbol == "USDT";
      });
      if (result?.status == 200) {
        //console.log("[getRecentTransaction] Processing data:", result.data);
        const bestPricePushInArray: RECENT_TRNX["data"] | undefined =
          result?.data?.map((value: any) => {
            if (value?.tokenIn == tokenOne?.address?.toLowerCase()) {
              const reserve0Value: number =
                value?.reserve0 / 10 ** value?.decimal0;
              const reserve1Value: number =
                value?.reserve1 / 10 ** value?.decimal1;
              value.status = "BUY";
              value.price = tofixFunctionSliced(reserve0Value / reserve1Value);
              const decimals: number =
                value?.tokenOut == USDTInfo?.address?.toLowerCase() ? 6 : 18;
              value.amountOfSwap = value?.amountOut / 10 ** decimals;
            } else {
              //console.error("[getRecentTransaction] Failed to fetch data:", result);
              const reserve0Value: number =
                value?.reserve0 / 10 ** value?.decimal0;
              const reserve1Value: number =
                value?.reserve1 / 10 ** value?.decimal1;
              value.status = "SELL";
              value.price = tofixFunctionSliced(reserve1Value / reserve0Value);
              const decimals: number =
                value?.tokenIn == USDTInfo?.address?.toLowerCase() ? 6 : 18;
              value.amountOfSwap = value?.amountIn / 10 ** decimals;
            }
            return value;
          });
        setRecentHistoryData(bestPricePushInArray);
        setLoader(false);
      }
    } catch (error) {
      //console.error("[getRecentTransaction] Error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <CommonCard
      cardTitle={t('tradeRecentTrades')}
      noHeaderSpacing
      className="recentTradesCard"
      contentClassName="recentTradesCardContent"
    >
      <CustomTable
        shimmerHeight={24}
        fields={
          props?.showTime
            ? [
              {
                key: "index",
                label: t('tradeRecentSNo')
              },
              {
                key: "price",
                label: t('tradeRecentPrice')
              },
              {
                key: "amount",
                label: t('tradeRecentAmount')
              },
              {
                key: "time",
                label: t('tradeRecentTime')
              },
              {
                key: "buySell",
                label: t('tradeRecentBuySell')
              },
            ] : [
              {
                key: "index",
                label: t('tradeRecentSNo')
              },
              {
                key: "amount",
                label: t('tradeRecentAmount')
              },
              {
                key: "buySell",
                label: t('tradeRecentBuySell')
              },
            ]
        }
        loader={Loader}
      >
        {recentHistoryData?.map((item: any, index: any) => (
          <tr key={item?.id}>
            <td>
              <>{index + 1}</>
            </td>
            {props?.showTime && (
              <td>
                <>{cryptoDecimals(item?.price) || "0.00"}</>
              </td>
            )}
            <td>
              <>{cryptoDecimals(item?.amountOfSwap) || "0.00"}</>
            </td>
            {props?.showTime && (
              <td>
                <p>{item?.date}</p>
                <p className="dateText">
                  {item?.timestamp
                    ? moment(item?.timestamp * 1000)?.format("DD/MM/YYYY")
                    : "--"}
                </p>
                <p className="timeText">
                  {item?.timestamp
                    ? moment(item?.timestamp * 1000)?.format("hh:mm")
                    : "--"}
                </p>
              </td>
            )}
            <td>
              {item?.status == "BUY" ? (
                <button className="buyBtn">{t('tradeRecentBuy')}</button>
              ) : (
                <button className="sellBtn">{t('tradeRecentSell')}</button>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </CommonCard>
  );
  
};

RecentTrades.defaultProps = {
  showTime: true,
};

export default RecentTrades;
