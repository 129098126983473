import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from '../../../Common/Button/ButtonCustom';
import CustomTable from "../../../Common/Table/Table";
import { useTranslation } from 'react-i18next';
import CommonCard from '../../../Common/Cards/CommonCard/CommonCard';
import Tooltip from '../../../Common/Tooltip/Tooltip';
import { cryptoDecimals, formatNumberWithMagnitudeSuffix, toCustomFixed } from '../../../../Services/common.service';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import SettingModal from "../../../Common/Modals/SettingModal/SettingModal";
import AmanaVault from "../../../Common/Modals/AmanaModal/AmanaModal";
import { Typography } from '@mui/material';
import { setGlobalAmanaData, setUserSubscribed } from '../../../../Redux/Slices/user.slice';
import { getAmanaVaultsData } from "../../../../LibfiServices/Hooks/useFetchAmanaVaultsData";
import { AMANA_VAULTS } from "../../../../Redux/Actions/apiResponseInterfaces";
import { LeftArrow, SearchIconTwo, TableViewIcon, PanelViewIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import InputCustom from "../../../Common/FormInputs/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import './AmanaVaults.scss';


const AmanaVaults = () => {

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { primaryWallet } = useDynamicContext();
  const globalAmanaData = useSelector((state: any) => state?.user?.poolData);
  const [ModalShow, setModalShow] = useState(false);
  const isSubscribed = useSelector((state: any) => state?.user?.subscribed);
  const [show, setShow] = useState<boolean>(false);
  const [showTab, setShowTab] = useState<string>("earn");
  const [amanaVaultData, setAmanaVaultData] = useState<any>({});

  const [searchQuery, setSearchQuery] = useState<String>("");
  const [searchResults, setSearchResults] = useState<AMANA_VAULTS["data"]>([]);
  const [notLoaded, setNotLoaded] = useState<boolean>(false);
  const [sorting, setSorting] = useState<{ field: string; upOrDown: string }>({
    field: 'tvl',
    upOrDown: "up",
  });
  const [Loader, setLoader] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [buttonPanelClicked, setButtonPanelClicked] = useState(false);
  const [displayValues, setDisplayValues] = useState({});
  const [showUpdatedContent, setShowUpdatedContent] = useState(Array(searchResults.length).fill(false));

  const assetName = location?.state?.assetName;
  const earnOverviewAmanaVaultsData = location?.state?.amanaVaultsData;


  /**
  * This useEffect hook initializes the Amana Vaults panels
  **/
  useEffect(() => {
    if (primaryWallet?.connected) {
      if (earnOverviewAmanaVaultsData) {
        handleAmanaVaults(earnOverviewAmanaVaultsData);
        navigate("/amana-vaults", { replace: true });
      } else {
        getAmanaVaults();
      }
    }
  }, [primaryWallet]);

  /**
  * This useEffect hook resets the Amana Vaults panels & Modal
  **/
  useEffect(() => {
    if (!primaryWallet?.connected) {
      // Close modal if Opened and Restore default settings
      if (show) {
        setShow(!show);
        setShowTab("earn");
        setAmanaVaultData({});
      }
      // Reset Pools Information
      if (searchResults.length > 0) {
        setLoader(false);
        setSearchResults([]);
        navigate("/amana-vaults", { replace: true });
      }
    }
  }, [primaryWallet]);

  /**
  * This useEffect hook allows refreshing the Amana Vaults panels
  * every 30s if no action has been performed by the user
  **/
  useEffect(() => {
    const interval = setInterval(() => {
      const runRefreshFunctions = async () => {
        if (primaryWallet?.connected) {
          setIsRefreshing(true); 
          await getAmanaVaults(); 
          setIsRefreshing(false); 
        }
      };
      runRefreshFunctions();
    }, 60000);
    return () => clearInterval(interval);
  }, [primaryWallet?.connected]);

  /**
  * This useEffect hook allows updating the data 
  * within the selected Amana Vault modal when a refresh occurs
  **/
  useEffect(() => {
    if (globalAmanaData && amanaVaultData) {
      const filteredSelectedAmanaVault: AMANA_VAULTS["data"][1] = globalAmanaData?.find( 
        (vault: AMANA_VAULTS["data"][1]) => vault?.underlyingAssetName === amanaVaultData?.underlyingAssetName && vault?.amanaVaultType === amanaVaultData?.amanaVaultType);

      if (show) setAmanaVaultData(filteredSelectedAmanaVault);
    }
  }, [globalAmanaData]);

  /**
  * This useEffect hook triggers the filtering function when navigating from the Earn Overview Page.
  **/
  useEffect(() => {
    if (isSubscribed) {
      handleSearchWhenSubscribed();
    }
  }, [isSubscribed]);

  /**
  * This function filters the amana vaults to include only those that
  *  contain the token selected by the user on the Earn Overview Page
  **/
  const handleSearchWhenSubscribed = () => {
    dispatch(setUserSubscribed(false));
    setSearchQuery(assetName);
  };

  /**
  * This function displays the amana vaults information 
  * received when navigating from the Earn Overview Page
  */
  const handleAmanaVaults = async (earnOverviewAmanaVaultsData) => {
    setLoader(true);

    dispatch(setGlobalAmanaData(earnOverviewAmanaVaultsData));   

    if (assetName?.length) {
      await handleSearch(earnOverviewAmanaVaultsData, assetName);
    } else { 
      setSearchResults(earnOverviewAmanaVaultsData);
    }
  }

  /**
  * This function fetches all the information for the available Amana Vaults (tokens, TVL, ...)
  */
  const getAmanaVaults = async () => {
    try {
      setLoader(true);

      const walletAddress = primaryWallet?.address

      if (walletAddress) {
        const amanaVaultsData = await getAmanaVaultsData({
          dispatch,
          walletAddress,
        });

        dispatch(setGlobalAmanaData(amanaVaultsData));   

        if (!amanaVaultsData || amanaVaultsData.length === 0) { 
          setNotLoaded(true);
        } else {
          if (assetName?.length) {
            await handleSearch(amanaVaultsData, assetName);
          } else { 
            setSearchResults(amanaVaultsData);
          }
        }
      }
    } catch (error) {
      setNotLoaded(true);
    }
    setLoader(false);
  }

  /**
  * This function filters the amana vaults based on the value entered in the Search bar
  * @param data Total amana vaults in the platform
  * @param searchStringData String to be searched
  **/
  const handleSearch = async (data: AMANA_VAULTS["data"], searchStringData: any) => {
    setSearchQuery(searchStringData);
    const filteredResults: AMANA_VAULTS["data"] = data?.filter(
      (item: AMANA_VAULTS["data"][1]) =>
        item?.underlyingAssetName
          ?.toLowerCase()
          ?.includes(searchStringData?.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  /**
  * This function manages the display of the respective modals
  * @param show boolean value to show modal or not
  * @param tabToShow which modal to show earn or redeem
  * @param userSelectedAmanaVault which Amana Vault to show
  **/
  const handleShow = (
    show: boolean,
    tabToShow: string,
    userSelectedAmanaVault: AMANA_VAULTS["data"][1]
  ) => {
    setShow(show);
    setShowTab(tabToShow);
    setAmanaVaultData(userSelectedAmanaVault);
  };

  /**
  * This function manages the view selected by the user (table view vs panel view)
  * and sorts the pools in ascending order when switching from table to panel view
  * @param view 'True' refers to the Panel view and 'False' refers to the Table view
  **/
  const handleView = (view: boolean) => {
    handleDropdownDisplay(selectedOption);
    setButtonPanelClicked(view);
  };

  /**
  * This function manages the tokens switch button in the Panel view
  **/ 
  const handleClick = (index) => {
    setDisplayValues(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  /**
  * This function manages which container 
  * to display for each pool in the Panel view
  **/
  const handleButtonClick = (index) => {
    const newShowUpdatedContent = [...showUpdatedContent];
    newShowUpdatedContent[index] = !newShowUpdatedContent[index];
    setShowUpdatedContent(newShowUpdatedContent);
  };

  //////////////////////////////////// DROPDOWN ////////////////////////////////////
  /**
  * This manages the ordering criteria in the Dropdown
  **/
  const options = [
    { value: "tvl", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "TVL") },
    { value: "volume", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "Volume") },
    { value: "profit", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "Shared Profit") },
    { value: "userShare", label: (t('ammPoolsSortByField')).replace('{{fieldName}}', "Share Percentage") },
  ]

  /**
  * This manages the style of the Dropdown
  **/
  const dropdownBaseStyle = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderRadius: '16px',
      border: '1.5px solid #E5F7FF',
      boxShadow: "none",
      boxSizing: 'border - box',
      wordWrap: 'break-word',
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#181431",
      color: 'white',
      marginTop: 2,
      borderRadius: '16px',
      border: '1.5px solid #FFFFFF',
      borderShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: '16px',
        backgroundColor: isSelected ? "blue" : isFocused ? "rgb(65, 105, 239)" : "transparent"
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: 'white'
    })
  };

  const dropdownStyle = {
    ...dropdownBaseStyle,
    menu: (provided) => ({
      ...dropdownBaseStyle.menu(provided),
      width: `${menuWidth}px`,
    }),
  };
  
  /**
  * This ensures that each ordering criteria in the Dropdown fits on one line
  **/
  const menuWidth = options.reduce((maxWidth, option) => {
    const labelWidth = option.label.length * 7;
    return labelWidth > maxWidth ? labelWidth : maxWidth;
  }, 0);


  /**
  * This function stores the ordering criteria selected by the user in the dropdown
  **/
  const [selectedOption, setSelectedOption] = useState(options[0]);

  /**
  * This functions allows to manage the dropdown display and sort the pools in the panel view
  **/
  const handleDropdownDisplay = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSorting({ field: selectedOption.value, upOrDown: "up"  });
  };

  /**
  * This function allows the data to be sorted accordingly to the user's preference in the table view
  **/
  const updateSortedData = (data: string, sortType: string) => {
    const option = options.find(option => option.value === data);
    setSelectedOption({ value: data, label: option?.label || "" });
    setSorting({ field: data, upOrDown: sortType });
  };

  /**
  * This useEffect hook updates the translation of the selected ordering criteria in the dropdown
  **/
  useEffect(() => {
    const selectedOp = options.find(o => o.value === selectedOption.value);
    setSelectedOption({ value: selectedOption.value, label: selectedOp?.label || "" });
  }, [t]);

  /**
  * This function handles sorting the amana vaults according to the user's selection from the dropdown
  **/
  const sortResults = (criteria, upOrDown = 'up') => {
    return [...searchResults].sort((a, b) => {
      const order = upOrDown === 'down' ? -1 : 1;
      if (criteria === 'userShare') {
        return (b.userShare - a.userShare) * order;
      } else if (criteria === 'volume') {
        return (b.volumeInUSD - a.volumeInUSD) * order;
      } else if (criteria === 'tvl') {
        return (b?.TVL - a?.TVL) * order;
      } else if (criteria === 'profit') {
        return (b.sharedProfit - a.sharedProfit) * order;
      }
      return 0;
    });
  };

  //////////////////////////////////// END OF DROPDOWN ////////////////////////////////////


  const sortedResults = selectedOption ? sortResults(sorting.field, sorting.upOrDown) : searchResults;
  const combinedResults = [...new Set(sortedResults.concat(searchResults))];
    
  window?.addEventListener("popstate", function (event) {
    navigate("/earn/overview");
  });


  //////////////////////////////////// TABLE EFFECT ////////////////////////////////////
  /**
  * This section manages the table's effect when it does not fully fit within the screen.
  **/
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  //////////////////////////////////// END OF TABLE EFFECT ////////////////////////////////////

  return (
    <>

      <section className="amana-vault">

        <div className="amana-vault-head">
          <h3>
            <button className="left_arrow" onClick={() => navigate("/earn/overview")}>
              <LeftArrow />
            </button>
            {t('amanaVaults')}
          </h3>
          { primaryWallet?.connected && combinedResults.length > 1 && (
            <div className="amana-vault-head-options">
              <div className="amana-vault-head-options-display">
                {!buttonPanelClicked && (
                  <Select className="amana-vault-head-options-ordering"
                    value={selectedOption}
                    hideSelectedOptions={true}
                    options={options}
                    styles={dropdownStyle}
                    isSearchable={false}
                    onChange={handleDropdownDisplay}
                  />
                )}
                <button className="amana-vault-head-options-display-button"
                  style={{ color: buttonPanelClicked ? '#4169ef' : 'white' }}
                  onClick={() => handleView(true)} >
                  <TableViewIcon />
                </button>
                <button className="amana-vault-head-options-display-button"
                  style={{ color: buttonPanelClicked ? 'white' : '#4169ef' }}
                  onClick={() => handleView(false)} >
                  <PanelViewIcon />
                </button>
              </div>
              <InputCustom
                leftIcon={<SearchIconTwo />}
                placeholder={t('earnPoolSearchPlaceholder')}
                onChange={(e: any) => {
                  e.preventDefault();
                  handleSearch(globalAmanaData, e?.target?.value);
                }}
                value={searchQuery}
              />
            </div>
          )}
        </div>

        {/*  Code start for panel view */}
        {!buttonPanelClicked && (

          <div className={`amana-vault-section ${ ( Loader && combinedResults.length == 0 ) || notLoaded || combinedResults.length === 0 ? '' : 'grid'}`}>
            {!primaryWallet?.connected && combinedResults.length === 0 &&
              <div style={{ height: '20px' }}>
                <CommonCard />
              </div>
            }

            {!Loader && !notLoaded || combinedResults.length !== 0
              ? combinedResults?.map((item, index) => {

                return (
                  <div>
                    <CommonCard
                    >
                      <div className="amana-vault-container" key={index}>
                        <div className="amana-vault-sub-container-panel-1">
                          <div className="amana-vault-header">
                            <span className="amana-vault-icons">
                              <img
                                className="amana-vault-icon-one"
                                src={item?.underlyingAssetURI}
                                alt=""
                              />
                            </span>
                            <span>
                              <span>{item?.underlyingAssetName}</span>
                            </span>
                          </div>

                          <div className="amana-vault-header-title">
                            { t('amanaVaultsTitle').replace('{{token}}', item?.underlyingAssetName).replace('{{token}}', item?.underlyingAssetName) }  
                          </div>

                          <div className="container-amana-vault-header">
                            <div className="amana-vault-apr-title-container">
                              <div className="amana-vault-apr-title-sub-container">
                                <div className="amana-vault-apr-title">
                                  <div className="amana-vault-profit-title-text">
                                    {t('amanaVaultsSharedProfit')}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="amana-vault-profit-data">
                              <span>$</span>
                              <span>{item?.sharedProfit ? formatNumberWithMagnitudeSuffix(item?.sharedProfit) : "0.00"}</span>
                            </div>

                            <div className="item3">
                              <div className="amana-vault-type-container">
                                <span className="amana-vault-type-text">{item?.amanaVaultType?.toString().toUpperCase()}</span>
                                <Tooltip>
                                  {item?.amanaVaultType?.toString().toUpperCase().includes("FLEX") ? (
                                    <div>
                                      <Typography className="custom-tooltip-sections">
                                        <div className="d-flex align-items-center">
                                          <span className="bullet-point"></span>
                                          <span>{t('amanaVaultsNoLockUp')}</span>                                        
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <span className="bullet-point"></span>
                                          <span>{t('amanaVaultsPermissible')}</span>
                                        </div>
                                      </Typography>
                                    </div>
                                  ) : (
                                    <div>
                                    <Typography className="custom-tooltip-sections">
                                      <div className="d-flex align-items-center">
                                        <span className="bullet-point"></span>
                                        <span>3-months Lock-Up</span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="bullet-point"></span>
                                        <span>Higher Shared Profit</span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <span className="bullet-point"></span>
                                        <span>Permissible</span>
                                      </div>
                                    </Typography>
                                  </div>
                                  )}
                                </Tooltip>
                              </div>
                            </div>
                          </div>


                          <div className="amana-vault-details-container">
                            <table className="amana-vault-details-table">
                              <tbody>
                                <tr>
                                  <td className="amana-vault-details-table-column1">{t('ammPoolsVolumeColumn')}</td>
                                  <td className="amana-vault-details-table-column2">{t('ammPoolsTVLColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`amana-vault-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>                     
                                    <p>
                                      <span>$</span>
                                      <span>{item?.volumeInUSD ? formatNumberWithMagnitudeSuffix(item?.volumeInUSD) : "0.00"}</span>
                                    </p> 
                                  </td>
                                  <td className={`amana-vault-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{item?.TVL ? formatNumberWithMagnitudeSuffix(item?.TVL) : "0.00"}</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="amana-vault-separator"></div>
                          <div className="amana-vault-details-container">
                            <table className="amana-vault-details-table">
                              <tbody>
                                <tr>
                                  <td className="amana-vault-details-table-column1">{t('amanaVaultsLockedAssetValueColumn')}</td>
                                  <td className="amana-vault-details-table-column2">{t('amanaVaultsMyPoolShareColumn')}</td>
                                </tr>
                                <tr>
                                  <td className={`amana-vault-details-table-column1 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>$</span>
                                      <span>{item?.userShareDollarValue ? formatNumberWithMagnitudeSuffix(item?.userShareDollarValue) : "0.00"}</span>
                                    </p>
                                  </td>
                                  <td className={`amana-vault-details-table-column2 ${isRefreshing ? 'text-shadow' : ''}`}>
                                    <p>
                                      <span>
                                        {`${item?.userShare
                                            ? `${item?.userShare > 0 && item?.userShare < 0.01
                                                ? "< 0.01"
                                                : toCustomFixed(item?.userShare, 2)
                                              }`
                                            : "0.00"
                                        }`}
                                      </span>
                                      <span>%</span>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="amana-vault-buttons">
                            <td>
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsEarnButton')}
                                onClick={() => {
                                  handleShow(true, "earn", item);
                                }}
                              />
                              <ButtonCustom
                                className="bordered-blue"
                                title={t('ammPoolsRedeemButton')}
                                onClick={() => {
                                  handleShow(true, "redeem", item);
                                }}
                              />
                            </td>
                          </div>
                        </div>
                      </div>
                    </CommonCard>
                  </div>
                );
              })
              : notLoaded ? (
                <p>{t('failedloading')}</p>
              ) : (
                <p>{t('loading')}</p>
              )
            }
          </div>

        )}

        {/*  Code start for table view */}
        {buttonPanelClicked && (
          <CommonCard>
            <CustomTable
              sortIcons
              fields={[
                {
                  key: "pool",
                  label: t('ammPoolsPool')
                },
                {
                  key: "userShare",
                  label: t('ammPoolsPositions')
                },
                {
                  key: "tvl",
                  label: t('ammPoolsTVLColumn')
                },
                {
                  key: "volume",
                  label: t('ammPoolsVolume')
                },
                {
                  key: "profit",
                  label: t('amanaVaultsSharedProfit')
                },
                {
                  key: "",
                  label: ""
                },
              ]}
              updateSortedData={updateSortedData}
              loader={searchResults.length !== 0 ? false : Loader}
              scrollingBar={true}
              tableRef={tableRef}
            >
              { !Loader && !notLoaded || combinedResults.length !== 0
                ? combinedResults?.map((item: AMANA_VAULTS["data"][1]) => {
                  return (
                    <tr>
                      <td>
                        <span className="amana-vault-icons">
                          <img
                            className="amana-vault-icon-one"
                            src={item?.underlyingAssetURI}
                            alt=""
                          />
                        </span>
                        <span>
                          <span>{item?.underlyingAssetName}</span>
                        </span>
                      </td>
                      <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                        <span>{`${item?.userShare ? toCustomFixed(item?.userShare, 2) : "0.00"} `}</span>
                        <span>%</span>
                      </td>
                      <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                        <span>$</span>
                        <span>{item?.TVL ? formatNumberWithMagnitudeSuffix(item?.TVL) : "0.00"}</span>
                      </td>
                      <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                        <span>$</span>
                        <span>{item?.volumeInUSD ? formatNumberWithMagnitudeSuffix(item?.volumeInUSD) : "0.00"}</span>
                      </td>
                      <td className={`text-decoration ${isRefreshing ? 'text-shadow' : ''}`}>
                        <span>$</span>
                        <span>{item?.sharedProfit ? formatNumberWithMagnitudeSuffix(item?.sharedProfit) : "0.00"}</span>
                      </td>
                      <td>
                        <ButtonCustom
                          className="bordered-blue"
                          title={t('ammPoolsEarnButton')}
                          onClick={() => {
                            handleShow(true, "earn", item);
                          }}
                        />
                        <ButtonCustom
                          className="bordered-blue"
                          title={t('ammPoolsRedeemButton')}
                          onClick={() => {
                            handleShow(true, "redeem", item);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
                : t('dataFetching')}
            </CustomTable>
          </CommonCard>
        )}
        {/*  Code end for table view */}

        <AmanaVault
          show={show}
          handleClose={() => setShow(false)}
          showTab={showTab}
          getAmanaVaults={getAmanaVaults}
          showSettingsModal={() => {
            setModalShow(true);
          }}
          selectedAmanaVault={amanaVaultData || ""}
          isRefreshing={isRefreshing}
        />
        <SettingModal
          show={ModalShow}
          handleClose={() => {
            setModalShow(false);
          }}
        />

      </section>

    </>
  )
}

export default AmanaVaults;