import { http } from 'viem';
import { arbitrumSepolia } from 'viem/chains';
import { createConfig } from 'wagmi';


export const WagmiConfig = createConfig({
  chains: [arbitrumSepolia],
  multiInjectedProviderDiscovery: false,
  transports: {
    [arbitrumSepolia.id]: http(),
  },
});