import { storeInstance } from "../../Services/axios.service";
import { TOKEN_DETAILS } from "../../interfaces/commonInterfaces";
import LibfiServiceCommon, { getDeadline, timeoutPromise } from "../libfiService_Common";
import { getRemoveLiquiditySignature } from "../libfiService_AAM";
import { LPAllowanceHelper } from "./TokensAllowancesHelper";
import { handleError, handleErrorNoRetryButton, handlePendingApproval, handleSuccess } from "./TransactionModalHelper";
import { addBreadcrumb, captureException } from './../../SentryContext';
import { hundred } from "../../Utils";
import LibfiServiceAAM from "../libfiService_AAM";


// Slippage & Transaction Deadline
const { slippage, TransactionDeadline } : { slippage: number, TransactionDeadline: number } = storeInstance?.getState()?.user;


type RemoveLiq = {
  pairAddress: string;
  withdrawValue: number;
  walletAddress: string;
  tokenDetails: TOKEN_DETAILS;
  handleOpen: Function;
  setModalData: Function;
  tokenA: string;
  tokenB: string;
  t: (key: string) => string
};

/**
* This helper function executes remove liquidity for both native and custom tokens
* @param data it is an object that contains various params required for this function
* @returns a boolean 
**/
export const executeRemoveLiquidity = async (data: RemoveLiq) => {
  const {
    pairAddress,
    withdrawValue,
    walletAddress,
    tokenDetails,
    handleOpen,
    setModalData,
    tokenA,
    tokenB,
    t
  } = data;

  addBreadcrumb('LiquidityRemoval', '[executeRemoveLiquidity] Input Data', 'info', { inputdata: data });
  if ( !data.pairAddress || !data.withdrawValue || !data.walletAddress|| !data.tokenDetails || !data.handleOpen || !data.setModalData|| !data.tokenA || !data.tokenB ) throw new Error("Input Validation Failed...");
  addBreadcrumb('LiquidityRemoval', '[executeRemoveLiquidity] Input Validation Successful', 'info');


  handlePendingApproval(setModalData, t('ammPoolsRemoveLiquidityHeading'), t('earnModelRemoveLiquidityInit'));
  handleOpen();

  try {

    const [
      userLPBalance,
      totalLPAmount,
      totalReserves
    ] = await Promise.all([
      timeoutPromise(LibfiServiceCommon.getBalance(walletAddress, pairAddress, 'pair'), 10000),
      timeoutPromise(LibfiServiceCommon.getTotalSupply(pairAddress, 'pair'), 10000),
      timeoutPromise(LibfiServiceAAM.getReserves(pairAddress), 10000)
    ]);

    addBreadcrumb('LiquidityRemoval', '[executeRemoveLiquidity] userLPBalance/totalLPAmount/totalReserves Retrieved', 'info', { userLPBalanceOutput: userLPBalance, totalLPAmountOutput: totalLPAmount, totalReservesOutput: totalReserves });

    const native: boolean = tokenDetails?.isToken0Native || tokenDetails?.isToken1Native
      ? true
      : false;

    const token0Receive = ( totalReserves[0] * userLPBalance.bigIntBalance * BigInt(withdrawValue) ) / ( totalLPAmount * BigInt(hundred) );
    const minAmountToken0 = token0Receive * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred);

    const token1Receive = ( totalReserves[1] * userLPBalance.bigIntBalance * BigInt(withdrawValue) ) / ( totalLPAmount *  BigInt(hundred) );
    const minAmountToken1 = token1Receive * ( BigInt(100) - BigInt(slippage) ) / BigInt(hundred);

    let userLPTokenstoRemove = ( userLPBalance.bigIntBalance * BigInt(withdrawValue)) / BigInt(hundred);

    /**
    * 'executeRemoveLiquidity' contains two ways for liquidity removal:
      * 1. Signature + removeLiquidityETHWithPermit/removeLiquidityWithPermit
      * 2. LP Allowance + removeLiquidityETH/removeLiquidity
    * To use 1., set 'useSignature' to 'true' else 'false'
    **/
    const useSignature = false;
    let data, removeLiquidityResponse, signature;

    // It's important to maintain the same deadline for both Signature and removeLiquidityETHWithPermit/removeLiquidityWithPermit functions
    const deadline = getDeadline(); 

    if (useSignature) {

      handlePendingApproval(setModalData, t('ammPoolsSignHeading'), 
        t('ammPoolsSignRedeenTrxText').replace('{{token1}}', tokenA || "").replace('{{token2}}', tokenB || ""));

      signature = await getRemoveLiquiditySignature({
        walletAddress,
        pairAddress,
        liquidity: userLPTokenstoRemove,
        deadLine: deadline
      });

      addBreadcrumb('LiquidityRemoval', '[executeRemoveLiquidity] Signature Output Data', 'info', { Output: signature });

    } else {

      const LPAllowanceHelperResponse = await LPAllowanceHelper(setModalData, { pairAddress, walletAddress, userLPTokenstoRemove, t} )

    }

    handlePendingApproval(setModalData, t('ammPoolsRemoveLiquidityHeading'), 
      t('ammPoolsTrxConfirmText').replace('{{token1}}', tokenA || "").replace('{{token2}}', tokenB || ""));

    if (native) {

      const NonNativeTokenAddress: string = tokenDetails?.isToken0Native
      ? tokenDetails?.token1Address
      : tokenDetails?.token0Address;

      data = {
        NonNativeTokenAddress,
        LPTokensToRemove: userLPTokenstoRemove,
        walletAddress,
        ...(useSignature ? { signature } : {}),
        deadLine: deadline
      }

      removeLiquidityResponse = useSignature
        ? await LibfiServiceAAM.removeLiquidityETHWithPermit(data)
        : await LibfiServiceAAM.removeLiquidityETH(data);

    } else {

      data = {
        token0Address: tokenDetails?.token0Address,
        token1Address: tokenDetails?.token1Address,
        LPTokensToRemove: userLPTokenstoRemove,
        walletAddress,
        ...(useSignature ? { signature } : {}),
        deadLine: deadline
      }

      removeLiquidityResponse = useSignature
      ? await LibfiServiceAAM.removeLiquidityWithPermit(data)
      : await LibfiServiceAAM.removeLiquidity(data);

    }

    addBreadcrumb('LiquidityRemoval', '[executeRemoveLiquidity] Liquidity Removed', 'info');

    return handleSuccess(setModalData, t('ammPoolsRemoveLiquidityHeading'), 
      t('ammPoolsTrxSuccessText').replace('{{token1}}', tokenA || "").replace('{{token2}}', tokenB || ""), removeLiquidityResponse.transactionHash);

  } catch (error) {
    addBreadcrumb('LiquidityRemoval', '[executeRemoveLiquidity] Error Occured', 'info', { errorMessage: error });
    if (error && !error.toString().includes("User rejected the request")) {
      captureException(error);
    }
    return handleError(setModalData, t('ammPoolsRemoveLiquidityHeading'), 
      t('ammPoolsRemoveLiquidityFailedText').replace('{{token1}}', tokenA || "").replace('{{token2}}', tokenB || ""))
  }
};


