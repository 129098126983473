import { Provider } from "react-redux";
import store from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Application from "./Application";
import { Toaster } from "react-hot-toast";
import Loader from "./Components/Common/Loader/Loader";
import ReactDOM from "react-dom";
import "./App.scss";

/**CREATE STORE PERSIST INSTANCE */
let persistor = persistStore(store);

function App() {
  //console.log = () => {};
  console.warn = () => {};
  console.error = () => {};

  window?.addEventListener("navigationhandler", function (e) {
    window?.history?.pushState(null, document?.title, window?.location?.href);
    window?.addEventListener("popstate", function (event) {
      window?.history?.pushState(null, document?.title, window?.location?.href);
    });
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {<>{ReactDOM?.createPortal(<Toaster containerStyle= {{ zIndex: 9999999999999999999 }} containerClassName="toaster-override"/>, document?.body)}</>}
        <Loader />
        <Application />
      </PersistGate>
    </Provider>
  );
}

export default App;
