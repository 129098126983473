import { GET_AMOUNTS_DATA } from "../interfaces/commonInterfaces";
import { Hex, encodeFunctionData, isAddress, TransactionReceipt, SignTypedDataParameters } from "viem";
import { readContract, writeContract, simulateContract } from '@wagmi/core'
import { WagmiConfig} from "../WagmiConfig";
import { BigNumber } from 'ethers';
import { GAS_LIMIT_BUFFER } from "../Utils";
import { addBreadcrumb } from './../SentryContext';
import LibfiServiceCommon, { getContractDetails } from "./libfiService_Common";


const routerDetails = getContractDetails("router");


export const handleWriteContractForETH = async (method, data): Promise<TransactionReceipt> => {
    try {

        const amount = data.field == "field1" ? data.amountIn : data.amountOut;
        const amountWithSlippageTolerance = data.field == "field1" ? data.amountOutMinWithSlippageTolerance : data.amountInMaxWithSlippageTolerance;

        // Input Validation
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Input Data', 'info', { method: method, data: data, amountWithSlippageTolerance: amountWithSlippageTolerance })
        if (amount <= 0n || amountWithSlippageTolerance < 0n) {
            throw new Error("Amount Validation Failed...");
        }

        if (!data.path || !Array.isArray(data.path) || data.path.length < 2) {
            throw new Error("Token Path Validation Failed...");
        }

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (data.deadLine <= currentTimestamp) {
            throw new Error("Deadline Validation Failed...");
        }
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Input Validation Successful', 'info');

        const routerDetails = getContractDetails("router");

        // Estimate gas
        const encodedData = encodeFunctionData({
            abi: routerDetails.abi,
            functionName: method,
            args: [
                amountWithSlippageTolerance,
                data.path,
                data.to,
                data.deadLine
            ],
        });

        const gasEstimate = await LibfiServiceCommon.getEstimatedGas(routerDetails.contractAddress as Hex, encodedData, BigInt(Number(data.amountIn)));

        // Compute transaction gasLimit
        const gasLimit = BigNumber.from(gasEstimate).mul(BigNumber.from(GAS_LIMIT_BUFFER)).div(BigNumber.from(100)).toBigInt();
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Computed gasLimit', 'info', { gasBuffer: GAS_LIMIT_BUFFER, gasLimit: gasLimit });

        addBreadcrumb('handleWriteContractForETH', 
            '[handleWriteContractForETH] simulateContract Input Data', 
            'info',
            {
                address: routerDetails.contractAddress as Hex, 
                //abi: routerDetails.abi,
                functionName: method,
                args: [
                    amountWithSlippageTolerance,
                    data.path,
                    data.to,
                    data.deadLine
                ],
                value: amount,
                gas: gasLimit
            }
        );

        // Simulate contract
        const preparedTx = await simulateContract(WagmiConfig, {
            address: routerDetails.contractAddress as Hex, 
            abi: routerDetails.abi,
            functionName: method,
            args: [
                amountWithSlippageTolerance,
                data.path,
                data.to,
                data.deadLine
            ],
            value: amount,
            gas: gasLimit
        });
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

        // Execute transaction
        const result = await writeContract(WagmiConfig, preparedTx.request);
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Transaction Submitted', 'info', { Output: result });

        // Wait For Transaction Execution
        const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);

        return waitForTransactionReceipt;

    } catch (error) {
        addBreadcrumb('handleWriteContractForETH', '[handleWriteContractForETH] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');

    }
};


export const handleWriteContractForTokens = async (method, data): Promise<TransactionReceipt> => {
    try {

        const amount = data.field == "field1" ? data.amountIn : data.amountOut;
        const amountWithSlippageTolerance = data.field == "field1" ? data.amountOutMinWithSlippageTolerance : data.amountInMaxWithSlippageTolerance;

        // Input Validation
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Input Data', 'info', { method: method, data: data, amount: amount, amountWithSlippageTolerance: amountWithSlippageTolerance })
        if (amount <= 0n || amountWithSlippageTolerance < 0n) {
            throw new Error("Amount Validation Failed...");
        }

        if (!data.path || !Array.isArray(data.path) || data.path.length !== 2) {
            throw new Error("Token Path Validation Failed...");
        }

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (data.deadLine <= currentTimestamp) {
            throw new Error("Deadline Validation Failed...");
        }
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Input Validation Successful', 'info');

        // Estimate gas
        const encodedData = encodeFunctionData({
            abi: routerDetails.abi,
            functionName: method,
            args: [
                amount,
                amountWithSlippageTolerance,
                data.path,
                data.to,
                data.deadLine
            ]
        });

        const gasEstimate = await LibfiServiceCommon.getEstimatedGas(routerDetails.contractAddress as Hex, encodedData);

        // Compute transaction gasLimit
        const gasLimit = BigNumber.from(gasEstimate).mul(BigNumber.from(GAS_LIMIT_BUFFER)).div(BigNumber.from(100)).toBigInt();
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Computed gasLimit', 'info', { gasBuffer: GAS_LIMIT_BUFFER, gasLimit: gasLimit });

        addBreadcrumb('handleWriteContractForTokens', 
            '[handleWriteContractForTokens] simulateContract Input Data', 
            'info',
            {
                address: routerDetails.contractAddress as Hex,
                //abi: routerDetails.abi,
                functionName: method,
                args: [
                    amount,
                    amountWithSlippageTolerance,
                    data.path,
                    data.to,
                    data.deadLine
                ],
                gas: gasLimit
            }
        );

        // Simulate contract
        const preparedTx = await simulateContract(WagmiConfig, {
            address: routerDetails.contractAddress as Hex,
            abi: routerDetails.abi,
            functionName: method,
            args: [
                amount,
                amountWithSlippageTolerance,
                data.path,
                data.to,
                data.deadLine
            ],
            gas: gasLimit
        });

        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

        // Execute transaction
        const result = await writeContract(WagmiConfig, preparedTx.request);
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Transaction Submitted', 'info', { Output: result });

        // Wait For Transaction Execution
        const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);

        return waitForTransactionReceipt;

    } catch (error) {
        addBreadcrumb('handleWriteContractForTokens', '[handleWriteContractForTokens] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};


const LibfiServiceSwap = {

    getAmountsOut: async (data: GET_AMOUNTS_DATA): Promise<[bigint,bigint]> => {
        try {
            const { amountIn, token0Address, token1Address } = data;

            // Input Validation
            if (!isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            if (BigInt(amountIn) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            const path: string[] = [token0Address, token1Address];

            const routerDetails = getContractDetails("router");

            const amountsOut = await readContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "getAmountsOut",
                args: [amountIn, path]
            });

            if (!Array.isArray(amountsOut) || amountsOut.length !== 2 || typeof amountsOut[0] !== 'bigint' || typeof amountsOut[1] !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }
 
            return [amountsOut[0], amountsOut[1]];

        } catch (error) {
            //console.error('Error getAmountsOut:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getAmountsIn: async (data: GET_AMOUNTS_DATA): Promise<[bigint,bigint]> => {
        try {
            const { amountIn, token0Address, token1Address } = data;

            // Input Validation
            if (!isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            if (BigInt(amountIn) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            const path: string[] = [token0Address, token1Address];

            const amountsIn = await readContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "getAmountsIn",
                args: [amountIn, path]
            });

            if (!Array.isArray(amountsIn) || amountsIn.length !== 2 || typeof amountsIn[0] !== 'bigint' || typeof amountsIn[1] !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }
 
            return [amountsIn[0], amountsIn[1]];

        } catch (error) {
            console.error('Error getAmountsIn:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

};

export default LibfiServiceSwap;
