  import React, { useRef, useEffect, useState } from "react";
  import { ComplyCube } from "@complycube/api";
  import "./AddKycProfile.scss";
  import { useUserUpdateRequest, useOtpVerificationRequest } from "@dynamic-labs/sdk-react-core";
  import { Container } from "react-bootstrap";
  import CommonHeading from "../../../Common/Heading/Heading";
  import { Modal } from "react-bootstrap";
  import {
    CameraIcon,
    CloseIcon,
    EditIcon,
    UploadIcon,
  } from "../../../../Assets/Images/Icons/SvgIcons";

  import { useFormik } from "formik";
  import ButtonCustom from "../../../Common/Button/ButtonCustom";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation, useNavigate } from "react-router-dom";
  import { Dispatch } from "@reduxjs/toolkit";
  import { custmizeAddress } from "../../../../Services/common.service";
  import {
    createUserProgress,
    metamaskDisconnect,
  } from "../../../../Redux/Actions/user.action";
  import { loginSchema } from "../../../FormikSchemas/addProfileSchema";
  import InputCustom from "../../../Common/FormInputs/Input/Input";
  import { setUserOnboardingData } from "../../../../Redux/Slices/user.slice";
  import { DATA_INSERTED, GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
  import {
    handleUserProgress,
  } from "../../../../Redux/Actions/user.action";
  import { userDetails } from "../../../../Redux/Slices/user.slice";
  import { VIDEO_URL } from "../../../../Utils";
  import profileImg from "../../../../Assets/Images/profile-img.png";
  import { now, result } from "lodash";
  import { useTranslation } from "react-i18next";
  import toast from "react-hot-toast";
  import OtpInput from 'react-otp-input';
  import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
  import { useEmbeddedWallet } from "@dynamic-labs/sdk-react-core";
  import DatePicker from "react-date-picker";
  import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
  import { Value } from "../../../../interfaces/commonInterfaces"

  
  const AddKycProfile = () => {

    // State variables

    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const userDetailsData: any = useSelector( (state: any) => state?.user?.userDetails );
    const [dateOfBirth, setDateOfBirth] = useState<Value>();
    const [datePickerOpened, setDatePickerOpened] = useState<boolean>(false);
    const [locale, setLocale] = useState<string>();
    const { user, handleLogOut } = useDynamicContext();
    const portalContainer = useRef<HTMLDivElement>(null);
    const email = user?.email;
    
    // Hooks

    /**
    * This function initializes the "useFormik" hook with initial
    * values from userPreviewDetails and validates the name and the email
    **/  
    const formik = useFormik({
      initialValues: {
        firstname: userDetailsData?.firstname,
        lastname: userDetailsData?.lastname,
      },
      // Validate Name & Email
      validationSchema: (values) => loginSchema(t),
      onSubmit() {},
    });

    /**
    * This useEffect hook listens for browser navigation events (like back/forward)
    **/ 
    useEffect(() => {
      window?.addEventListener("popstate", function (event) {
        navigate(location?.pathname);
      });
    }, [location]);

    /**
    * This useEffect hook retrieves the user profile from the database
    **/     
    useEffect(() => {
      if (user?.userId?.length) getUserDetails();
    }, []);
 
    /**
    * This useEffect hook reflects the language selected by the user into the date selector
    **/     
    useEffect(() => {
      const language = i18n?.languages[0];
      setLocale(language);
    }, [ i18n.languages ]);

    // Functions

    /**
    * This function retrieves information from the user profile and 
    * initializes the "useFormik" hook with initial values as well as the image preview
    **/ 
    const getUserDetails = async () => {
      const result: GET_USER | undefined = await handleUserProgress({
        userId: user?.userId,
        from: "Profile",
        dispatch
      });
      if (result) {
        if (result?.data != null && result?.data != undefined) {
          formik.setFieldValue("firstname", result.data.firstname);
          formik.setFieldValue("lastname", result.data.lastname);
          setDateOfBirth(result.data.birthDate)
        } 
      }
    };

    /**
    * This function enables to cancel the onboarding 
    * process for embedded wallets
    **/
    const handleCancel = async () => {
      handleLogOut();
      navigate("/");
    };

    /**
    * This function pushes the profile information that have been updated 
    * to the LibFi database and to the Dynamic.xyz database if need be
    **/
    const handleProfileSubmit = async () => {
      const result: DATA_INSERTED | false = await createUserProgress({
        userId: user?.userId,
        firstname: formik?.values?.firstname,
        lastname: formik?.values?.lastname,
        birthDate: dateOfBirth,
        language: i18n.language,
        shariah: false,  
        value: 50,
        label: "/onboarding/kyc-document",      
        dispatch,
      });
      if (result) {
        navigate("/onboarding/kyc-document");
      }
    };


    return (
      <>
        <div>
          {/* This is where ComplyCube SDK will mount its UI */}
          <div id="complycube-mount"></div>        
        </div>

        <section className="AddProfile">

            <Container>
              <CommonHeading
                heading="Create Your Profile"
                subText="To open an account, we need some information from you. This will take only a moment"
                centered
                className="AddProfileHeading"
              />
              <form>

                <InputCustom
                  name="firstname"
                  value={formik?.values?.firstname}
                  onChange={(e: any) => formik?.handleChange(e)}
                  maxLength={30}
                  error={formik?.errors?.firstname ? formik?.errors?.firstname : null}
                  //isInvalid={formik?.touched?.firstname && !!formik?.errors?.firstname}
                  label={
                    <>
                      {/* {t('onboardProfileName')} */}
                      {"Your Firstname"}
                    </>
                  }
                  className="inputField"
                />
                
                <InputCustom
                  name="lastname"
                  value={formik?.values?.lastname}
                  onChange={(e: any) => formik?.handleChange(e) }
                  maxLength={30}
                  error={formik?.errors?.lastname ? formik?.errors?.lastname : null}
                  label={
                    <>
                      {/* {t('onboardEmail')} */}
                      {"Your Lastname"}
                    </>
                  }
                  className="inputField"
                />

                <div className="date-of-birth-label">Your Date Of Birth</div>
                <DatePicker 
                  locale={locale}
                  value={dateOfBirth}
                  onChange={(date) => setDateOfBirth(date)}
                  maxDate={new Date()}
                  clearIcon={null}
                  portalContainer={portalContainer.current}
                  onCalendarOpen={() => setDatePickerOpened(true)}
                  onCalendarClose={() => setDatePickerOpened(false)}
                  className="myCustomDatePickerClassName"
                />

                <div className="d-flex align-items">
                  <ButtonCustom
                    title="Cancel"
                    className="bordered-blue"
                    fluid
                    onClick={() => {
                      handleCancel();
                    }}
                  />
                  <ButtonCustom
                    title="Save"
                    fluid
                    className="bordered-blue"
                    disabled= { !formik?.values?.firstname || !formik?.values?.lastname || !dateOfBirth || Boolean(formik?.errors?.firstname) || Boolean(formik?.errors?.lastname) }
                    onClick={() => {
                      handleProfileSubmit();
                    }}                  
                    />
                </div>

                <div className={datePickerOpened ? "date-picker-container" : ""}  ref={portalContainer} />

              </form>
            </Container>

        </section>
      </>
    );
  };

  export default AddKycProfile;
