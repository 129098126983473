import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { CopyIcon, DoubleTickIcon, InfoIcon } from "../../../../../Assets/Images/Icons/SvgIcons";
import { callBlobApiPostMethod } from "../../../../../Redux/Actions/api.action";
import { formatDateTime, handleCopy, toCustomRoundedFixed } from "../../../../../Services/common.service";
import { tokenCollection } from "../../../../../Services/dynamicContractDetails";
import { ON_MAINNET } from "../../../../../Utils";
import { TOKENS } from "../../../../../interfaces/commonInterfaces";
import ButtonCustom from "../../../../Common/Button/ButtonCustom";
import InputCustom from "../../../../Common/FormInputs/Input/Input";
import { ON_OFF_RAMP_TYPES } from "./OnOffRampPage";
import "./OnOffRampSuccessPage.scss";
import Tooltip from "../../../../Common/Tooltip/Tooltip";

type OnOffRampBuyProps = {
    type: ON_OFF_RAMP_TYPES;       // Type of on/off ramp transaction (enum ON_OFF_RAMP_TYPES)
    reference: string;             // Reference UUID for the transaction
    bankName?: string;             // Optional name of the bank involved in the transaction
    accountName?: string;          // Optional name of the account holder
    accountNumber?: string;        // Optional bank account number
    amount: number;                // Amount or tokens to be transacted
    currency: string;              // Currency in which the transaction is made
    token?: string;                // Optional Crypto token associated with the transaction
    expiresAt: string;             // Expiration date and time for the transaction
    walletAddress: string;         // Wallet address for the transaction
    handleClose: Function;         // Function to handle closing the transaction or modal
}

// To make copy icon reset after below seconds
const COPIED_ICON_RESET_IN_SECONDS = 10;
const OnOffRampSuccessPage = ({ type, reference, bankName, accountName, accountNumber, amount, currency, token, expiresAt, walletAddress, handleClose }: OnOffRampBuyProps) => {
    const dispatch: any = useDispatch();
    const { t } = useTranslation();
    const componentRef: any = useRef();

    // To indicate whether reference number is copied or not
    const [copyReference, setCopyReference] = useState<boolean>(false);

    // To indicate whether wallet address is copied or not
    const [copyWalletAddress, setCopyWalletAddress] = useState<boolean>(false);

    // To indicate whether crypto amount is copied or not
    const [copyCryptoAmount, setCopyCryptoAmount] = useState<boolean>(false);

    // To save selected tokens details e.g. decimals
    const [selectedToken, setSelectedToken] = useState<TOKENS>();

    const [qr, setQR] = useState<string>("")

    // set selected token value from token collections
    useEffect(() => {
        setSelectedToken(tokenCollection.find((t: TOKENS) => t.symbol === token));
    }, [token])

    useEffect(() => {
        if (selectedToken) {
            const { address, decimals } = selectedToken;
            const trxAmount = amount * (10 ** (decimals || 0));
            const qrCode = `ethereum:${address}/transfer?address=${walletAddress}&uint256=${trxAmount}`;
            setQR(qrCode);
        }

    }, [walletAddress, amount, selectedToken])

    /**
     * This method will make one call to backend to generate the pdf for on/off ramp transfer details.
     *  
     * @param type Type of on/off ramp transaction, enum ON_OFF_RAMP_TYPES.
     * @param reference Reference UUID for the transaction
     */
    const downloadOrderDetails = async (type, reference) => {
        try {
            if (reference) {
                let blob;
                if (ON_OFF_RAMP_TYPES.BUY === type) {

                    // Pass all the labels which is displaying under pdf so that it will be displayed based on the language selected
                    const labels = {
                        bankDetails: t('onOffRampBankDetails'),
                        bankName: t('onOffRampBankName'),
                        accountName: t('onOffRampAccountName'),
                        accountNumber: t('onOffRampAccountNumber'),
                        expectedAmount: t('onOffRampExpectedAmount'),
                        expiryDate: t('onOffRampBankTransferBy'),
                        reference: t('onOffRampReference'),
                        note: t('onOffRampNote'),
                        confirmationText1: t('onOffRampReferenceNoAddConfirmationTick'),
                        confirmationText2: t('onOffRampCryptoAmountConfirmationTick')
                    }

                    // Pass rtl to handle all rtl languages (i.e. Arabic)
                    blob = await dispatch(callBlobApiPostMethod("ONRAMP_ORDER_PDF_GENERATE", { reference, labels, rtl: document.body.dir === "rtl" }));
                } else {

                    // Pass all the labels which is displaying under pdf so that it will be displayed based on the language selected
                    const labels = {
                        sendYourTokens: t('onOffRampSendYourTokens'),
                        makeTransferText: t('onOffRampMakeTransferText'),
                        or: t('onOffRampOR'),
                        walletAddress: t('onOffRampWalletAddress'),
                        walletAddressNote: t('onOffRampWalletAddressErrorText'),
                        amountToSend: t('onOffRampAmountToSend'),
                        timeLeft: t('onOffRampTokensTransferBy'),
                        note: t('onOffRampNote'),
                        sendTokenNote: t('onOffRampSendTokenToArbitrum'),
                    }

                    // Pass rtl to handle all rtl languages (i.e. Arabic)
                    blob = await dispatch(callBlobApiPostMethod("OFFRAMP_ORDER_PDF_GENERATE", { reference, qr, labels, rtl: document.body.dir === "rtl" }));
                }

                // Code to convert blob data into one pdf link and then download it
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `order-${reference}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    }

    /**
     * This function is used to copy the content to the clipboard and manage the icons of the copy
     * 
     * @param field field on which copy function is called
     */
    const copy = (field: string) => {
        if ("reference" === field) {
            handleCopy(reference || "");
            // Set flag to true to indicate the reference is copied and need to display copied icon
            setCopyReference(true);
            // Set flag to false to reset copy icon
            setTimeout(() => {
                setCopyReference(false);
            }, COPIED_ICON_RESET_IN_SECONDS * 1000);
        } else if ("walletAddress" === field) {
            handleCopy(walletAddress || "");
            // Set flag to true to indicate the wallet address is copied and need to display copied icon
            setCopyWalletAddress(true);
            // Set flag to false to reset copy icon
            setTimeout(() => {
                setCopyWalletAddress(false);
            }, COPIED_ICON_RESET_IN_SECONDS * 1000);
        } else if ("cryptoAmount" === field) {
            handleCopy(`${formatCryptoAmount(amount)}`);
            // Set flag to true to indicate the crypto amount is copied and need to display copied icon
            setCopyCryptoAmount(true);
            // Set flag to false to reset copy icon
            setTimeout(() => {
                setCopyCryptoAmount(false);
            }, COPIED_ICON_RESET_IN_SECONDS * 1000);
        }
    }

    /**
     * This method will format the crypto currencies based on selected tokens decimal value. Default set to 6 decimals.
     * 
     * @param amount Crypto amount which needs to be formatted
     * @returns 
     */
    const formatCryptoAmount = (amount: number) => {
        return Number(toCustomRoundedFixed(amount, selectedToken?.decimals || 6))
    }

    /**
     * This method will format the currency amount. Set to 6 decimals.
     * 
     * @param amount Currency amount which needs to be formatted
     * @returns 
     */
    const formatCurrencyAmount = (amount: number) => {
        return Number(toCustomRoundedFixed(amount, 2))
    }

    const rightIconWalletAddress = ON_MAINNET ? <span className="copy-icon" onClick={() => copy("walletAddress")}>
        {copyWalletAddress ? <DoubleTickIcon /> : <CopyIcon />}
    </span> : ""

    return (
        <div>
            {
                type === ON_OFF_RAMP_TYPES.BUY ?
                    <div className="on-off-ramp-payment-section on-off-wallet-success">
                        <div className="on-off-ramp-payment-body" ref={componentRef}>
                            <h2 className="banktitle">{t('onOffRampBankDetails')}</h2>
                            <hr />
                            <div className="transfer-bank-details">
                                <Row>
                                    <Col><p>{t('onOffRampBankName')}</p></Col>
                                    <Col><p>{bankName}</p></Col>
                                </Row>
                                <Row>
                                    <Col><p>{t('onOffRampAccountName')}</p></Col>
                                    <Col><p>{accountName}</p></Col>
                                </Row>
                                <Row>
                                    <Col><p>{t('onOffRampAccountNumber')}</p></Col>
                                    <Col><p className={`${ON_MAINNET ? "" : "blur no-select"}`} onCopy={(e) => e.preventDefault()}>{ON_MAINNET ? accountNumber : "0XXXXXXXXX"}</p></Col>
                                </Row>
                            </div>
                            <hr />
                            <div className="transfer-amount-details">
                                <Row>
                                    <Col><p>{t('onOffRampExpectedAmount')}</p></Col>
                                    <Col>
                                        <p>
                                            <span>{formatCurrencyAmount(amount)}</span>
                                            <span className="mx-1"></span>
                                            <span>{currency}</span>
                                            <span className="error-msg"><Tooltip content={t('onOffRampCryptoAmountConfirmationTick')} /></span>
                                        </p>
                                    </Col>
                                </Row>
                                {
                                    expiresAt && <Row>
                                        <Col><p>{t('onOffRampBankTransferBy')}</p></Col>
                                        <Col><p>{formatDateTime(expiresAt)}</p></Col>
                                    </Row>
                                }

                            </div>
                            <hr />
                            <div className="refrence-section">
                                <Row>
                                    <Col><p>{t('onOffRampReference')}</p></Col>
                                    <Col>
                                        <div className="reference-code"><span>{reference}</span></div>
                                        <div className="copy-icon" onClick={() => copy("reference")}>
                                            {copyReference ? <DoubleTickIcon /> : <CopyIcon />}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="on-off-ramp-payment-notes">
                                <span className="error-msg">
                                    <InfoIcon />
                                    {t('onOffRampReferenceNoAddConfirmationTick')}
                                </span>
                            </div>

                        </div>
                        <div className="on-off-ramp-payment-footer bank-footer">
                            <ButtonCustom
                                title={t('onOffRampCloseBtn')}
                                onClick={handleClose}
                            />
                            <ButtonCustom
                                title={t('onOffRampDownloadBtn')}
                                onClick={() => downloadOrderDetails(type, reference)}
                            />
                        </div>
                    </div>
                    :
                    <div className="on-off-ramp-payment-section on-off-wallet-success">
                        <div className="on-off-ramp-payment-body">
                            <h2 className="banktitle">{t('onOffRampSendYourTokens')}</h2>
                            <hr />
                            {/* Append 'blur' class along with 'on-off-ramp-qr-code' class when chain id is different */}
                            <div className={`on-off-ramp-qr-code ${ON_MAINNET ? "" : "blur no-select"}`} onCopy={(e) => e.preventDefault()}>
                                <QRCode value={qr} />
                            </div>
                            <div className="hr-or"> <span>{t('onOffRampOR')}</span> </div>
                            <div className="on-off-ramp-payment-title">{t('onOffRampMakeTransferText')}</div>
                            <div className={`on-off-ramp-payment-input-box`}>
                                <InputCustom
                                    label={t('onOffRampWalletAddress')}
                                    inputClassName={`${ON_MAINNET ? "" : "blur"}`}
                                    name="walletAddress"
                                    type="text"
                                    rightIcon={rightIconWalletAddress}
                                    value={ON_MAINNET ? walletAddress : "0XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"}
                                    onChange={(e) => {
                                    }}
                                    disabled={!ON_MAINNET}
                                    disableCopy={!ON_MAINNET}
                                />
                                <div className="on-off-ramp-note-content">
                                    <span className="error-msg">{t("onOffRampWalletAddressSingleUserErrorText")}<span className="error-msg">{t("onOffRampWalletAddressDoNotInitiateErrorText")}</span></span>
                                </div>
                            </div>
                            <hr />
                            <div className="on-off-ramp-payment-input-box">
                                <InputCustom
                                    label={t('onOffRampAmountToSend')}
                                    name="amount"
                                    type="text"
                                    rightIcon={
                                        <span className="copy-icon" onClick={() => copy("cryptoAmount")}>
                                            {copyCryptoAmount ? <DoubleTickIcon /> : <CopyIcon />}
                                        </span>
                                    }
                                    leftIcon={selectedToken?.img && <img src={selectedToken?.img} alt="icon" />}
                                    value={`${formatCryptoAmount(amount)} ${token}`}
                                    onChange={(e) => {
                                    }}
                                />
                                <span className="error-msg"></span>
                            </div>
                            {
                                expiresAt && <div className="on-off-ramp-payment-time">
                                    <p>{t('onOffRampTokensTransferBy')}</p>
                                    <span>{formatDateTime(expiresAt)}</span>
                                </div>
                            }
                            <div className="on-off-ramp-payment-notes">
                                <span className="error-msg"><InfoIcon />{t('onOffRampSendTokenToArbitrum')}</span>
                            </div>
                        </div>
                        <div className="on-off-ramp-payment-footer bank-footer">
                            <ButtonCustom
                                title={t('onOffRampCloseBtn')}
                                onClick={handleClose}
                            />
                            <ButtonCustom
                                title={t('onOffRampDownloadBtn')}
                                onClick={() => downloadOrderDetails(type, reference)}
                            />
                        </div>
                    </div>
            }
        </div>
    )
}

export default OnOffRampSuccessPage;