import { newRemoveLiquiditySig, permitSignature } from "../interfaces/contractCallInterfaces";
import { Hex, isHex, toBytes, pad, toHex, isAddress, TransactionReceipt, SignTypedDataParameters } from "viem";
import { readContract, writeContract, simulateContract, getChainId, signTypedData } from '@wagmi/core'
import { WagmiConfig} from "../WagmiConfig";
import { ZERO_ADDRESS } from "../Utils";
import { splitSignature as ethersSplitSignature } from 'ethers/lib/utils';
import { addBreadcrumb } from './../SentryContext';
import LibfiServiceCommon, { getContractDetails, timeoutPromise } from "./libfiService_Common";


const routerDetails = getContractDetails("router");
const pairDetails = getContractDetails("pair");


export const getTotalReservesOfTokensInPool = async (pairAddress, token0Address, token1Address): Promise<{ _reserve0: number; _reserve1: number }> => {
    if (pairAddress.toLowerCase() === ZERO_ADDRESS?.toLowerCase()) {
        return { _reserve0: 0, _reserve1: 0 };
    }

    try {
        const [
            reserves,
            tokens,
            decimals0,
            decimals1
        ] = await Promise.all([
            timeoutPromise(LibfiServiceAAM.getReserves(pairAddress), 10000),
            timeoutPromise(LibfiServiceAAM.getTokensThroughPairAddress(pairAddress), 10000),
            timeoutPromise(LibfiServiceCommon.getDecimals(token0Address, 'dynamic'), 10000),
            timeoutPromise(LibfiServiceCommon.getDecimals(token1Address, 'dynamic'), 10000)
        ]);
  
        const reserve0 = Number(reserves[0]) / 10 ** decimals0;
        const reserve1 = Number(reserves[1]) / 10 ** decimals1;
  
        if (token0Address?.toLowerCase() == tokens.token0.toString().toLowerCase()) {
            return { _reserve0: reserve0, _reserve1: reserve1 };
        } else {
            return { _reserve0: reserve1, _reserve1: reserve0 };
        }

    } catch (error) {
        return { _reserve0: 0, _reserve1: 0 };
    }
};


export const getRemoveLiquiditySignature = async (data: newRemoveLiquiditySig): Promise<permitSignature> => {
    const { walletAddress, pairAddress, liquidity, deadLine } = data;
  
    const owner = walletAddress;
    const spender = routerDetails.contractAddress;
    const value = liquidity;
    let chainId = getChainId(WagmiConfig)

    try {
        // Input Validation
        addBreadcrumb('LiquidityRemoval', '[getRemoveLiquiditySignature] Input Data', 'info', { walletAddress: walletAddress, pairAddress: pairAddress, liquidity: liquidity, routerAddress: spender, chainId: chainId, deadLine: deadLine });
        if (BigInt(value) < 0n) {
            throw new Error("Amount Validation Failed...");
        }

        if (!isAddress(walletAddress) || !isAddress(pairAddress)) {
            throw new Error("Address Validation Failed...");
        }

        if (!spender) {
            throw new Error("Router Address Validation Failed...");
        }

        if (!chainId) {
            throw new Error("chainId Validation Failed...");
        }

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (deadLine <= currentTimestamp) {
            throw new Error("Deadline Validation Failed...");
        }
        addBreadcrumb('LiquidityRemoval', '[getRemoveLiquiditySignature] Input Validation Successful', 'info');
  
    
        const nonce = await LibfiServiceAAM.getPairNonces(walletAddress, pairAddress);
          
        // Domain Definition
        const domain = {
            name: "Libfi V2",
            version: "1",
            chainId,
            verifyingContract: pairAddress,
        };
        
        // Types definition
        const types: SignTypedDataParameters['types'] = {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' },
            ],
            Permit: [
              { name: 'owner', type: 'address' },
              { name: 'spender', type: 'address' },
              { name: 'value', type: 'uint256' },
              { name: 'nonce', type: 'uint256' },
              { name: 'deadline', type: 'uint256' },
            ],
          };

        // Message Definition
        const message = {
            owner,
            spender,
            value,
            nonce: nonce,
            deadline: deadLine
        };
        
        addBreadcrumb('LiquidityRemoval', '[signTypedData] Input Data', 'info', { domain, types, primaryType: "Permit", message });
        
        const signature = await signTypedData(WagmiConfig, {
            domain,
            types,
            primaryType: "Permit",
            message
        });
        
        addBreadcrumb('LiquidityRemoval', '[signTypedData] Output Data', 'info', { signature });

        const splits = await splitSignature(signature);
        return splits;

    } catch (error) {
        addBreadcrumb('LiquidityRemoval', '[getRemoveLiquiditySignature] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};

const splitSignature = (signature: string): permitSignature => {
    try {
        // Input Validation
        addBreadcrumb('LiquidityRemoval', '[splitSignature] Input Data', 'info', { signature: signature });
        if (!isHex(signature) || signature.length !== 132) {
            throw new Error("Invalid signature string; must be 65 bytes");
        }
        addBreadcrumb('LiquidityRemoval', '[splitSignature] Input Validation Successful', 'info');

        const { r, s, v } = ethersSplitSignature(signature);

        const recoveryParam = v < 27 ? v : 1 - (v % 2);

        const sBytes = toBytes(s);
        if (recoveryParam) {
            sBytes[0] |= 0x80;
        }

        const _vs = toHex(pad(sBytes, { size: 32 }));

        addBreadcrumb('LiquidityRemoval', '[splitSignature]  Output Data', 'info', { r: r, s: s, v: v, recoveryParam: recoveryParam, _vs: _vs });
        return { r, s, v, recoveryParam, _vs };

    } catch (error) {
        addBreadcrumb('LiquidityRemoval', '[splitSignature] Error Occured', 'info', { errorMessage: error });
        throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
    }
};



const LibfiServiceAAM = {

    getTokensThroughPairAddress: async (pairAddress): Promise<{ token0: string, token1: string }> => {
        try {
            // Input Validation
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const [token0, token1] = await Promise.all([

                readContract(WagmiConfig, {
                    address: pairAddress as Hex,
                    abi: pairDetails.abi,
                    functionName: "token0",
                    args: []
                }),
                
                readContract(WagmiConfig, {
                    address: pairAddress as Hex,
                    abi: pairDetails.abi,
                    functionName: "token1",
                    args: []
                })

            ]);

            // Output Validation
            if (typeof token0 !== 'string' || typeof token1 !== 'string') {
                throw new Error("Unexpected Return From Contract");
            }

            const result = token0 && token1 
                    ? { token0, token1 } 
                    : { token0: ZERO_ADDRESS, token1: ZERO_ADDRESS };

            //console.log('getTokensThroughPairAddress result:', result);
            return result;

        } catch (error) {
            //console.error('Error getTokensThroughPairAddress:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },  

    getReserves: async (pairAddress): Promise<[bigint, bigint]> => {
        try {
            // Input Validation
            if (!isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }
    
            const reserves = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: pairDetails.abi,
                functionName: "getReserves",
                args: []
            });
    
            // Check if reserves is an array with at least 3 elements
            if (!Array.isArray(reserves) || reserves.length !== 3) {
                throw new Error("Unexpected Return From Contract");            
            }

            // Check if the first element is a bigint
            if (typeof reserves[0] !== 'bigint' || typeof reserves[1] !== 'bigint' || typeof reserves[1] !== 'bigint' || typeof reserves[2] !== 'number') {
                throw new Error("Unexpected Return From Contract");
            }
    
            return [reserves[0], reserves[1]];
    
        } catch (error) {
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    addETHLiquidity: async (data): Promise<TransactionReceipt> => {

        const { inputOne, inputTwo, NonNativeTokenAddress,amountTokenMin, amountTokenETHMin, walletAddress, deadLine } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Input Data', 'info', { data: data });
            if (BigInt(inputOne) < 0n || BigInt(inputTwo) < 0n || BigInt(amountTokenMin) < 0n || BigInt(amountTokenETHMin) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(NonNativeTokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Input Validation Successful', 'info');

            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] simulateContract Input Data', 'info', {             
                address: routerDetails.contractAddress as Hex, 
                //abi: routerDetails.abi,
                functionName: "addLiquidityETH",
                args: [
                    NonNativeTokenAddress as Hex,
                    BigInt(inputTwo),
                    BigInt(amountTokenMin),
                    BigInt(amountTokenETHMin),
                    walletAddress as Hex,
                    deadLine,
                ],
                value: BigInt(inputOne)
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "addLiquidityETH",
                args: [
                    NonNativeTokenAddress as Hex,
                    BigInt(inputTwo),
                    BigInt(amountTokenMin),
                    BigInt(amountTokenETHMin),
                    walletAddress as Hex,
                    deadLine,
                ],
                value: BigInt(Number(inputOne))
            });

            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityAddition', '[addETHLiquidity] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    addLiquidity: async (data): Promise<TransactionReceipt> => {

        const { tokenOneAddress, tokenTwoAddress, amountTokenAMin, amountTokenBMin , amountTokenADesired, amountTokenBDesired, deadLine, walletAddress } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Input Data', 'info', { data: data });
            if (BigInt(amountTokenADesired) < 0n || BigInt(amountTokenBDesired) < 0n || BigInt(amountTokenAMin) < 0n || BigInt(amountTokenBMin) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(tokenOneAddress) || !isAddress(tokenTwoAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityAddition', '[addLiquidity] simulateContract Input Data', 'info', {             
                address: routerDetails.contractAddress as Hex,
                //abi: routerDetails.abi,
                functionName: "addLiquidity",
                args: [
                    tokenOneAddress as Hex,
                    tokenTwoAddress as Hex,
                    BigInt(amountTokenADesired),
                    BigInt(amountTokenBDesired),
                    BigInt(amountTokenAMin),
                    BigInt(amountTokenBMin),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex,
                abi: routerDetails.abi,
                functionName: "addLiquidity",
                args: [
                    tokenOneAddress as Hex,
                    tokenTwoAddress as Hex,
                    BigInt(amountTokenADesired),
                    BigInt(amountTokenBDesired),
                    BigInt(amountTokenAMin),
                    BigInt(amountTokenBMin),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            addBreadcrumb('LiquidityAddition', '[addLiquidity] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityAddition', '[addLiquidity] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getPairNonces: async (walletAddress, pairAddress): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('Nonce', '[getPairNonces] Input Data', 'info', { walletAddress: walletAddress, pairAddress: pairAddress });
            if (!isAddress(walletAddress) || !isAddress(pairAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('Nonce', '[getPairNonces] Input Validation Successful', 'info');


            addBreadcrumb(
                'nonce', 
                'nonce Input Data', 
                'info', 
                {
                    address: pairAddress as Hex, 
                    //abi: pairDetails.abi,
                    functionName: "nonces",
                    args: [ walletAddress as Hex, ]
                }
            );

            const nonce = await readContract(WagmiConfig, {
                address: pairAddress as Hex, 
                abi: pairDetails.abi,
                functionName: "nonces",
                args: [ walletAddress as Hex, ]
            });

            // Output Validation
            if (typeof nonce !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            addBreadcrumb('Nonce', '[getPairNonces] Output Data', 'info', { Output: nonce });

            return nonce;

        } catch (error) {
            addBreadcrumb('Nonce', '[getPairNonces] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    removeLiquidityETHWithPermit: async (data): Promise<TransactionReceipt> => {

        const { NonNativeTokenAddress, LPTokensToRemove, walletAddress, signature, deadLine } = data;
        const { v, r, s } = signature;

        try {
            // Input Validation
            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] Input Data', 'info', { NonNativeTokenAddress: NonNativeTokenAddress, LPTokensToRemove: LPTokensToRemove,  walletAddress: walletAddress, signature: signature, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if ( !v || !r || !s) {
                throw new Error("Signature Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(NonNativeTokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] Input Validation Successful', 'info');


            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] simulateContract Input Data', 'info', {             
                address: routerDetails.contractAddress as Hex, 
                //abi: routerDetails.abi,
                functionName: "removeLiquidityETHWithPermit",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "removeLiquidityETHWithPermit",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETHWithPermit] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('removeLiquidityETHWithPermit', '[removeLiquidityETHWithPermit] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETHWithPermit] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    removeLiquidityWithPermit: async (data): Promise<TransactionReceipt> => {

        const { token0Address, token1Address, LPTokensToRemove, walletAddress, signature, deadLine } = data;
        const { v, r, s } = signature;

        try {
            // Input Validation
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Input Data', 'info', { token0Address: token0Address, token1Address: token1Address, LPTokensToRemove: LPTokensToRemove,  walletAddress: walletAddress, signature: signature, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if ( !v || !r || !s) {
                throw new Error("Signature Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] simulateContract Input Data', 'info', {             
                address: routerDetails.contractAddress as Hex, 
                //abi: routerDetails.abi,
                functionName: "removeLiquidityWithPermit",
                args: [ 
                    token0Address,
                    token1Address,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "removeLiquidityWithPermit",
                args: [ 
                    token0Address,
                    token1Address,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine,
                    false,
                    v,
                    r,
                    s,
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
            
            return  waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityWithPermit] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    removeLiquidityETH: async (data): Promise<TransactionReceipt> => {

        const { NonNativeTokenAddress, LPTokensToRemove, walletAddress, deadLine } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Input Data', 'info', { NonNativeTokenAddress: NonNativeTokenAddress, LPTokensToRemove: LPTokensToRemove, walletAddress: walletAddress, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(NonNativeTokenAddress)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Input Validation Successful', 'info');
            

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] simulateContract Input Data', 'info', {             
                address: routerDetails.contractAddress as Hex, 
                //abi: routerDetails.abi,
                functionName: "removeLiquidityETH",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "removeLiquidityETH",
                args: [ 
                    NonNativeTokenAddress,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress,
                    deadLine
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Transaction Submitted', 'info', { Output: result });
  
            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);

            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidityETH] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    removeLiquidity: async (data): Promise<TransactionReceipt> => {

        const { token0Address, token1Address, LPTokensToRemove, walletAddress, deadLine } = data;

        try {
            // Input Validation
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Input Data', 'info', { token0Address: token0Address, token1Address: token1Address, LPTokensToRemove: LPTokensToRemove, walletAddress: walletAddress, deadLine: deadLine });
            if (BigInt(LPTokensToRemove) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress) || !isAddress(token0Address) || !isAddress(token1Address)) {
                throw new Error("Address Validation Failed...");
            }

            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (deadLine <= currentTimestamp) {
                throw new Error("Deadline Validation Failed...");
            }
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Input Validation Successful', 'info');


            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] simulateContract Input Data', 'info', {             
                address: routerDetails.contractAddress as Hex, 
                //abi: routerDetails.abi,
                functionName: "removeLiquidity",
                args: [ 
                    token0Address as Hex,
                    token1Address as Hex,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: routerDetails.contractAddress as Hex, 
                abi: routerDetails.abi,
                functionName: "removeLiquidity",
                args: [ 
                    token0Address as Hex,
                    token1Address as Hex,
                    BigInt(LPTokensToRemove),
                    BigInt(0),
                    BigInt(0),
                    walletAddress as Hex,
                    deadLine
                ],
            });

            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('LiquidityRemoval', '[removeLiquidity] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

};

export default LibfiServiceAAM;
