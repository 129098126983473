import { TOKEN_DETAILS_SINGLE } from "../../interfaces/commonInterfaces";
import { timeoutPromise, getContractDetails } from "../libfiService_Common";
import { amanaVaultAllowanceHelper } from "./TokensAllowancesHelper";
import { handleError, handleErrorNoRetryButton, handlePendingApproval, handleSuccess } from "./TransactionModalHelper";
import { addBreadcrumb, captureException } from './../../SentryContext';
import LibfiServiceAmanaVault from "../libfiService_Amana_Vault";
import LibfiServiceCommon from "../libfiService_Common";


/**
* This helper function executes remove liquidity for both native and custom tokens
* @param data it is an object that contains various params required for this function
* @returns a boolean 
**/
export const removeAmanaVaultHelperFunction = async (
  shareDetails: TOKEN_DETAILS_SINGLE,
  withdrawPercentage: number,
  walletAddress: string,
  amanaVaultAddress: string,
  handleOpen: Function,
  setModalData: Function,
  t: (key: string) => string,
) => {
  
  try {

    const inputData = {
      tokenDetails: shareDetails,
      withdrawPercentage: withdrawPercentage,
      walletAddress: walletAddress, 
    };

    addBreadcrumb('AmanaLiquidityRemoval', '[removeAmanaVaultHelperFunction] Input Data', 'info', { inputdata: inputData });
    if ( !shareDetails || !withdrawPercentage || !walletAddress ) throw new Error("Input Validation Failed...");
    addBreadcrumb('AmanaLiquidityRemoval', '[removeAmanaVaultHelperFunction] Input Validation Successful', 'info');

    handlePendingApproval(setModalData, t('ammPoolsRemoveLiquidityHeading'), t('earnModelRemoveLiquidityInit'));
    handleOpen();

    addBreadcrumb('AmanaLiquidityRemoval', '[userVaultShares] Input Data', 'info', { walletAddress: walletAddress, tokenAddress: shareDetails.tokenAddress, type: 'AmanaVault' });
    const userVaultShares = await timeoutPromise(LibfiServiceCommon.getBalance(walletAddress, amanaVaultAddress, "amanaVault"), 10000);
    addBreadcrumb('AmanaLiquidityRemoval', '[userVaultShares] Output Data', 'info', { userVaultShares: userVaultShares });

    addBreadcrumb('AmanaLiquidityRemoval', '[vaultShareDecimals] Input Data', 'info', { walletAddress: walletAddress, type: 'AmanaVault' });
    const vaultShareDecimals = await timeoutPromise(LibfiServiceCommon.getDecimals(amanaVaultAddress, "amanaVault"), 10000);
    addBreadcrumb('AmanaLiquidityRemoval', '[vaultShareDecimals] Output Data', 'info', { vaultShareDecimals: vaultShareDecimals });

    const sharesAmountToRedeem = ( BigInt(withdrawPercentage) * userVaultShares.bigIntBalance ) / BigInt(100);
    addBreadcrumb('AmanaLiquidityRemoval', 'sharesAmountToRedeem/convertedSharesAmountToRedeem Data', 'info', { sharesAmountToRedeem: sharesAmountToRedeem });

    const amanaVaultAllowanceHelperInputData = {
      tokenDetails: shareDetails,
      inputValue: sharesAmountToRedeem.toString(),
      walletAddress, 
      amanaVaultAddress,
      t
    };

    const amanaVaultAllowanceHelperResponse = await amanaVaultAllowanceHelper(setModalData, amanaVaultAllowanceHelperInputData);

    handlePendingApproval(setModalData, t('ammPoolsRemoveLiquidityHeading'), 
      t('amanaVaultsTrxConfirmText').replace('{{token1}}', shareDetails.tokenName || ""));

    const removeLiquidityResponse = await LibfiServiceAmanaVault.redeemAmanaVault({
      amanaVaultAddress,
      amount: sharesAmountToRedeem,
      walletAddress
    });

    addBreadcrumb('AmanaLiquidityRemoval', '[removeAmanaVaultHelperFunction] Liquidity Removed', 'info');

    return handleSuccess(setModalData, t('ammPoolsRemoveLiquidityHeading'), 
      t('amanaVaultsTrxSuccessText').replace('{{token1}}', shareDetails.tokenName || ""), removeLiquidityResponse.transactionHash);

  } catch (error) {
    addBreadcrumb('AmanaLiquidityRemoval', '[removeAmanaVaultHelperFunction] Error Occured', 'info', { errorMessage: error });
    if (error && !error.toString().includes("User rejected the request")) {
      captureException(error);
    }
    return handleError(setModalData, t('ammPoolsRemoveLiquidityHeading'), 
      t('amanaVaultsRemoveLiquidityFailedText').replace('{{token1}}', shareDetails.tokenName || ""))
  }
};


