import React, { useEffect, useState } from "react";
import { useDynamicContext, useEmbeddedWallet } from '@dynamic-labs/sdk-react-core';
import CommonHeading from "../../../Common/Heading/Heading";
import videoPoster from "../../../../Assets/Images/liberty_finance_thumbnail.svg";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserOnboardingData } from "../../../../Redux/Slices/user.slice";
import { PlayIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from 'react-i18next';
import "./Welcome.scss";


const WelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useDynamicContext();
  const [imageVisible, setImageVisible] = useState<boolean>(false);

  useEffect(() => { 
    dispatch(setUserOnboardingData({ progress: 0, path: "/onboarding" }));
  }, []);

  const handleVideoEnded = () => {
    setImageVisible(!imageVisible);
  };
  
  const handleProgressFunction = async () => {
    const verifiedCredentials = user?.verifiedCredentials
    if (verifiedCredentials?.length === 1 && verifiedCredentials[0].email) {
      dispatch(setUserOnboardingData({ progress: 25, path: "/onboarding/kyc-profile" }));
      navigate("/onboarding/kyc-profile");
    } else {
      dispatch(setUserOnboardingData({ progress: 75, path: "/onboarding/add-profile" }));
      navigate("/onboarding/add-profile");
    }
  };

  return (
    <section className="WelcomePage">
      <CommonHeading heading={t("welcome")} centered />
      <div className="BannerVideo">
        <ReactPlayer
          canPlay
          className="mobile-responsive"
          url={require(`../../../../Assets/Videos/${t('welcomeVideoName')}`)}
          light={
            <img className="Thumbnail" src={videoPoster} alt="Thumbnail" />
          }
          pip={true}
          controls
          playIcon={
            <button className="playIcon">
              <PlayIcon />
            </button>
          }
          playing={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          onEnded={handleVideoEnded}
          {...(imageVisible && (
            <img src={videoPoster} alt="After Video Image" />
          ))}
        />
      </div>
      <ButtonCustom
        title={ t("continueButton")}
        className="continueBtn"
        centered
        onClick={() => handleProgressFunction()}
      />
    </section>
  );
};

export default WelcomePage;
