import React from "react";
import { Dropdown, Form } from "react-bootstrap";
import { DownArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import "./TokenSelect.scss";


const TokenSelect = ({
  options,
  className,
  id,
  defaultValue,
  dropIcon,
  selectedField,
  label,
  lastItem,
  SelectTokens,
}: any) => {
  
  return (
    <>
      {/* <Select
        options={options}
        defaultValue={tokens[0]}
        className={`TokenSelect ${className}`}
        classNamePrefix={"select"}
        isSearchable={false}
      /> */}
      <div className={`token_selector ${className || ""}`}>
        {label && <Form.Label>{label}</Form.Label>}
        <Dropdown>
          <Dropdown.Toggle id={id}>
            {defaultValue?.label}
            {dropIcon && (
              <span className="dropdown_icon">
                <DownArrow />
              </span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="dropdown_menu_inner">
              {options?.map((item) => (
                <Dropdown.Item
                  onClick={() =>
                    !item?.isSelected ? SelectTokens(item, selectedField) : null
                  }
                  // className={
                  //   selectedOption?.symbol === item?.symbol ? "active" : ""
                  // }
                  key={item?.id}
                  disabled={item?.isSelected == true}
                  className={item?.isSelected == true ? "active" : ""}
                >
                  {item?.label}
                </Dropdown.Item>
              ))}
            </div>
            {lastItem && (
              <div className="menu_last_item">
                <Dropdown.Item>{lastItem}</Dropdown.Item>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default TokenSelect;
