import * as Yup from "yup";

export const loginSchema = (t: (key: string) => string) => Yup.object().shape({
  name: Yup.string()
    .min(3, t('nameMinSizeValidation'))
    .max(20, t('nameMaxSizeValidation')),
  firstname: Yup.string()
    .min(1, t('nameMinSizeValidation'))
    .max(30, t('nameMaxSizeValidation')),
  lastname: Yup.string()
    .min(1, t('nameMinSizeValidation'))
    .max(30, t('nameMaxSizeValidation')),
  email: Yup.string().matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    t('emailValidValidation')
  ),
});

