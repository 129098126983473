import { callApiPostMethod } from "../../Redux/Actions/api.action";
import { AMANA_VAULTS } from "../../Redux/Actions/apiResponseInterfaces";
import { ZERO_ADDRESS } from "../../Utils";
import { tokenCollection } from "../../Services/dynamicContractDetails";
import LibfiService, { getContractDetails, timeoutPromise } from "../libfiService_Common";
import { divideWithDecimal } from "../../Services/common.service";
import LibfiServiceAmanaVault from "../libfiService_Amana_Vault";
import LibfiServiceCommon from "../libfiService_Common";


export const getAmanaVaultsData = async ({
  dispatch,
  walletAddress,
}: {
    dispatch: any;
    walletAddress: string;
  }) => {

  const result = await dispatch(
    callApiPostMethod(
      "GET_AMANA_VAULTS",
      {},
      false,
      false
    )
  );

  // Create a map for faster lookup of token details and dollar values
  const tokenDetails = new Map(
    tokenCollection.map((info: any) => [info.address?.toLowerCase(), { img: info.img, name: info.name, isNative: info.isNative }])
  );

  // Function to handle each data item
  const processData = async (data: any) => {
    if (data?.walletAddress === ZERO_ADDRESS?.toLowerCase() || data?.tokenAddress === ZERO_ADDRESS?.toLowerCase()) {
      return null;
    }

    try {
      
      data.amanaVaultAddress = data?.amanaVaultAddress;
      data.amanaVaultType = "Flexible";

      const [
        vaultShareName,
        vaultShareSymbol,
        totalUnderlyingAsset, // Users Assets Locked + Rewards
        totalVaultShares, // User Assets Locked Only
        vaultShareDecimals
      ] = await Promise.all([
        timeoutPromise(LibfiServiceAmanaVault.getShareName(data?.amanaVaultAddress), 10000),
        timeoutPromise(LibfiServiceAmanaVault.getShareSymbol(data?.amanaVaultAddress), 10000),
        timeoutPromise(LibfiServiceAmanaVault.getTotalUnderlyingAssets(data?.amanaVaultAddress), 10000),
        timeoutPromise(LibfiServiceCommon.getTotalSupply(data?.amanaVaultAddress, "amanaVault"), 10000),
        timeoutPromise(LibfiServiceCommon.getDecimals(data?.amanaVaultAddress, "amanaVault"), 10000),
      ]);

      data.underlyingAssetAddress = data?.tokenAddress;
      data.underlyingAssetName = tokenDetails.get(data?.underlyingAssetAddress?.toLowerCase())?.name;
      data.underlyingAssetURI = tokenDetails.get(data?.underlyingAssetAddress?.toLowerCase())?.img;
      data.underlyingAssetNative = tokenDetails.get(data?.underlyingAssetAddress?.toLowerCase())?.isNative;
      data.underlyingAssetDecimals = data?.tokenDecimals;
      data.underlyingAssetDollarValue = data?.tokenDollarValue;
      data.totalUnderlyingAssets = divideWithDecimal(totalUnderlyingAsset, data?.underlyingAssetDecimals);
      
      data.vaultShareName = vaultShareName;    
      data.vaultShareSymbol = vaultShareSymbol;    
      data.vaultShareDecimals = vaultShareDecimals;
      data.totalVaultShares = divideWithDecimal(totalVaultShares, vaultShareDecimals);    

      data.sharedProfit = 50000000;
      data.volumeInUSD = 300000;

      data.TVL = Number( divideWithDecimal(totalUnderlyingAsset, data?.underlyingAssetDecimals) ) * data?.underlyingAssetDollarValue;

      if (walletAddress) {

        const userVaultShares = await timeoutPromise(LibfiServiceCommon.getBalance(walletAddress, data?.amanaVaultAddress, "amanaVault"), 10000);
        const userVaultSharesToUnderlyingAsset = await timeoutPromise(LibfiServiceAmanaVault.convertSharesToUnderlyingAssets(data?.amanaVaultAddress, userVaultShares.bigIntBalance), 10000);

        data.userVaultShares = divideWithDecimal(userVaultShares.bigIntBalance.toString(), vaultShareDecimals);
        data.userVaultSharesToUnderlyingAsset = divideWithDecimal(userVaultSharesToUnderlyingAsset, data?.underlyingAssetDecimals);

        data.userShare = userVaultShares.bigIntBalance !== BigInt(0)
                      ? ( Number(userVaultShares.bigIntBalance) * 100 ) / Number(totalVaultShares)
                      : 0;

        data.userShareDollarValue = data.userShare !== 0
                                      ? data.userShare * data.TVL / 100
                                      : 0;          

      } else {
        data.userVaultShare = 0;
        data.userVaultSharesToUnderlyingAsset = 0;
        data.userShare = 0;
        data.userShareDollarValue = 0;
      }
      return data;
    } catch (error) {
      return null;
    }
  };

  // Process all pools data in parallel
  let amanaVaultData: AMANA_VAULTS["data"] = await Promise.all(result?.data?.map(processData) || []);
 
  // Filter out null values
  amanaVaultData = amanaVaultData.filter(amanaVault => amanaVault !== null);

  if (amanaVaultData.length > 0) {
    return amanaVaultData;
  } else {
    return [];
  }
};

