import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./OrderDetailsModal.scss";

import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CloseIcon, DoubleTickIcon, FailedIcon, WaitingIcon, WalletIcon } from "../../../../../Assets/Images/Icons/SvgIcons";
import { TIME_LEFT } from "../../../../../interfaces/commonInterfaces";
import { callApiPostMethod } from "../../../../../Redux/Actions/api.action";
import { formatDateTime } from "../../../../../Services/common.service";
import { ON_MAINNET } from "../../../../../Utils";
import ButtonCustom from "../../../../Common/Button/ButtonCustom";
import CommonCard from "../../../../Common/Cards/CommonCard/CommonCard";
import { ON_OFF_RAMP_TYPES } from "../OnOffRamp/OnOffRampPage";
import OnOffRampSuccessPage from "../OnOffRamp/OnOffRampSuccessPage";
import OrderTransferDetailsModal from "./OrderTransferDetailsModal";

type OrderDetailsProps = {
    show: boolean;           // Indicates whether the order details modal is shown or hidden
    handleClose: Function;   // Function to handle closing the order details modal
    orderNumber?: string;    // Order number associated with the order details
}

interface STAGES {
    stage: number;        // Stage identifier
    title: string;        // Title of the stage
    description: string;  // Description of what the stage entails
    timestamp?: string;   // Optional timestamp associated with the stage
    icon: any;            // Icon or visual representation of the stage
    completed?: boolean;  // Optional flag indicating if the transaction is completed
    waiting?: boolean;    // Optional flag indicating if the transaction is waiting or pending
    download?: boolean;   // Optional flag indicating if download is available at this stage
    expired?: boolean;     // Optional flag indicating if the transaction has expired
    failed?: boolean;      // Optional flag indicating if the transaction has failed
    declined?: boolean;    // Optional flag indicating if the transaction has been declined
}

// Order Types which are stored in database
export const ORDER_DB_TYPES = {
    ONRAMP: "ONRAMP",
    OFFRAMP: "OFFRAMP"
}

// Payment statuses which are stored in database
export const PAYMENT_DB_STATUSES = {
    INITIATED: "INITIATED",
    // APPROVED: "APPROVED",
    PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
    // PROCESSED: "PROCESSED",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    DECLINED: "DECLINED"
}

const OrderDetailsModal = ({ orderNumber, show, handleClose }: OrderDetailsProps) => {
    const dispatch: any = useDispatch();
    const { t, i18n } = useTranslation();
    const [stages, setStages] = useState<STAGES[]>([]);
    const [orderDetails, setOrderDetails] = useState<any>();

    // to store the value of time left till the expiry date of the transaction
    const [timeLeft, setTimeLeft] = useState<TIME_LEFT>();

    // this will store the formatted timeLeft value
    const [timeLeftText, setTimeLeftText] = useState<string>("");

    // Toggle indicator to show bank/transfer details
    const [showBankDetails, setShowBankDetails] = useState(false);

    // Display date based on selected langauge
    moment.locale(i18n.language);

    // Updates the time left value every second and update the stages once order details got updated
    useEffect(() => {
        updateOrderDetails(orderDetails);

        //return if expiry date is not coming from payment gateway
        if (!orderDetails?.expiryDate) return;

        const setTimeLeftValue = () => {
            setTimeLeft(calculateTimeLeft(orderDetails?.expiryDate));
        }

        setTimeLeftValue();

        //start the interval to show remaining time left
        const timer = setInterval(setTimeLeftValue, 1000);// Update every second

        return () => clearInterval(timer); // Cleanup on component unmount
    }, [orderDetails])

    // Fetch the order details once order number is updated
    useEffect(() => {
        if (!orderNumber || !show) return;

        // Initial fetch
        fetchOrderDetails();

        // Repeated call every 5 seconds
        const intervalId = setInterval(fetchOrderDetails, 5000);

        return () => {
            clearInterval(intervalId); // Cleanup on component unmount
        }
    }, [orderNumber, show])

    // Updates the formatted time left (timeLeftText) value when time left is updated
    useEffect(() => {
        // return if expiry date is not coming from payment gateway
        if (!orderDetails?.expiryDate) return;

        setTimeLeftText(getTimeLeftText(timeLeft));

    }, [timeLeft])

    // When timeLeftText is updated, need to update timeleft value into the stages (i.e. Initiated, processed etc...) array to get reflected on screen.  
    useEffect(() => {
        // return if expiry date is not coming from payment gateway
        if (!orderDetails?.expiryDate) return;

        // Update the od array when stage1WaitingDesc changes
        const newStages: any[] = stages.map((item: any) => {

            // Condition which specifies the stage element of array where timeleft is visible.
            if (item.stage === 1 && item.waiting) {
                return {
                    ...item,
                    waiting: !!timeLeftText,
                    expired: !timeLeftText,
                    title: timeLeftText ? item.title : t('onOffRampOrderDetailsWaitingTrxExpired'),
                    description: ORDER_DB_TYPES.ONRAMP === orderDetails?.type
                        ? timeLeftText ? t('onOffRampOrderDetailsWaitingForMoneyDescription').replace("{{time}}", timeLeftText) : t('onOffRampOrderDetailsWaitingForMoneyExpiredDescription')
                        : timeLeftText ? t('onOffRampOrderDetailsWaitingForCryptoDescription').replace("{{time}}", timeLeftText) : t('onOffRampOrderDetailsWaitingForCryptoExpiredDescription')
                };
            } else {
                return item;
            }
        });

        setStages(newStages);
    }, [timeLeftText])

    /**
     * This function will convert TIME_LEFT object in a string format with language translation
     * 
     * @param timeLeft TIME_LEFT {hours, minutes, seconds}
     * @returns 
     */
    const getTimeLeftText = (timeLeft: TIME_LEFT | undefined) => {
        if (timeLeft) {
            if (timeLeft.hours > 0) {
                return t('onOffRampTimeLeftHours').replace("{{hours}}", timeLeft.hours.toString());
            } else if (timeLeft.minutes > 0) {
                return t('onOffRampTimeLeftMinutes').replace("{{minutes}}", timeLeft.minutes.toString());
            } else if (timeLeft.seconds > 0) {
                return t('onOffRampTimeLeftSeconds').replace("{{seconds}}", timeLeft.seconds.toString());
            } else {
                return ""
            }
        }
        return "";
    }

    /**
     * This function will calculate the time left value by expiry date provided with the current date.
     * 
     * @param targetDate Expiry date of the transaction
     * @returns {Object} This will return Object with hours, minutes and seconds remaining.
     * - hours - Number of hours remaining
     * - minutes - Number of minutes remaining
     * - seconds - Number of seconds remaining
     */
    const calculateTimeLeft = (targetDate: string) => {
        // Get the current time using moment
        const now = moment();

        // Parse the target date using moment
        const end = moment.utc(targetDate);

        // Calculate the difference in milliseconds
        const difference = end.diff(now);

        // If the difference is positive, calculate hours, minutes, and seconds
        if (difference > 0) {
            const duration = moment.duration(difference);
            const hours = Math.floor(duration.asHours());
            const minutes = Math.floor(duration.minutes());
            const seconds = Math.floor(duration.seconds());
            return { hours, minutes, seconds };
        } else {
            // If the time has already passed, return 0 for all units
            return { hours: 0, minutes: 0, seconds: 0 };
        }
    }

    /**
     * This method will update the stages of the order details. Set the stages information whether they are in waiting stage, completed, processed or failed.
     * There are total 3 stages
     * - 1. Crypto/Currency transaction Initiate (once Initiated or Approved it is Waiting stage, once Payment received completed stage)
     * - 2. Crypto/Currency trasaction processing stage 
     * - 3. Crypto/Currency transaction completed stage (Completed, Failed, Declined)
     * 
     * @param orderInfo Order information fetched from database collection
     */
    const updateOrderDetails = (orderInfo: any) => {
        if (orderInfo) {
            const { type, stages: localStages, expiryDate } = orderInfo;
            let od: STAGES[] = [];

            const stage1Waiting = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.INITIATED);
            const stage1Completed = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.PAYMENT_RECEIVED);
            const stage2Waiting = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.PAYMENT_RECEIVED);
            const stage2Completed = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.COMPLETED);
            const stage3Waiting = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.COMPLETED);
            const stage3Completed = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.COMPLETED);
            const failed = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.FAILED);
            const declined = localStages.find((ts: any) => ts.stage === PAYMENT_DB_STATUSES.DECLINED);

            let timeLeftStr = expiryDate ? getTimeLeftText(calculateTimeLeft(expiryDate)) : "";
            if (stage1Completed) {
                od.push({
                    stage: 1,
                    title: ORDER_DB_TYPES.ONRAMP === type ? t('onOffRampOrderDetailsMoneyReceived') : t('onOffRampOrderDetailsCryptoReceived'),
                    description: ORDER_DB_TYPES.ONRAMP === type
                        ? t('onOffRampOrderDetailsMoneyReceivedDescription')
                            .replace("{{amount}}", orderInfo?.amountPayable)
                            .replace("{{currency}}", orderInfo?.currency)
                            .replace("{{timestamp}}", formatDateTime(stage1Completed?.timestamp))
                        : t('onOffRampOrderDetailsCryptoReceivedDescription')
                            .replace("{{amount}}", orderInfo?.amountPayable)
                            .replace("{{token}}", orderInfo?.token)
                            .replace("{{timestamp}}", formatDateTime(stage1Completed?.timestamp)),
                    timestamp: stage1Completed.timestamp,
                    icon: <DoubleTickIcon />,
                    completed: true,
                    download: true
                })
            } else if (stage1Waiting) {
                if (failed || declined) {
                    od.push({
                        stage: 1,
                        title: t('onOffRampOrderDetailsTransactionFailed'),
                        description: t('onOffRampOrderDetailsTransactionFailedDescription'),
                        icon: <FailedIcon />,
                        download: true,
                        failed: !!failed,
                        declined: !!declined
                    })
                } else {
                    if (orderDetails?.expiryDate) {
                        od.push({
                            stage: 1,
                            title: timeLeftStr ? ORDER_DB_TYPES.ONRAMP === type ? t('onOffRampOrderDetailsWaitingForMoney') : t('onOffRampOrderDetailsWaitingForCrypto') : t('onOffRampOrderDetailsWaitingTrxExpired'),
                            description: ORDER_DB_TYPES.ONRAMP === orderDetails?.type
                                ? timeLeftStr ? t('onOffRampOrderDetailsWaitingForMoneyDescription').replace("{{time}}", timeLeftStr) : t('onOffRampOrderDetailsWaitingForMoneyExpiredDescription')
                                : timeLeftStr ? t('onOffRampOrderDetailsWaitingForCryptoDescription').replace("{{time}}", timeLeftStr) : t('onOffRampOrderDetailsWaitingForCryptoExpiredDescription'),
                            icon: !!timeLeftStr ? <WaitingIcon /> : <FailedIcon />,
                            waiting: !!timeLeftStr,
                            download: true,
                            expired: !timeLeftStr
                        })
                    } else {
                        od.push({
                            stage: 1,
                            title: ORDER_DB_TYPES.ONRAMP === type ? t('onOffRampOrderDetailsWaitingForMoney') : t('onOffRampOrderDetailsWaitingForCrypto'),
                            description: ORDER_DB_TYPES.ONRAMP === orderDetails?.type
                                ? t('onOffRampOrderDetailsWaitingForMoneyDescription1')
                                : t('onOffRampOrderDetailsWaitingForCryptoDescription1'),
                            icon: <WaitingIcon />,
                            waiting: true,
                            download: true
                        })
                    }
                }
            }

            if (stage2Completed) {
                od.push({
                    stage: 2,
                    title: t('onOffRampOrderDetailsTransactionProcessed'),
                    description: t('onOffRampOrderDetailsTransactionProcessedDescription'),
                    timestamp: stage2Completed.timestamp,
                    icon: <DoubleTickIcon />,
                    completed: true,
                })
            } else if (stage2Waiting) {
                if (failed || declined) {
                    od.push({
                        stage: 2,
                        title: t('onOffRampOrderDetailsTransactionFailed'),
                        description: t('onOffRampOrderDetailsTransactionFailedDescription'),
                        icon: <FailedIcon />,
                        failed: !!failed,
                        declined: !!declined
                    })
                } else {
                    od.push({
                        stage: 2,
                        title: t('onOffRampOrderDetailsTransactionProcessing'),
                        description: t('onOffRampOrderDetailsTransactionProcessingDescription'),
                        icon: <WaitingIcon />,
                        waiting: true
                    })
                }
            } else {
                od.push({
                    stage: 2,
                    title: t('onOffRampOrderDetailsTransactionProcessing'),
                    description: "",
                    icon: <WaitingIcon />
                })
            }

            if (stage3Completed) {
                od.push({
                    stage: 3,
                    title: ORDER_DB_TYPES.ONRAMP === type ? t('onOffRampOrderDetailsCryptoSent') : t('onOffRampOrderDetailsFundsSent'),
                    description: ORDER_DB_TYPES.ONRAMP === type
                        ? t('onOffRampOrderDetailsCryptoSentDescription').replace("{{time}}", "2-3 days")   //TODO: Need to update static text
                        : t('onOffRampOrderDetailsFundsSentDescription').replace("{{time}}", "2-3 days"),   //TODO: Need to update static text
                    timestamp: stage3Completed.timestamp,
                    icon: <DoubleTickIcon />,
                    completed: true
                })
            } else if (stage3Waiting) {
                if (failed || declined) {
                    od.push({
                        stage: 3,
                        title: t('onOffRampOrderDetailsTransactionFailed'),
                        description: t('onOffRampOrderDetailsTransactionFailedDescription'),
                        icon: <FailedIcon />,
                        failed: !!failed,
                        declined: !!declined
                    })
                } else {
                    od.push({
                        stage: 3,
                        title: ORDER_DB_TYPES.ONRAMP === type ? t('onOffRampOrderDetailsCryptoDelivery') : t('onOffRampOrderDetailsFundsDelivery'),
                        description: "",
                        icon: <WaitingIcon />,
                        waiting: true,
                    })
                }
            } else {
                od.push({
                    stage: 3,
                    title: ORDER_DB_TYPES.ONRAMP === type ? t('onOffRampOrderDetailsCryptoDelivery') : t('onOffRampOrderDetailsFundsDelivery'),
                    description: "",
                    icon: <WalletIcon />
                })
            }

            setStages(od);
        } else {
            setStages([]);
        }
    }

    /**
     * Fetch the order details from the order Number
     * 
     * @returns 
     */
    const fetchOrderDetails = async () => {
        if (!orderNumber) return;
        const result: any = await dispatch(callApiPostMethod("ON_OFF_RAMP_ORDER_DETAILS", { orderNumber }, false, false));
        setOrderDetails(result?.data);
    }

    const simulatePayment = async () => {
        const result: any = await dispatch(callApiPostMethod("SIMULATE_PAYMENT", { reference: orderDetails?.reference }))
    }

    return (
        <Modal
            backdropClassName="order-details-bg"
            centered
            show={show}
            className="order-details-modal"
        >
            <button
                className="modal_close_btn"
                onClick={() => handleClose()}
            >
                <CloseIcon />
            </button>
            <Modal.Body>
                <CommonCard
                    noHeaderSpacing
                    cardTitle={"Transaction Status"}
                    className="order-details"
                // viewAllNavigate="/dashboard/portfolio"
                >
                    <div className="on-off-ramp-order-details-page">
                        <hr />
                        <div className="on-off-ramp-order-details-body">
                            {
                                stages && stages.map((stage: any) =>
                                    <div className="stage">
                                        {/* Append the relavent classes to apply css for every stage */}
                                        <div className={`stage-icon${stage.waiting ? " waiting" : ""}${stage.completed ? " completed" : ""}${stage.failed || stage.declined || stage.expired ? " failed" : ""}`}>{stage.icon}</div>
                                        <div className="stage-details">
                                            <h3 className={stage.failed || stage.declined || stage.expired ? "failed" : ""}>{stage.title}</h3>
                                            <p>{stage.description}</p>
                                            {
                                                // Show download transfer details button if download flag is true for particular stage
                                                stage.download &&
                                                (<>
                                                    <div className="simulate-payment-wrapper">
                                                        {!(stage.expired) && <div>
                                                            <a href="javascript: void(0);" onClick={() => setShowBankDetails(true)}>{t('onOffRampViewTransferDetails')}</a>
                                                        </div>}
                                                        {!ON_MAINNET
                                                            && ORDER_DB_TYPES.ONRAMP === orderDetails?.type
                                                            && !(stage.failed || stage.declined || stage.expired)
                                                            && <ButtonCustom className="simulate-payment-btn" onClick={simulatePayment}>{t("simulatePaymentTestNetBtn")}</ButtonCustom>}
                                                    </div>
                                                </>)

                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="on-off-ramp-order-details-footer">
                            <ButtonCustom
                                title="Close"
                                onClick={() => handleClose()}
                            />
                        </div>
                    </div>
                    {/* Show On/Off Ramp Transfer details, this will be shown based on Transfer Details button click */}
                    <OrderTransferDetailsModal
                        show={showBankDetails}
                        handleClose={() => setShowBankDetails(false)}
                        className="on-off-ramp-payment-page"
                    >
                        {
                            ORDER_DB_TYPES.ONRAMP === orderDetails?.type ?
                                <OnOffRampSuccessPage
                                    type={ON_OFF_RAMP_TYPES.BUY}
                                    reference={orderDetails?.reference}
                                    bankName={orderDetails?.bankName}
                                    accountName={orderDetails?.accountName}
                                    accountNumber={orderDetails?.accountNumber}
                                    amount={orderDetails?.amountPayable}
                                    currency={orderDetails?.currency}
                                    expiresAt={orderDetails?.expiryDate}
                                    walletAddress={orderDetails?.walletAddress}
                                    handleClose={() => {
                                        setShowBankDetails(false);
                                    }}
                                />
                                : <OnOffRampSuccessPage
                                    type={ON_OFF_RAMP_TYPES.SELL}
                                    reference={orderDetails?.reference}
                                    amount={orderDetails?.amountPayable}
                                    currency={orderDetails?.currency}
                                    token={orderDetails?.token}
                                    expiresAt={orderDetails?.expiryDate}
                                    walletAddress={orderDetails?.trxWalletAddress}
                                    handleClose={() => {
                                        setShowBankDetails(false);
                                    }}
                                />
                        }

                    </OrderTransferDetailsModal>
                </CommonCard>
            </Modal.Body>
        </Modal>
    )
}

export default OrderDetailsModal