import React, { useState } from "react";
import Lottie from "lottie-react";
import loading from "../../../../Assets/animations/loading.json";
import tick from "../../../../Assets/animations/tick.json";
import error from "../../../../Assets/animations/error.json";
import "./TransactionModal.scss";
import { Col, Modal, Row } from "react-bootstrap";
import ButtonCustom from "../../Button/ButtonCustom";
import { CloseIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import { EXPLORER_LINK } from "../../../../Utils";
import { useTranslation } from "react-i18next";

const TransactionModal = ({
  show,
  handleClose,
  modalData,
  handleFunction,
}: {
  show: boolean;
  handleClose: () => void;
  modalData: any;
  handleFunction: Function;
  }) => {
  const { t } = useTranslation();
  const viewTransactiononExplorer = (e) => {
    //e.preventDefault();
    window.open(`${EXPLORER_LINK}tx/${modalData?.txHash}`);
  };
  return (
    <Modal
      className="transaction_modal"
      show={show}
      centered
      handleClose={handleClose}
      backdropClassName="transaction_modal_bckdrop"
      //   backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{modalData?.heading}</Modal.Title>
        {!modalData.hideCloseButton && (
          <button className="modal_close_btn" onClick={() => handleClose()}>
            <CloseIcon />
          </button>
        )}
      </Modal.Header>
      <Modal.Body title={modalData?.heading}>
        <div
          className={`lottie_animation ${
            modalData?.status == "success" ? "tick_animation" : ""
          }`}
        >
          <Lottie 
            animationData={modalData?.status === "success" ? tick : 
              modalData?.status === "error" ? error : loading}
            loop={!(modalData?.status === "success" || modalData?.status === "error")}
            autoplay={true}
          />
        </div>
        <p className="transaction_text">{modalData?.bodyText}</p>
        {modalData?.txHash ? <div className="url_box"></div> : null}
        {modalData?.status == "success" || modalData?.status == "error" ? (
          <div className={`transaction_action_btn ${modalData.hideRetryButton ? 'action-btn-center-button' : ''}`}>      
            <Row className={`${modalData.hideRetryButton ? 'row-center-button' : ''}`}>
              <Col
                sm={
                  modalData?.heading == t('tradeLimitStopLoss') ||
                  modalData?.heading == t('tradeLimitOrder')
                    ? 12
                    : 6
                }
                className={`${modalData.hideRetryButton ? 'col-center-button' : ''}`}
              >
                <ButtonCustom
                  fluid
                  onClick={() => handleClose()}
                  title={t('transactionModalClose')}
                  className="danger"
                />
              </Col>
              {modalData?.status == "error" && !modalData.hideRetryButton ? (
                <Col sm={6}>
                  <ButtonCustom
                    fluid
                    title={t('transactionModalRetry')}
                    onClick={() => handleFunction()}
                  />
                </Col>
              ) : modalData?.status == "success" && modalData?.txHash ? (
                <Col sm={6}>
                  <ButtonCustom
                    fluid
                    title={t('transactionModalViewTransaction')}
                    onClick={(e) => viewTransactiononExplorer(e)}
                  />
                </Col>
              ) : null}
            </Row>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default TransactionModal;
