import { useEffect, useState, useCallback } from "react";
import { TOKEN_DETAILS, TOKEN_DETAILS_SINGLE } from "../../interfaces/commonInterfaces";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import LibfiServiceCommom, { timeoutPromise } from "../libfiService_Common";
import { ZERO_ADDRESS } from "../../Utils";
import LibfiServiceAAM from "../libfiService_AAM";


export const useFetchTokenBalance = ({
  tokenDetails,
  dispatch
}: {
  tokenDetails: TOKEN_DETAILS;
  dispatch: any;
}) => {
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(false);
  const { primaryWallet } = useDynamicContext();

  const [tokenBalance, settokenBalance] = useState<{
    token1Balance: number;
    token1BalanceConverted: bigint;
    token2Balance: number;
    token2BalanceConverted: bigint;
  }>({
    token1Balance: 0,
    token1BalanceConverted: BigInt(0),
    token2Balance: 0,
    token2BalanceConverted: BigInt(0),
  });

  const fetchData = useCallback(async () => {

    setFetchingBalance(true); 

    if (primaryWallet?.connected) {
      const {
        isToken0Native,
        isToken1Native,
        token0Address,
        token1Address,
      } = tokenDetails;

      const token1Details: TOKEN_DETAILS_SINGLE | undefined = { tokenAddress: token0Address, isTokenNative: isToken0Native };
      const token2Details: TOKEN_DETAILS_SINGLE | undefined = { tokenAddress: token1Address, isTokenNative: isToken1Native };
      const walletAddress = primaryWallet?.address;

      const [token1Balance, token2Balance] = await Promise.all([
        timeoutPromise(fetchTokenBalance({ walletAddress, tokenDetails: token1Details }), 10000),
        timeoutPromise(fetchTokenBalance({ walletAddress, tokenDetails: token2Details }), 10000)
      ]);

      settokenBalance({
        token1Balance: token1Balance?.tokenBalance || 0,
        token2Balance: token2Balance?.tokenBalance || 0,
        token1BalanceConverted: token1Balance?.tokenBalanceConverted,
        token2BalanceConverted: token2Balance?.tokenBalanceConverted,
      });
      setFetchingBalance(false);
    }
  }, [primaryWallet, tokenDetails, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { tokenBalance, fetchData, fetchingBalance };
};

export const fetchTokenBalance = async ({
  walletAddress,
  tokenDetails,
}: {
  walletAddress: any,
  tokenDetails: TOKEN_DETAILS_SINGLE,
}) => {

  if (walletAddress) {
    const {
      isTokenNative,
      tokenAddress,
    } = tokenDetails;

    let tokenBalance: any;

    try {
      if (isTokenNative) {
        tokenBalance = await LibfiServiceCommom.getNativeTokenBalance(walletAddress)
      } else {
        tokenBalance = await LibfiServiceCommom.getBalance(walletAddress, tokenAddress, 'dynamic');
      }
      return {
        tokenBalance: tokenBalance?.calculatedBalance || 0,
        tokenBalanceConverted: tokenBalance?.bigIntBalance
      };
    } catch (error) {

    }
  }
};

export const fetchPoolReservesBalance = async (pairAddress, token0Address, token1Address) => {
  if (pairAddress.toLowerCase() === ZERO_ADDRESS?.toLowerCase()) {
      return { _reserve0: 0, _reserve1: 0 };
  }

  try {
      const [
          reserves,
          tokens,
          decimals0,
          decimals1
      ] = await Promise.all([
          timeoutPromise(LibfiServiceAAM.getReserves(pairAddress), 10000),
          timeoutPromise(LibfiServiceAAM.getTokensThroughPairAddress(pairAddress), 10000),
          timeoutPromise(LibfiServiceCommom.getDecimals(token0Address, 'dynamic'), 10000),
          timeoutPromise(LibfiServiceCommom.getDecimals(token1Address, 'dynamic'), 10000)
      ]);

      const reserve0 = reserves[0] / BigInt(10) ** BigInt(decimals0);
      const reserve1 = reserves[1] / BigInt(10) ** BigInt(decimals1);

      if (token0Address?.toLowerCase() == tokens.token0.toString().toLowerCase()) {
          return { _reserve0: reserve0, _reserve1: reserve1 };
      } else {
          return { _reserve0: reserve1, _reserve1: reserve0 };
      }

  } catch (error) {
      return { _reserve0: 0, _reserve1: 0 };
  }
};
