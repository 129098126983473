import React, { useEffect } from "react";

import { DynamicEmbeddedWidget, useDynamicContext} from '@dynamic-labs/sdk-react-core';

import { GET_USER } from "../../../../Redux/Actions/apiResponseInterfaces";
import {
  setUserOnboardingData,
  userDetails,
  setUserPersonalisedLock,
} from "../../../../Redux/Slices/user.slice";

import { Modal } from "react-bootstrap";
import "./GetWallet.scss";
import ButtonCustom from "../../Button/ButtonCustom";
import {
  ModalClose,
  RightArrow,
} from "../../../../Assets/Images/Icons/SvgIcons";
import { Dispatch } from "redux";
import metamaskIcon from "../../../../Assets/Images/Icons/wallets/metamask.svg";
import walletConnect from "../../../../Assets/Images/Icons/wallets/wallet-connect.svg";
import { connectmetamask, handleUserProgress } from "../../../../Redux/Actions/user.action";
import {
  walletAddress,
  walletType,
} from "../../../../Redux/Slices/user.slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CHAIN_ID, WEBSITE_URL } from "../../../../Utils";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';


const GetWallet = (props: {
  show: boolean | undefined;
  handleClose: () => void;
}) => {
  const { show, handleClose } = props;
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  {/*
  const { NetworkChainId, walletType: walletTypeState } = useSelector(
    (state: any) => state?.user
  );
  */}

  const walletInfo = [
    {
      title: `${t('walletConnectHeader1')}`,
      content: `${t('walletConnectSection1')}`
    },
    {
      title: `${t('walletConnectHeader2')}`,
      content: `${t('walletConnectSection2')}`
    },
  ];

  {/*
  const connectToWallet = async (walletType: string) => {
    try {
      if (walletType === 'Metamask') {
        const walletAddress = await dispatch(connectmetamask());
        if (walletAddress && typeof walletAddress === 'string') {
          handleClose();
          navigate('/add-profile'); 
        }
      } else if (walletType === "Metamask") {
        const metamaskAppDeepLink = `https://metamask.app.link/dapp/${WEBSITE_URL}`;
        window.open(metamaskAppDeepLink, "_self");
        dispatch(connectmetamask());
      }
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    }
  };
  */}

  const { user, isAuthenticated } = useDynamicContext();

  useEffect(() => {
    if (walletAddress.length != 0) {
      handleClose();
      verifyUserProgress(walletAddress);
    }
  }, [walletAddress]);


  const verifyUserProgress = async (userwalletAddress) => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      walletAddress: userwalletAddress,
      from: "MainL",
    });
    if (result) {
      dispatch(userDetails(result?.data));
    }
    if (result?.walletStatus == "inactive") {
      dispatch(setUserOnboardingData({ progress: 50, path: "/onboarding/add-profile" }));
      navigate("/onboarding/add-profile");
    } else {
      if (
        result?.walletStatus == "active" &&
        result?.data?.walletAddress &&
        !result?.data?.value
      ) {
        dispatch(setUserOnboardingData({ progress: 50, path: "/onboarding/add-profile" }));
        navigate("/onboarding/add-profile");
      } else if (result != undefined) {
        dispatch(
          setUserOnboardingData({
            progress: result?.data?.value,
            path: result?.data?.label,
          })
        );
        navigate(`/${result?.data?.label}`);
      }
    }
  };



  useEffect(() => {
    if (isAuthenticated && !walletAddress) {
      handleClose();
      verifyUserProgressEmbeddedWallet();
    }
  }, [isAuthenticated]);

  const verifyUserProgressEmbeddedWallet = async () => {
    const email = user?.email;
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      email: email,
      from: "AuthL",
    });
    if (result?.walletStatus == "inactive" || result?.data?.label == null) {
      dispatch(setUserOnboardingData({ progress: 0, path: "/onboarding" }));
      navigate("/onboarding");
    } else if (result?.walletStatus == "active") {
      dispatch(userDetails(result.data));
      dispatch(setUserPersonalisedLock(result?.data?.lock));
      if (result?.data?.value != 100) {
        dispatch(setUserOnboardingData({ progress: result?.data?.value, path: result?.data?.label, }));
        navigate(`${result?.data?.label}`);
      }
    }
  };


  const {locale} = useDynamicContext();

  useEffect(() => {
    handleDynamicTranslation(i18next.languages[0]);
  }, [ i18next.languages[0] ]);


  const handleDynamicTranslation = (lang) => {
     locale.changeLanguage(lang);
   };


  return (
    <Modal className="GetWallet" show={show} onHide={handleClose} centered>
      <button className="modal_close_btn" onClick={handleClose}>
        <ModalClose />
      </button>
      <Modal.Body>
        <div className="GetWalletInner">
          <div className="GetWalletDetails">
            <h3>{t('walletConnectTitle')}</h3>
            <ul>
              {walletInfo?.map((item) => (
                <li key={item?.title}>
                  <h4>{item?.title}</h4>
                  <p>{item?.content}</p>
                </li>
              ))}
            </ul>
            <ButtonCustom
              className="bordered-blue learnBtn"
              fluid
              title={t('learnMore')}
              onlyIcon={<RightArrow />}
            />
          </div>
          <div className="GetWalletOptions">
            <h2> { t('connectWallet') } </h2>
            <p> { t('connectWalletDesc') } </p>

            <ul>
                <li>

                    <div className="SetupPage-dynamic-widget-container">
                      <DynamicEmbeddedWidget background="default"/> {/* background can be none, default or with-border */}
                    </div>
                    
                </li>
            </ul>
                
            {/*
            <h2>
              {NetworkChainId != CHAIN_ID && walletTypeState == "walletConnect"
                ? t('checkYourNetwork')
                : t('connectWallet')}
            </h2>
            <p>
              {NetworkChainId != CHAIN_ID && walletTypeState == "walletConnect"
                ? t('liFiOnlySupport')
                : t('connectWalletDesc')}
            </p>
            {NetworkChainId != CHAIN_ID && walletTypeState == "walletConnect" ? (
              <ul>
                <li>
                  (
                  {/* <ButtonCustom
                    title="Switch network in Wallet"
                    // btnIcon={metamaskIcon}
                    className="walletBtn bordered-blue"
                    onClick={() => walletConnectSwitchAndAddNetwork(provider)}
                  />
                  )
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  (
                  <ButtonCustom
                    title={ t('metamask')}
                    btnIcon={metamaskIcon}
                    className="walletBtn bordered-blue"
                    onClick={() => connectToWallet("Metamask")}
                  />
                  )
                </li>
                {/*
                  <li>
                    <ButtonCustom
                      title={ t('walletConnect')}
                      className="walletBtn bordered-blue"
                      btnIcon={walletConnect}
                      onClick={() => connectToWallet("walletConnect")}
                    />
                  </li>
                */}
              {/* </ul>
            )} */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GetWallet;
