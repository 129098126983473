import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import SubHeader from "../../../Common/SubHeader/SubHeader";
import "./CryptoBuySellPage.scss";

// The parent component will display the tabs of the Crypto Buy/Sell Sidebar Menu.
const CryptoBuySellPage = () => {
  const { t } = useTranslation();
  const {primaryWallet} = useDynamicContext();
  const walletAddress : any = primaryWallet?.address;
  const navigationLinks = [
    {
      id: 1,
      to: "/on-off-ramp/trade",
      label: `${t('onOffRamp')}`,
      defaultPath: "/on-off-ramp/trade/buy"
    },
    {
      id: 2,
      to: "/on-off-ramp/order-history",
      label: `${t('onOffRampOrderHistory')}`,
      // disabled: !walletAddress  //disable tab when there is no wallet connected TODO: check
    },
  ];
  //Remove orders button if user is not connected
  if(!primaryWallet?.connected){  
    navigationLinks.pop();
  }
  return (
    <section className="CryptoBuySellPage">
      <SubHeader backBtn heading={t('leftNavCryptoBuySell')} navigationLinks={navigationLinks} />
      <Outlet />
    </section>
  );
};

export default CryptoBuySellPage;
