import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { Col, Row } from "react-bootstrap";
import MyPortfolio from "../../../Common/Cards/MyPortfolio/MyPortfolio";
import QuickLinks from "../../../Common/Cards/QuickLinks/QuickLinks";
import NewsCard from "../../../Common/Cards/NewsCard/NewsCard";
import WhatHot from "../../../Common/Cards/WhatHot/WhatHot";
import TopPools from "../../../Common/Cards/TopPools/TopPools";
import { useDispatch, useSelector } from "react-redux";
import { callApiPostMethod, callAdminApiGetMethod } from "../../../../Redux/Actions/api.action";
import { getNativeBalance, getTokenBalanceForNewUser } from "../../../../Services/contractCallService";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";
import { setGlobalPoolData } from "../../../../Redux/Slices/user.slice";
import { DATA_INSERTED, WHATS_HOT, TOP_LIQUIDITY_POOL } from "../../../../Redux/Actions/apiResponseInterfaces";
import { getPoolData } from "../../../../LibfiServices/Hooks/useFetchPoolData";
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import "./MyLibfiPage.scss";



const MyLibfiPage = () => {

  interface Banner {
    heading: string;
    subheading: string;
    image: string;
    buttontext: string;
    buttonlink: string;
  }

  const dispatch: any = useDispatch();
  const globalPoolData = useSelector((state: any) => state?.user?.poolData);
  const [hotPools, setHotPools] = useState<WHATS_HOT[]>([]);
  const [topLiquidityPools, setTopLiquidityPools] = useState<TOP_LIQUIDITY_POOL[]>([]);
  const [banners, setBanners] = useState<Banner[]>([]);
  const { primaryWallet } = useDynamicContext();
  const walletAddress = primaryWallet?.address;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: document.body.dir === "rtl"
  };

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("navigationhandler"));
  });

  useEffect(() => {
    if (primaryWallet?.connected) updateTokenBalanceForNewUser();
    if (!globalPoolData?.length) getPoolsInfo();
  }, [primaryWallet]);

  useEffect(() => {
    getHotPools();
    getTopLiquidityPools();
  }, []);

  useEffect(() => {
    
    const fetchBanners = async () => {
      try {
        const result: Banner[] = await dispatch(
          callAdminApiGetMethod(
            'GET_HOME_BANNERS',
            [],
            false,
            false
          )
        );
        setBanners(result);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();

  }, []);

  /**
  * This Function get the Tokens Balance of the User Wallet
  */
  const updateTokenBalanceForNewUser = async () => {

    if (walletAddress) {

      let result: any;

      const tokensBalance = await Promise.all(
        tokenCollection.map(async (item: any) => {
          if (item?.isNative) {
            let nativeBalance: any = await getNativeBalance(walletAddress);
            result = nativeBalance?.res;
          } else {
            result = await getTokenBalanceForNewUser({
              tokenAddress: item?.address,
              dispatch,
              walletAddress: walletAddress,
            });
          }
          return {
            name: item?.symbol,
            token: item?.address?.toLowerCase(),
            balance: result,
          };
        })
      );

      const apiResult: DATA_INSERTED = await dispatch(
        callApiPostMethod(
          "USER_ONBOARDING",
          { walletAddress: walletAddress, balance: tokensBalance },
          false,
          false
        )
      );
      if (apiResult?.status == 200) {
      }

    }
  };

  const getPoolsInfo = async () => {
    if (walletAddress) {
      try {
        const poolData = await getPoolData({ dispatch, walletAddress });
        dispatch(setGlobalPoolData(poolData));
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    }
  };

  const getHotPools = async () => {
    try {
      const result: WHATS_HOT[] = await dispatch(
        callAdminApiGetMethod("HOT_POOLS", {}, false, false)
      );
      setHotPools(result);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  const getTopLiquidityPools = async () => {
    try {
      const result: TOP_LIQUIDITY_POOL[] = await dispatch(
        callAdminApiGetMethod("TOP_LIQUIDITY_POOLS", {}, false, false)
      );
      setTopLiquidityPools(result);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  return (
    <div className="LibfiPage">

      <div className="LibfiPageBanner">
        { banners.length > 0 && (
          <Slider className="BannerSlider" {...settings}>
            {banners.map((banner, index) => (
              <div className="BannerSlide" key={index}>
                  <img src={banner.image} alt={banner.heading} className="banner-image"/>
                  <h2>{banner.heading}</h2>
                  <h3>{banner.subheading}</h3>
                  <ButtonCustom title={banner.buttontext} navigate={banner.buttonlink} />
              </div>
            ))}
          </Slider>
        )}
      </div>

      <Row>
        <Col lg={4} sm={6}>
          <MyPortfolio />
        </Col>
        <Col lg={4} sm={6}>
          <QuickLinks />
        </Col>
        <Col lg={4} xs={12}>
          <NewsCard />
        </Col>
        <Col xs={12}>
          <WhatHot pools={hotPools} newSlider={true} />
        </Col>
        <Col xs={12}>
          <TopPools pools={topLiquidityPools}/>
        </Col>
      </Row>

    </div>
  );
};

export default MyLibfiPage;
