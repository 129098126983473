import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import sampleImg from "../../../../Assets/Images/news-sample2.png";
import { RightArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import CustomTable from "../../../Common/Table/Table";
import { DownArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import LibertyFinanceIcon from "../../../../Assets/Images/Icons/tokens/libertyfinance-white.png";
import ArbitrumIcon from "../../../../Assets/Images/Icons/tokens/arb.png";
import ethIcon from "../../../../Assets/Images/Icons/tokens/Eth.svg";
import usdtIcon from "../../../../Assets/Images/Icons/tokens/usdticon.svg";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { getPoolData } from "../../../../LibfiServices/Hooks/useFetchPoolData";
import { getAmanaVaultsData } from "../../../../LibfiServices/Hooks/useFetchAmanaVaultsData";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { setGlobalPoolData, setGlobalAmanaData, setUserSubscribed } from "../../../../Redux/Slices/user.slice";
import { AMANA_VAULTS, POOLS } from "../../../../Redux/Actions/apiResponseInterfaces";
import "./EarnOverview.scss";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";


const EarnOverview = () => {
  const { t } = useTranslation();
  const [poolLoader, setPoolLoader] = useState<boolean>(false);
  const [amanaLoader, setAmanaLoader] = useState<boolean>(false);
  const [poolsData, setPoolsData] = useState<POOLS["data"]>([]);
  const [amanaVaultsData, setAmanaVaultsData] = useState<AMANA_VAULTS["data"]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [notLoaded, setNotLoaded] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const { primaryWallet } = useDynamicContext();

  const [active, setActive] = useState<number | null>(null);
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  
  /**
  * This useEffect hook initializes the Liquidity Pools panels/table
  **/
  useEffect(() => {
      getAAMPools();
      getAmanaVaults();
  }, []);

  /**
  * This function fetches all the information for the available pools (tokens, TVL, ...)
  */
  const getAAMPools = async () => {
    try {
      setPoolLoader(true);
      const walletAddress = primaryWallet?.address || "";

      const poolsData = await getPoolData({
        dispatch,
        walletAddress
      });

      dispatch(setGlobalPoolData(poolsData));   

      if (!poolsData || poolsData.length === 0) { 
        setNotLoaded(true);
      } else {
        setPoolsData(poolsData);
      }

    } catch (error) {
      setNotLoaded(true);
    }
    setPoolLoader(false);
  }

  /**
  * This function fetches all the information for the available Amana Vaults (tokens, TVL, ...)
  */
  const getAmanaVaults = async () => {
    try {
      setAmanaLoader(true);
      const walletAddress = primaryWallet?.address || "";

      const amanaVaultsData = await getAmanaVaultsData({
        dispatch,
        walletAddress,
      });

      dispatch(setGlobalAmanaData(amanaVaultsData));   

      if (!amanaVaultsData || amanaVaultsData.length === 0) { 
        setNotLoaded(true);
      } else {
        setAmanaVaultsData(amanaVaultsData);
      }

    } catch (error) {
      setNotLoaded(true);
    }
    setAmanaLoader(false);
  }
  
  /**
  * This useEffect hook allows refreshing the Liquidity Pools panels/table
  * every 30s if no action has been performed by the user
  **/
  useEffect(() => {
    const interval = setInterval(() => {
      const runRefreshFunctions = async () => {
        if (primaryWallet?.connected) {
          setIsRefreshing(true); 
          await Promise.all([getAAMPools(), getAmanaVaults()]);
          setIsRefreshing(false); 
        }
      };
      runRefreshFunctions();
    }, 60000);
    return () => clearInterval(interval);
  }, [primaryWallet?.connected]);

  /**
  * dynamicData contains a structured object with related pool and vault details
  **/
  const dynamicData = tokenCollection.map((token, index) => {
    // Find pools where token is either token0 or token1
    const relevantPools = poolsData.filter(pool =>
      pool.token0Name === token.symbol || pool.token1Name === token.symbol
    );
  
    // Find vaults where token is the underlying asset
    const relevantVaults = amanaVaultsData.filter(vault =>
      vault.underlyingAssetName === token.symbol
    );
  
    // Calculate aprStart and aprEnds from relevant pools
    const aprValues = relevantPools.map(pool => pool.apr || 0);
    const aprStart = aprValues.length > 0 ? Math.min(...aprValues).toFixed(2) : "";
    const aprEnds = aprValues.length > 0 ? Math.max(...aprValues).toFixed(2) : "";

    // Initialize an empty array for otherTokens
    let otherTokens: any[] = [];

    // If there are relevant pools, add the AMM Pools object
    if (relevantPools.length > 0) {
      otherTokens.push({
        id: 1,
        tokenName: `${t('earnAMMPool')}`,
        tokenIcon: token.img,
        aprStart,
        aprEnds,
        duration: t('earnOverviewMixed'),
      });
    }

    // If there are relevant vaults, add the Amana Vault object
    if (relevantVaults.length > 0) {
      otherTokens.push({
        id: 2,
        tokenName: `${t('amanaVaults')}`,
        tokenIcon: token.img,
        aprStart: "",
        aprEnds: "",
        duration: "",
      });
    }

    // Create the token object with pools and vaults, including otherTokens only if not empty
    return {
      id: index + 1,
      tokenName: token.name,
      tokenSubname: token.symbol,
      tokenIcon: token.img,
      aprStart,
      aprEnds,
      duration: t('earnOverviewMixed'),
      ...(otherTokens.length > 0 ? { otherTokens } : {})
    };
  });

  // Flatten the resulting array since map on pools will create nested arrays
  const flatData = dynamicData.flat();

  /**
  * This useEffect hook updates the tableData state 
  * with the flatData array whenever flatData changes
  **/
  useEffect(() => {
    setTableData(flatData);
  }, [flatData]);

  /**
  * This function redirects the user to either 
  * "AMM Pools" or "Amana Vaults" based on their selection
  **/
  const handleSubscribe = async (assetName: any, poolOrStaking: any) => {
    if (poolOrStaking?.tokenName == `${t('earnAMMPool')}`) {
      navigate("/amm-pools", {
        state: {
          assetName: assetName?.tokenName,
          poolsData: !poolLoader ? poolsData : undefined
        },
      });
      dispatch(setUserSubscribed(true));
    } else {
      navigate("/amana-vaults", {
        state: {
          assetName: assetName?.tokenName,
          amanaVaultsData: !amanaLoader ? amanaVaultsData : undefined
        },
      });
      dispatch(setUserSubscribed(true));
    }
  };

  // Check The Table and Add The Vertical Line Accordingly
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  return (
    <div className="EarnOverview">
      <div className="EarnOverviewHead">
        <Row>
          <Col md={6}>
            <div
              onClick={() => {
                navigate("/amm-pools", {
                  state: {
                    assetName: "",
                    poolsData: !poolLoader ? poolsData : undefined
                  },
                });
              }}
              className="EarnOverviewBox"
            >
              <img src={sampleImg} alt="" />
              <div className="me-3">
                <h4>{t('liquidityPools')}</h4>
                <p>{t('liquidityPoolsSubHeading')}</p>
              </div>
              <span className="rightArrow">
                <RightArrow />
              </span>
            </div>
          </Col>
          <Col md={6}>
            <div
              onClick={() => {
                navigate("/amana-vaults", {
                  state: {
                    assetName: "",
                    amanaVaultsData: !amanaLoader ? amanaVaultsData : undefined
                  },
                });
              }}
              className="EarnOverviewBox"
            >
              <img src={sampleImg} alt="" />
              <div className="me-3">
                <h4>{t('amanaVaults')}</h4>
                <p>{t('amanaVaultsSubHeading')}</p>
              </div>
              <span className="rightArrow">
                <RightArrow />
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <h3 className="totalTokensTitle">{t('earnTotalInvestment')}</h3>
        <div className="TotalInvestmentTokens">
          <CustomTable 
            className={`CustomTable ${tableData?.length ? 'CustomTotalInvestmentTable' : ''}`}
            fields={[
              {
                key: "token",
                label: t('earnTotalToken')
              },
              {
                key: "apr",
                label: t('earnTotalAPR')
              },
              {
                key: "duration",
                label: t('earnTotalDuration')
              },
              {
                key: "",
                label: ""
              }]} scrollingBar={true} tableRef={tableRef} >
            {tableData?.map((item) => (
              <React.Fragment key={item?.id}>
                <tr>
                  <td>
                    <span>
                      <img className="tokenIcon" src={item?.tokenIcon} alt="" />
                      <h5>
                        {item?.tokenName}
                        {" "}{item?.tokenSubname && <span>{item?.tokenSubname}</span>}
                      </h5>
                    </span>
                  </td>
                  <td>
                    {poolLoader ? (
                      <span className="loading-animation" />
                    ) : (
                      <>
                        {item?.aprStart ? (
                          <span>
                            <span>{item.aprStart}</span>
                            <span>%</span>
                          </span>
                        ) : (
                          <span></span>
                        )}
                        {item?.aprEnds && (item?.aprEnds > item?.aprStart) ? (
                          <>
                            <span className="m-1">-</span>
                            <span>
                              <span>{item.aprEnds}</span>
                              <span>%</span>
                            </span>
                          </>
                        ) : (
                          <span></span>
                        )}
                      </>
                    )}
                  </td>
                  <td>{item?.duration}</td>
                  <td
                    onClick={() =>
                      item?.id === active && !poolLoader && !amanaLoader
                        ? setActive(null)
                        : setActive(item?.id)
                    }
                  >
                    {t('earnTotalSelect')}{" "}
                    <span
                      className={`downArrow ${
                        item?.id === active && !poolLoader && !amanaLoader ? "active" : ""
                      }`}
                    >
                      <DownArrow />
                    </span>
                  </td>
                </tr>
                {item?.id === active && (
                  <>
                    {item?.otherTokens && item?.otherTokens.length > 0 ? (
                      item?.otherTokens.map((otherItems) => (
                        <tr className="OtherTokensRow" key={otherItems?.id}>
                          <td>
                            <img
                              className="tokenIcon"
                              src={otherItems?.tokenIcon}
                              alt=""
                            />
                            <h5>{otherItems?.tokenName}</h5>
                          </td>
                          <td>
                            {poolLoader ? (
                              <span className="loading-animation" />
                            ) : (
                              <>
                                {otherItems?.aprStart ? (
                                  <span>
                                    <span>{otherItems?.aprStart}</span>
                                    <span>%</span>
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                                {otherItems?.aprEnds && otherItems?.aprEnds > otherItems?.aprStart ? (
                                  <>
                                    <span className="m-1">-</span>
                                    <span>
                                      <span>{otherItems?.aprEnds}</span>
                                      <span>%</span>
                                    </span>
                                  </>
                                ) : (
                                  <span></span>
                                )}
                              </>
                            )}
                          </td>
                          <td>{otherItems?.duration}</td>
                          <td>
                            <ButtonCustom
                              className="bordered-blue"
                              title={t("earnOverviewEarnButton")}
                              onClick={() => {
                                handleSubscribe(item, otherItems);
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center TokensRow-Custom-Padding"> {/* Use camelCase colSpan */}
                          <h4>{t('earnOverviewTableNoRecord')}</h4>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </CustomTable>
        </div>
        {/* <p className="loadMoreText">Load more</p> */}
      </div>
    </div>
  );
};

export default EarnOverview;
