import React, { useEffect, useState } from "react";
import CustomTable from "../../Table/Table";
import CommonCard from "../CommonCard/CommonCard";
import ButtonCustom from "../../Button/ButtonCustom";
import "./MyAssets.scss";
import {
  DownRedArrow,
  TrandingIcon,
} from "../../../../Assets/Images/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { cryptoDecimals, formatNumberWithMagnitudeSuffix, toCustomRoundedFixed } from "../../../../Services/common.service";
import { tokenCollection } from "../../../../Services/dynamicContractDetails";
import { settokenOne } from "../../../../Redux/Slices/tokenSlice";
import { settokenTwo } from "../../../../Redux/Slices/tokenSlice";
import { useNavigate } from "react-router-dom";
import { MY_ASSETS, TOKENS } from "../../../../interfaces/commonInterfaces";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import ShariahIcon from '../../../../Assets/Images/Icons/shariah.png'
import Tooltip from "../../Tooltip/Tooltip";

type MyAssetProps = {
  page: String;
  assets: MY_ASSETS[];
  loader: boolean,
  isRefreshing: boolean
};
const MyAssets = ({ page, assets, loader, isRefreshing }: MyAssetProps) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [assetData, setAssetData] = useState<MY_ASSETS[]>(assets);

  // Check The Table and Add The Vertical Line Accordingly
  const [isLastColumnVisible, setIsLastColumnVisible] = useState(true);

  const [sorting, setSorting] = useState<{ field: string; upOrDown: string }>({
    field: "value",
    upOrDown: "up",
  });

  const tableRef = React.useCallback(node => {
    if (node !== null) {
      const lastColumn = node.querySelector('th:last-child');
      const handleResize = () => {
        if (lastColumn) {
          const rect = lastColumn.getBoundingClientRect();
          setIsLastColumnVisible(rect.right <= window.innerWidth);
          //console.log("Last Column Width:", rect.width, "Window Width:", window.innerWidth, "Is Visible:", rect.right <= window.innerWidth);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!isLastColumnVisible) {
      document.documentElement.style.setProperty('--VerticalLine', 'linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgb(18, 18, 18) 100%)');
    } else {
      document.documentElement.style.setProperty('--VerticalLine', 'none');
    }
  }, [isLastColumnVisible]);

  const sortData = (array: any) => {
    const { field, upOrDown } = sorting;
    const order = upOrDown === "up" ? "asc" : "desc";
    return field === "assetName" ?
      _.orderBy(array, (item) => [_.get(item, 'token0Name'), _.get(item, 'token1Name', '')], [order, order]) :
      _.orderBy(array, [field], [order]);
  }

  useEffect(() => { 
    if (assets && assets.length > 0) { 
      setAssetData(sortData(assets));
    }
  }, [sorting, assets])

  /**
   * function to navigate to trading desk whenever user clicks on trade button on myPortfolio page
   * @param tokenData object containing information about the selected asset by the user
   */
  const swapNavigate = async (tokenData: any) => {
    const { isLPToken, token0Name } = tokenData;
    if (isLPToken) {
      navigate("/amm-pools", {
        state: {
          assetName: token0Name,
        },
      });
    } else {
      const tokenAInfo = tokenCollection.find((item) => {
        return item?.symbol == token0Name;
      });
      const tokenBInfo: TOKENS | undefined = tokenCollection.find(
        (info) => info?.symbol !== token0Name
      );
      dispatch(settokenOne(tokenAInfo));
      dispatch(settokenTwo(tokenBInfo));
      const x =
        page == "portfolio"
          ? navigate(
            `/trade/simple/market/${token0Name}/${tokenBInfo?.symbol}`,
            { state: { pageName: "MyAssets" } }
          )
          : null;
    }
  };

  /**
   * helper function to fetch which field user wants to sort
   * @param data
   * @param sortType
   */
  const updateSortedData = (data: any, sortType: string) => {
    setSorting({ field: data, upOrDown: sortType });
  };

  return (
    <CommonCard
      noHeaderSpacing
      cardTitle={page == "portfolio" ? t('portfolioMyAssets') : t('portfolioMyPortfolio')}
      className="MyAssets"
      viewAllNavigate="/portfolio"
    >
      <CustomTable
        sortIcons
        scrollingBar={true}
        fixedHeader={true}
        fields={[
          {
            key: "assetName",
            label: t('portfolioMyAssetsAsset')
          },
          {
            key: "price",
            label: t('portfolioMyAssetsPrice')
          },
          {
            key: "balance",
            label: t('portfolioMyAssetsBalance')
          },
          {
            key: "value",
            label: t('portfolioMyAssetsValue')
          },
          {
            key: "change",
            label: t('portfolioMyAssetsChange')
          },
          {
            key: "",
            label: ""
          }
        ]}
        loader={assetData?.length !== 0 ? false : loader}
        updateSortedData={updateSortedData}
        tableRef={tableRef}
      >
        {assetData?.length > 0 &&
          assetData?.map((item: MY_ASSETS) => (
            <tr>
              <td className={isRefreshing ? "text-shadow" : ""}>
                <span className="assetIcons">
                  <img
                    className="assetIconOne"
                    src={item.img0}
                    alt=""
                  />
                  {
                    item?.img1 && <img
                      className="assetIconTwo"
                      src={item.img1}
                      alt=""
                    />
                  }
                </span>

                {
                  item.isLPToken ?
                    <span>
                      <span>{item.token0Name}</span>
                      <span>/</span>
                      <span>{item.token1Name}</span>
                    </span>
                    :
                    <span>
                      <span>{item.token0Name}</span>
                      {
                        item.isShariahCompliant &&
                        <sup>
                          <Tooltip icon={<img className="my-asset-table-shariah-image" src={ShariahIcon} />}>
                            <p>{t('portfolioMyAssetsApprovedBySharishBoard')}</p>
                          </Tooltip>
                        </sup>
                      }
                    </span>
                }
              </td>
              <td className={isRefreshing ? "text-shadow" : ""}>
                <span>$</span>
                <span>{formatNumberWithMagnitudeSuffix(item.price)}</span>
              </td>
              <td className={isRefreshing ? "text-shadow" : ""}>{formatNumberWithMagnitudeSuffix(item.balance)}</td>
              <td className={`value_box ${isRefreshing ? 'text-shadow' : ''}`}>
                <h4>
                  <span>$</span>
                  <span>{formatNumberWithMagnitudeSuffix(item.value)}</span>
                </h4>
              </td>

              <td className={isRefreshing ? "text-shadow" : ""}>
                <span>
                  {item?.change > 0 && <TrandingIcon />}
                  {item?.change < 0 && <DownRedArrow />}
                </span>
                <span className="change mx-2">
                  <span>{toCustomRoundedFixed(item.change || 0, 2)}</span>
                  <span>%</span>
                </span>
              </td>
              <td>
                <ButtonCustom
                  className="tradeBtn bordered-blue"
                  title={t('tradeButton')}
                  onClick={() => swapNavigate(item)}
                />
              </td>
            </tr>
          ))}
      </CustomTable>
    </CommonCard>
  );

};

export default MyAssets;
