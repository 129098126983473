import React, { useState } from "react";
import "./TradeHistory.scss";
import { Col, Row } from "react-bootstrap";
import DetailsCard from "../../../Common/Cards/DetailsCard/DetailsCard";
import TradehistoryCard from "../../../Common/Cards/TradehistoryCard/TradehistoryCard";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { WalletIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import { useSelector } from "react-redux";
import CommonTiles from "../TilesCard/CommonTiles";
import { useTranslation } from "react-i18next";
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';


const TradeHistory = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const { primaryWallet } = useDynamicContext();

  return (
    <div className="TradeHistoryPage">
      {!primaryWallet?.connected ? (
        <div className="wallet_not_connected">
          <p>
            {t('welcomeLIBFI')}
          </p>
          <ButtonCustom
            title={
              <>
                <span className="me-1">
                  <WalletIcon />
                </span>{" "}
                {t('connectWallet')}
              </>
            }
            show={show}
            setShow={setShow}
            onClick={() => setShow(true)}
          />
        </div>
      ) : (
        <>
          <CommonTiles page={"tradeHistory"} />
          <Row>
            <Col xs={12}>
              <TradehistoryCard />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default TradeHistory;
