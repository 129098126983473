import { Hex, isAddress, TransactionReceipt } from "viem";
import { readContract, writeContract, simulateContract } from '@wagmi/core'
import { WagmiConfig} from "../WagmiConfig";
import { addBreadcrumb } from './../SentryContext';
import LibfiServiceCommon, { getContractDetails } from "./libfiService_Common";
import { MAX_APPROVAL } from "../Utils";


const amanaVaultDetails = getContractDetails("amanaVault");


const LibfiServiceAmanaVault = {

    getUnderlyingAssetAddress: async (amanaVaultAddress): Promise<string> => {
        try {

            const tokenAddress = await readContract(WagmiConfig, {
                address: amanaVaultAddress as Hex, 
                abi: amanaVaultDetails.abi,
                functionName: "asset",
                args: []
            });

            // Output Validation
            if (typeof tokenAddress !== 'string') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getAmanaVaultTotalAssets Result:', amanaVaultTotalAssets);
            return tokenAddress;

        } catch (error) {
            //console.error('Error getAmanaVaultTotalAssets:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getShareName: async (amanaVaultAddress): Promise<string> => {
        try {

            const tokenAddress = await readContract(WagmiConfig, {
                address: amanaVaultAddress as Hex, 
                abi: amanaVaultDetails.abi,
                functionName: "name",
                args: []
            });

            // Output Validation
            if (typeof tokenAddress !== 'string') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getAmanaVaultTotalAssets Result:', amanaVaultTotalAssets);
            return tokenAddress;

        } catch (error) {
            //console.error('Error getAmanaVaultTotalAssets:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getShareSymbol: async (amanaVaultAddress): Promise<string> => {
        try {

            const tokenAddress = await readContract(WagmiConfig, {
                address: amanaVaultAddress as Hex, 
                abi: amanaVaultDetails.abi,
                functionName: "symbol",
                args: []
            });

            // Output Validation
            if (typeof tokenAddress !== 'string') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getAmanaVaultTotalAssets Result:', amanaVaultTotalAssets);
            return tokenAddress;

        } catch (error) {
            //console.error('Error getAmanaVaultTotalAssets:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getTotalUnderlyingAssets: async (amanaVaultAddress): Promise<bigint> => {
        try {

            const amanaVaultTotalAssets = await readContract(WagmiConfig, {
                address: amanaVaultAddress as Hex, 
                abi: amanaVaultDetails.abi,
                functionName: "totalAssets",
                args: []
            });

            // Output Validation
            if (typeof amanaVaultTotalAssets !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            //console.log('getAmanaVaultTotalAssets Result:', amanaVaultTotalAssets);
            return amanaVaultTotalAssets;

        } catch (error) {
            //console.error('Error getAmanaVaultTotalAssets:', error instanceof Error ? error.message : 'Unknown Error Occurred');
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    convertSharesToUnderlyingAssets: async (amanaVaultAddress, amount): Promise<bigint>  => {
        try {
            // Input Validation
            addBreadcrumb('convertToAssets', '[convertToAssets] Input Data', 'info', { amanaVaultAddress: amanaVaultAddress, amount: amount });
            if (!isAddress(amanaVaultAddress)) {
                throw new Error("Address Validation Failed...");
            }
            if (BigInt(amount) < 0n) {
                throw new Error("Amount Validation Failed...");
            }
            addBreadcrumb('convertToAssets', '[convertToAssets] Input Validation Successful', 'info');

            addBreadcrumb('convertToAssets', '[convertToAssets] Input Data', 'info', 
                {
                    address: amanaVaultAddress as Hex,
                    //abi: amanaVaultDetails.abi,
                    functionName: "convertToAssets",
                    args: [
                        BigInt(amount),
                    ],
                }
            );

            const assetsNumber = await readContract(WagmiConfig, {
                address: amanaVaultAddress as Hex,
                abi: amanaVaultDetails.abi,
                functionName: "convertToAssets",
                args: [
                    BigInt(amount),
                ],
              });

            addBreadcrumb('convertToAssets', '[convertToAssets] Data Output', 'info', { Output: assetsNumber });

            // Output Validation
            if (typeof assetsNumber !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            return assetsNumber;

        } catch (error) {
            addBreadcrumb('convertToAssets', '[convertToAssets] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    convertUnderlyingAssetsToShares: async (amanaVaultAddress, amount): Promise<bigint>  => {
        try {
            // Input Validation
            addBreadcrumb('convertToShares', '[convertToShares] Input Data', 'info', { amanaVaultAddress: amanaVaultAddress, amount: amount });
            if (!isAddress(amanaVaultAddress)) {
                throw new Error("Address Validation Failed...");
            }
            if (BigInt(amount) < 0n) {
                throw new Error("Amount Validation Failed...");
            }
            addBreadcrumb('convertToShares', '[convertToShares] Input Validation Successful', 'info');

            addBreadcrumb('convertToShares', '[convertToShares] Input Data', 'info', 
                {
                    address: amanaVaultAddress as Hex,
                    //abi: amanaVaultDetails.abi,
                    functionName: "convertToShares",
                    args: [
                        BigInt(amount),
                    ],
                }
            );

            const assetsNumber = await readContract(WagmiConfig, {
                address: amanaVaultAddress as Hex,
                abi: amanaVaultDetails.abi,
                functionName: "convertToShares",
                args: [
                    BigInt(amount),
                ],
              });

            addBreadcrumb('convertToShares', '[convertToShares] Data Output', 'info', { Output: assetsNumber });

            // Output Validation
            if (typeof assetsNumber !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            return assetsNumber;

        } catch (error) {
            addBreadcrumb('convertToShares', '[convertToShares] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    }, 

    getAllowance: async (amanaVaultAddress, walletAddress, tokenAddress, type: string): Promise<bigint> => {
        try {
            // Input Validation
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Data', 'info', { amanaVaultAddress: amanaVaultAddress, walletAddress: walletAddress, tokenAddress: tokenAddress, type: type });
            if (!isAddress(amanaVaultAddress) || !isAddress(walletAddress) || !isAddress(tokenAddress) ) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Validation Successful', 'info');

            const contractDetails = getContractDetails(type);
            
            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }

            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Input Data', 'info', 
                {
                    address: tokenAddress as Hex, 
                    //abi: contractDetails.abi,
                    functionName: "allowance",
                    args: [
                        walletAddress as Hex,
                        amanaVaultAddress as Hex,
                    ]
                }
            );

            const tokenAllowance = await readContract(WagmiConfig, {
                address: tokenAddress as Hex, 
                abi: contractDetails.abi,
                functionName: "allowance",
                args: [
                    walletAddress as Hex,
                    amanaVaultAddress as Hex,
                ]
            });
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Data Output', 'info', { Output: tokenAllowance });

            // Output Validation
            if (typeof tokenAllowance !== 'bigint') {
                throw new Error("Unexpected Return From Contract");
            }

            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Converted Data Output', 'info', { tokenAllowance: tokenAllowance });

            return tokenAllowance;

        } catch (error) {
            addBreadcrumb('TokenAllowance', '[getTokenAllowance] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    getApproval: async (amanaVaultAddress, tokenAddress, type: string): Promise<TransactionReceipt> => {
        try {
            // Input Validation
            addBreadcrumb('TokenApproval', '[getTokenApproval] Input Data', 'info', { amanaVaultAddress: amanaVaultAddress, tokenAddress: tokenAddress, type: type });
            if (!isAddress(amanaVaultAddress) || !isAddress(tokenAddress)) {
                throw new Error("Address Validation Failed...");
            }
            if ( !type ) {
                throw new Error("Type Validation Failed...");
            }
            addBreadcrumb('TokenApproval', '[getTokenApproval] Input Validation Successful', 'info');
    
            const contractDetails = getContractDetails(type);
            
            if (!contractDetails) {
                throw new Error("Contract details could not be retrieved.");
            }
    
            addBreadcrumb('TokenApproval', '[getTokenApproval] simulateContract Input Data', 'info', {
                address: tokenAddress as Hex, 
                //abi: contractDetails.abi,
                functionName: "approve",
                args: [
                    amanaVaultAddress as Hex,
                    MAX_APPROVAL
                ],
            });
    
            const preparedTx = await simulateContract(WagmiConfig, {
                address: tokenAddress as Hex, 
                abi: contractDetails.abi,
                functionName: "approve",
                args: [
                    amanaVaultAddress as Hex,
                    MAX_APPROVAL
                ],
            });
    
            addBreadcrumb('TokenApproval', '[getTokenApproval] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });
    
            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('TokenApproval', '[getTokenApproval] Transaction Submitted', 'info', { Output: result });
            
            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
    
            return waitForTransactionReceipt;
    
        } catch (error) {
            addBreadcrumb('TokenApproval', '[getTokenApproval] Error Occurred', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    addAmanaVault: async (data): Promise<TransactionReceipt> => {
        const { amanaVaultAddress, amount, walletAddress } = data;

        try {
            // Input Validation
            addBreadcrumb('addAmanaVault', '[addAmanaVault] Input Data', 'info', { amanaVaultAddress: amanaVaultAddress, amount: amount, walletAddress: walletAddress });
            if (!isAddress(amanaVaultAddress)) {
                throw new Error("Address Validation Failed...");
            }
            if (BigInt(amount) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('addAmanaVault', '[addAmanaVault] Input Validation Successful', 'info');


            addBreadcrumb('addAmanaVault', '[addAmanaVault] simulateContract Input Data', 'info', {             
                address: amanaVaultAddress as Hex,
                //abi: amanaVaultDetails.abi,
                functionName: "deposit",
                args: [
                    BigInt(amount),
                    walletAddress
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: amanaVaultAddress as Hex,
                abi: amanaVaultDetails.abi,
                functionName: "deposit",
                args: [
                  BigInt(amount),
                  walletAddress as Hex,
                ],
            });

            addBreadcrumb('addAmanaVault', '[addAmanaVault] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('addAmanaVault', '[addAmanaVault] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('addAmanaVault', '[addAmanaVault] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

    redeemAmanaVault: async (data): Promise<TransactionReceipt> => {
        const { amanaVaultAddress, amount, walletAddress } = data;

        try {
            // Input Validation
            addBreadcrumb('redeemAmanaVault', '[redeemAmanaVault] Input Data', 'info', { amanaVaultAddress: amanaVaultAddress, amount: amount, walletAddress: walletAddress });
            if (!isAddress(amanaVaultAddress)) {
                throw new Error("Address Validation Failed...");
            }
            if (BigInt(amount) < 0n) {
                throw new Error("Amount Validation Failed...");
            }

            if (!isAddress(walletAddress)) {
                throw new Error("Address Validation Failed...");
            }
            addBreadcrumb('redeemAmanaVault', '[redeemAmanaVault] Input Validation Successful', 'info');


            addBreadcrumb('redeemAmanaVault', '[redeemAmanaVault] simulateContract Input Data', 'info', {             
                address: amanaVaultAddress as Hex,
                //abi: amanaVaultDetails.abi,
                functionName: "redeem",
                args: [
                  amount,
                  walletAddress,
                  walletAddress
                ],
            });

            const preparedTx = await simulateContract(WagmiConfig, {
                address: amanaVaultAddress as Hex,
                abi: amanaVaultDetails.abi,
                functionName: "redeem",
                args: [
                  amount,
                  walletAddress,
                  walletAddress
                ],
            });

            addBreadcrumb('redeemAmanaVault', '[redeemAmanaVault] simulateContract Output Data', 'info', { preparedTxOutput: preparedTx });

            // Execute transaction
            const result = await writeContract(WagmiConfig, preparedTx.request);
            addBreadcrumb('redeemAmanaVault', '[redeemAmanaVault] Transaction Submitted', 'info', { Output: result });

            // Wait For Transaction Execution
            const waitForTransactionReceipt = await LibfiServiceCommon.waitForTransactionReceipt(result);
            
            return waitForTransactionReceipt;

        } catch (error) {
            addBreadcrumb('redeemAmanaVault', '[redeemAmanaVault] Error Occured', 'info',  { errorMessage: error });
            throw new Error(error instanceof Error ? error.message : 'Unknown Error Occurred');
        }
    },

};

export default LibfiServiceAmanaVault;
