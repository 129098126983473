import React from "react";
import SubHeader from "../../../Common/SubHeader/SubHeader";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TradePage = () => {
  const { t } = useTranslation();
  const navigationLinks = [
    {
      id: 1,
      label: `${t('tradeNavSimple')}`,
      to: "/trade/simple/market",
      alterLocation: [
        "/trade/simple/limit",
        "/trade/simple/stop-orders",
      ],
    },
    {
      id: 2,
      label: `${t('tradeNavProfessional')}`,
      // to: "",
      to: "/trade/professional/market",
      alterLocation: [
        "/trade/professional/stop-orders",
        "/trade/professional/limit",
      ],
    }
  ];
  return (
    <>
      <SubHeader
        backBtn
        heading={ t('leftNavTrade')}
        navigationLinks={navigationLinks}
      />
      <Outlet />
    </>
  );
};

export default TradePage;
