import React, { useEffect, useState } from "react";
import "./MyPortfolio.scss";
import CommonCard from "../CommonCard/CommonCard";
// import graphImg from "../../../../Assets/Images/graph-images/my-portfolio-graph.png";
import { SettingsIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import DashboardGraph from "../../../Pages/graph/DashboardGraph";
import ComingSoon from "../../ComingSoon/ComingSoon";
import { Dispatch } from "redux";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import Shimmer from "../../Shimmer/Shimmer";
import {
  cryptoDecimals,
  fromWeiConvert,
} from "../../../../Services/common.service";
import { PORTFOLIO_GRAPH } from "../../../../Redux/Actions/apiResponseInterfaces";
import myPortfolio from "../../../../Assets/Images/graph-images/portfolio-graph.png";
import { useTranslation } from "react-i18next";
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';


const MyPortfolio = () => {

  // State variables

  const dispatch: any = useDispatch();
  const { primaryWallet } = useDynamicContext();
  const { t } = useTranslation();
  const [graphInfo, setGraphInfo] = useState<PORTFOLIO_GRAPH["data"] | undefined>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [graphValue, setGraphValue] = useState<{
    totalAssets: string;
    change: number;
    difference: string | number;
  }>({
    totalAssets: "",
    change: 0,
    difference: "",
  });
  const buttons = [
    {
      value: 1,
      label: "1H",
    },
    {
      value: 24,
      label: "1D",
    },
    {
      value: 168,
      label: "1W",
    },
    {
      value: 730,
      label: "1M",
    },
    {
      value: 8760,
      label: "1Y",
    },
    {
      value: "max",
      label: "Max",
    },
  ];


  // Hooks

  useEffect(() => {
    if (primaryWallet?.connected) getGraphData();
  }, [primaryWallet]);


  // Funtions

  /**
  * This function allows getting portfolio graph data of the total assets held by the user
  */
  const getGraphData = async () => {
    setLoader(true);

    const apiResult: PORTFOLIO_GRAPH | undefined = await dispatch(
      callApiPostMethod(
        "PORTFOLIO_GRAPH_DATA",
        { userAddress: primaryWallet?.address },
        false,
        false
      )
    );

    if (apiResult) {
      setGraphInfo(apiResult?.data);
      const data: any = apiResult?.data;

      let initialValue: string = fromWeiConvert(data[0]?.amount?.toString());
      let finalValue: string = fromWeiConvert(
        data[data?.length - 1]?.amount?.toString()
      );
      let differValue: number =
        Number(initialValue) > Number(finalValue)
          ? Number(initialValue) - Number(finalValue)
          : Number(finalValue) - Number(initialValue);
      setGraphValue({
        totalAssets: finalValue,
        change: Number(initialValue) ? differValue / Number(initialValue) : 0,
        difference: Number(finalValue) - Number(initialValue),
      });
      setLoader(false);
    }
  };

  return (
    <>
      <CommonCard
        contentBg
        contentClassName="MyPortfolioContent"
        className="MyPortfolio"
        cardTitle={t('portfolioMyPortfolio')}
      >
        
        <img src={myPortfolio} alt="graph" />
        <ComingSoon />

        {/*  
        {loader ? (
          <Shimmer height={272} fluid />
        ) : (
          <>
            <div className="MyPortfolioHeader">
              <div>
                <h3>${cryptoDecimals(graphValue?.totalAssets)}</h3>
                <p>
                  <span
                    className={graphValue?.change > 0 ? "positive" : "danger"}
                  >
                    {cryptoDecimals(graphValue?.change)}%
                  </span>{" "}
                  <span className="">
                    ${cryptoDecimals(graphValue?.difference)}
                  </span>
                </p>
              </div>
                  {/* <button className="SettingsBtn">
                        <SettingsIcon />
                      </button> */} 
            
            {/* 
            </div>
              {/* <img src={graphImg} className="graphImg" alt="graph" /> */}
            {/* <br />
            {/* <br />

            <DashboardGraph height={160} data={graphInfo} graphValue="1" />

            {/* <div className="MyPortfolioAction">
                  {buttons?.map((item) => (
                    <button
                        key={item?.value}
                        className={item?.value === active ? "active" : ""}
                        onClick={() => setActive(item?.value)}
                      >
                        {item?.label}
                    </button>
                  ))}
                </div> 
          </>
        )} 
        */}
      </CommonCard>
    </>
  );
};

export default MyPortfolio;
