import { ORDER } from "../../../../Redux/Actions/apiResponseInterfaces";
import { callContractGetMethod } from "../../../../Redux/Actions/contract.action";
import {
  createLimitOrderVoucher,
  createStopOrderVoucher,
} from "../../../../Redux/Actions/limitApi.action";
import { storeInstance } from "../../../../Services/axios.service";
import {
  localeStringFunction,
  makeid,
  slicedValue,
} from "../../../../Services/common.service";
import { callWeb3 } from "../../../../Services/contract.service";
import {
  approvalAndAllowanceHelperForLimitOrder,
  getPairService,
} from "../../../../Services/contractCallService";
import { getLimitOrderSignature } from "../../../../Services/getSignature";
import { CHAIN_ID } from "../../../../Utils";
import {
  CALLDATA,
  TOKEN_DETAILS,
} from "../../../../interfaces/commonInterfaces";
import { LIMIT_ORDER_SIG } from "../../../../interfaces/contractCallInterfaces";

/**
 * helper function to make calldata required to execute limit order
 * @param data object containing info required for making calldata
 * amountIn amount that user has entered in the field
   amountOutMin minimum amount of token two that user will receive ,
   tokenOneAddress token one address,
   tokenTwoAddress token two address,
   to wallet address of the user that triggers this limit order ,
   deadline epoch time indicating when the order will expire,
 * @returns calldata
 */

const makeLimitOrderCallData = async (data: CALLDATA) => {
  const {
    amountIn,
    amountOutMin,
    tokenOneAddress,
    tokenTwoAddress,
    to,
    deadline,
  } = data;
  const path: any = [tokenOneAddress, tokenTwoAddress];
  let _web3Instance = await callWeb3();
  const res: string = await _web3Instance?.eth?.abi?.encodeFunctionCall(
    {
      name: "swapExactTokensForTokens",
      type: "function",
      inputs: [
        { type: "uint256", name: "amountIn" },
        { type: "uint256", name: "amountOutMin" },
        { type: "address[]", name: "path" },
        { type: "address", name: "to" },
        { type: "uint256", name: "deadline" },
      ],
    },
    [amountIn, amountOutMin, path, to, deadline]
  );
  return res;
};

/**
 * helper function to make signature and place limit order
 * @param data object containing info required for making signature and placing limit order
 * tokenDetails object containing info about the tokens
 * inputOne amount that user has entered in the field 1 ,
 * inputTwo amount in the field 2 ,
 * userValidLimitPrice price that user has inputted,
 * userDropdownTime time after which limit order will expire,
 * dispatch,
 * setModalData,
 * orderType,
 * t translation strings function
 * @returns boolean value whether the order has been placed or not
 */

export const performLimitOrderOrStopOrderExecution = async (
  tokenDetails: TOKEN_DETAILS,
  inputOne: {
    convertedValue: string | number;
    inputValue: string | number;
  },
  inputTwo: {
    convertedValue: string | number;
    inputValue: string | number;
  },
  userValidLimitPrice: any,
  userDropdownTime: number,
  dispatch: any,
  setModalData: any,
  orderType: string,
  t: (key: string) => string,
) => {
  const order: string = orderType == "StopLoss" ? t('tradeLimitStopLoss') : t('tradeLimitOrder');
  const {
    walletAddress,
    slippage,
  }: { walletAddress: string; slippage: number } =
    storeInstance?.getState()?.user;

  let newslippage: number = 5;
  if (slippage < 5) {
    newslippage = 5;
  } else {
    newslippage = slippage;
  }
  const randomness: number = Number(makeid(10));
  const allowanceData: {
    tokenOneAddress: string;
    walletAddress: string;
    input: string | number;
    dispatch: any;
  } = {
    tokenOneAddress: tokenDetails?.token0Address,
    walletAddress,
    input: inputOne?.convertedValue,
    dispatch,
  };
  setModalData({
    heading: `${t('tradeLimitModalApprovalHeading')}`,
    bodyText: `${(t('tradeLimitModalApprovalConfirmText')).replace('{{tokenowner}}', tokenDetails?.token0Name || "")}`,
    status: "pending",
    txHash: null,
  });
  const response: boolean | 0 = await approvalAndAllowanceHelperForLimitOrder(
    allowanceData
  );
  if (response) {
    setModalData({
      heading: `${t('tradeLimitModalApprovalHeading')}`,
      bodyText: `${(t('tradeLimitModalApprovalSuccessText')).replace('{{tokenowner}}', tokenDetails?.token0Name || "")}`,
      status: "success",
      txHash: null,
    });
    setTimeout(() => {}, 2000);
    setModalData({
      heading: `${order}`,
      bodyText: `${(t('tradeLimitModalOrderSignText'))
        .replace('{{order}}', order)
        .replace('{{tokenowner1}}', tokenDetails?.token0Name || "")
        .replace('{{tokenowner2}}', tokenDetails?.token1Name || "")}`,
      status: "pending",
      txHash: null,
    });

    const calldataData: CALLDATA = {
      amountIn: (inputOne?.convertedValue).toString(),
      amountOutMin: slicedValue(
        localeStringFunction(
          Number(inputTwo?.convertedValue) -
            (Number(inputTwo?.convertedValue) * newslippage) / 100
        )
      ),
      // amountOutMin: "1000000000000000000",
      tokenOneAddress: tokenDetails?.token0Address,
      tokenTwoAddress: tokenDetails?.token1Address,
      to: walletAddress,
      deadline: userDropdownTime.toString(),
    };
    const calldataForLimitOrder: string = await makeLimitOrderCallData(
      calldataData
    );
    const signatureData: LIMIT_ORDER_SIG = {
      user: calldataData?.to,
      salt: randomness,
      assetin: calldataData?.tokenOneAddress,
      assetout: calldataData?.tokenTwoAddress,
      amountin: calldataData?.amountIn,
      amountout: calldataData?.amountOutMin,
      Price: Math.floor(userValidLimitPrice * 10 ** 18).toString(),
      // Price: userValidLimitPrice,
      call: calldataForLimitOrder,
    };

    const res: string | undefined = await getLimitOrderSignature(signatureData);
    if (res) {
      const data: ORDER = {
        pairAddress: await getPairService({
          tokenOneAddress: tokenDetails?.token0Address,
          tokenTwoAddress: tokenDetails?.token1Address,
          dispatch,
        }),
        assetIn: calldataData?.tokenOneAddress,
        assetOut: calldataData?.tokenTwoAddress,
        amountsIn: calldataData?.amountIn,
        amountsOut: calldataData?.amountOutMin,
        userAddress: calldataData?.to,
        rate: signatureData?.Price,
        salt: randomness,
        expiryTime: Number(calldataData?.deadline),
        callData: calldataForLimitOrder,
        signature: res,
        message: "hello",
        chainId: CHAIN_ID,
        dispatch,
      };
      const apiResult: boolean | undefined =
        orderType == "StopLoss"
          ? await createStopOrderVoucher(data)
          : await createLimitOrderVoucher(data);
      // const apiResult = await createLimitOrderVoucher(data);
      if (apiResult) {
        setModalData({
          heading: `${order}`,
          bodyText: `${(t('tradeLimitModalOrderPlacedText'))
            .replace('{{order}}', order)
            .replace('{{tokenowner1}}', tokenDetails?.token0Name || "")
            .replace('{{tokenowner2}}', tokenDetails?.token1Name || "")}`,
          status: "success",
          txHash: null,
        });
        return true;
      } else {
        setModalData({
          heading: `${order}`,
          bodyText: `${(t('tradeLimitModalOrderFailedText'))
            .replace('{{order}}', order)
            .replace('{{tokenowner1}}', tokenDetails?.token0Name || "")
            .replace('{{tokenowner2}}', tokenDetails?.token1Name || "")}`,
          status: "error",
          txHash: null,
        });
        return false;
      }
    } else {
      setModalData({
        heading: `${(t('tradeLimitModalSignatureHeading'))}`,
        bodyText: `${(t('tradeLimitModalSignatureFailedText'))
            .replace('{{order}}', order)
            .replace('{{tokenowner1}}', tokenDetails?.token0Name || "")
            .replace('{{tokenowner2}}', tokenDetails?.token1Name || "")}`,
        status: "error",
        txHash: null,
      });
    }
  } else {
    setModalData({
      heading: `${(t('tradeLimitModalApprovalHeading'))}`,
      bodyText: `${(t('tradeLimitModalApprovalFailedText')).replace('{{tokenowner}}', tokenDetails?.token0Name || "")}`,
      status: "error",
      txHash: null,
    });
  }
};
