import { createSlice } from "@reduxjs/toolkit";
import tokenABi from "../../Abi/tokenabi.json";
import libertyfinanceIcon from "../../Assets/Images/Icons/tokens/libertyfinance-white.png";
import ethIcon from "../../Assets/Images/Icons/tokens/Eth.svg";
import usdcIcon from "../../Assets/Images/Icons/tokens/usdcIcon.svg";
import usdtIcon from "../../Assets/Images/Icons/tokens/usdticon.svg";
import {
  dynamicContractDetails,
  timeDuration,
  tokenCollection,
} from "../../Services/dynamicContractDetails";


const detailsARB = tokenCollection.find((a) => a.symbol == "ARB");
const detailsLIBFI = tokenCollection.find((a) => a.symbol == "LIBFI");

const tokenOne = {
  name: detailsARB?.name,
  symbol: detailsARB?.symbol,
  address: detailsARB?.address,
  abi: detailsARB?.abi,
  img: detailsARB?.img,
  isNative: detailsARB?.isNative,
  decimals: detailsARB?.decimals,
  isSelected: true,
};
const tokenTwo = {
  name: detailsLIBFI?.name,
  symbol: detailsLIBFI?.symbol,
  address: detailsLIBFI?.address,
  abi: detailsLIBFI?.abi,
  img: detailsLIBFI?.img,
  isNative: detailsLIBFI?.isNative,
  decimals: detailsLIBFI?.decimals,
  isSelected: true,
};

const initialState = {
  tokenOne: tokenOne,
  tokenTwo: tokenTwo,
  tokenList: tokenCollection,
  orderExpiredDays: {
    label: "7 Days",
    convertedTime: "604800",
    id: "6",
    isSelected: false,
  },
  timeList: timeDuration,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,

  reducers: {
    settokenOne: (state, action) => {
      state.tokenOne = action.payload;
    },
    settokenTwo: (state, action) => {
      state.tokenTwo = action.payload;
    },
    settokenList: (state, action) => {
      state.tokenList = action.payload;
    },
    setOrderExpiredDays: (state, action) => {
      state.orderExpiredDays = action.payload;
    },
    resetTokenSlice: () => initialState,
  },
});

export const {
  settokenOne,
  settokenTwo,
  resetTokenSlice,
  setOrderExpiredDays,
} = tokenSlice.actions;
