import FlexBanner from '../../Pages/Private/FlexBanner/FlexBanner';
import profileImg from "../../../Assets/Images/profile-img.png";
import {
  HelpIcon,
  NotificationIcon,
  SmallLogo,
  DotsBounce,
  CloseIcon,
  InfiniteSpinner,
  WalletIcon
} from "../../../Assets/Images/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser,
  reset,
  userDetails,
  walletAddress,
} from "../../../Redux/Slices/user.slice";
import { useNavigate, Link } from "react-router-dom";
import { CHAIN_ID, RESPONSES, VIDEO_URL } from "../../../Utils";
import { Dropdown } from "react-bootstrap";
import { custmizeAddress } from "../../../Services/common.service";
import Tooltip from "../Tooltip/Tooltip";
import { handleUserProgress, updateUserLanguage } from "../../../Redux/Actions/user.action";
import { useEffect, useState } from "react";
import GetWallet from "../Modals/GetWallet/GetWallet";
import ProfileModal from "../Modals/ProfileModal/ProfileModal";
import { GET_USER } from "../../../Redux/Actions/apiResponseInterfaces";
import { callApiPostMethod, callAdminApiGetMethod } from "../../../Redux/Actions/api.action";
import RecentTrades from "../Cards/RecentTrades/RecentTrades";
import { useTranslation } from "react-i18next";
import { changeLanguage, supportedLngs } from "../../../i18n";
import { useDynamicContext, useEmbeddedWallet, DynamicWidget, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import ButtonCustom from "../../../Components/Common/Button/ButtonCustom";
import "./Header.scss";



const Header = ({
  showSidebar,
  setShowSidebar,
}: {
  showSidebar?: boolean;
  setShowSidebar?: any;
}) => {

  // State variables

  const dispatch: any = useDispatch();
  const userDetailsData: any = useSelector( (state: any) => state?.user?.userDetails );
  const [userImage, setUserImage] = useState<string>(profileImg);
  const [showConnect, setShowConnect] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [isPasskeysSet, setIsPasskeysSet] = useState<Boolean>(false);
  const [loader, setLoader] = useState<Boolean>(false);
  const [showVerificationCodeModal, setShowVerificationCodeModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [otpError, setOtpError] = useState<{ status: boolean, message: string }>({ status: false, message: "" });
  const { handleLogOut, isAuthenticated, user, primaryWallet, showDynamicUserProfile, setShowDynamicUserProfile } = useDynamicContext();
  const { createPasskey, isSessionActive, sendOneTimeCode } = useEmbeddedWallet();
  const { getPasskeys } = useEmbeddedWallet();
  const isLoggedIn = useIsLoggedIn();


  // TESTNET
  const [numberOfGTHTransactions, setNumberOfGTHTransactions] = useState(0);
  const [numberOfSwaps, setNumberOfSwaps] = useState(0);
  const [numberOfAdd, setNumberOfAdd] = useState(0);
  const [numberOfRedeem, setNumberOfRedeem] = useState(0);
  const [isLoadingTestnetStats, setIsLoadingTestnetStats] = useState(true);
  const [isLoadingGthTransactions, setIsLoadingGthTransactions] = useState(true);
  const { t, i18n } = useTranslation();



  const disconnectWallet = () => {
    handleLogOut();
  };

  useEffect(() => {
    if (isAuthenticated) {
      setShowConnect(false);
      getUserDetails();
    }
  }, [isAuthenticated]);

  /**
  * This useEffect hook retrieves the Testnet user stats
  **/
  useEffect(() => {
    if (primaryWallet?.connected) {
      handleUserTestnetTransactions();
      handleUserGTHTestnetTransactions();
    }
  }, [primaryWallet]);
  
  /**
  * This useEffect hook closes the Dynamic widget 
  * when the user connects to a new wallet
  **/
  useEffect(() => {
    if (primaryWallet?.connected) {
      setShowDynamicUserProfile(false);
    }
  }, [primaryWallet]);


  useEffect(() => {
    if (isAuthenticated) {
      if (userDetailsData?.image != null && userDetailsData?.image.trim() != "") {
        const profilePic: string = VIDEO_URL + userDetailsData?.image;
        setUserImage(profilePic);
      } else {
        setUserImage(profileImg);
      }
      if (userDetailsData?.language) { 
        changeLanguage(userDetailsData.language);
      }
    } else {
      setUserImage(profileImg);
    }
  }, [userDetailsData, isAuthenticated]);


  useEffect(() => {
    if (primaryWallet?.connected) {
      const fetchPasskeys = async () => {
        try {
          const passkeysResult = await checkPasskeys();
          if (passkeysResult.length != 0) setIsPasskeysSet(true);
        } catch (error) {
          console.error('Error checking passkeys:', error);
        }
      };
      fetchPasskeys();
    }
  }, [primaryWallet]);

  const getUserDetails = async () => {
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      userId: user?.userId,
      from: "Header",
    });
    if (result) dispatch(userDetails(result?.data));
  };

  const updateImage = async () => {
    getUserDetails();
  };

  const handleToggleClick = (isOpen) => {
    if (isOpen) {
      handleUserTestnetTransactions();
      handleUserGTHTestnetTransactions();
    }
  };

  const handleUserTestnetTransactions = async () => {
    try {
      setIsLoadingTestnetStats(true);
      const result = await dispatch(
        callAdminApiGetMethod(
          'GET_USER_TESTNET_TRANSACTIONS',
          [primaryWallet?.address], // params
          false, // showToaster
          false // showLoader
        )
      );
      setNumberOfSwaps(result.TotalSwaps);
      setNumberOfAdd(result.mintTransactions);
      setNumberOfRedeem(result.burnTransactions);
    } catch (error) {
      return;
    } finally {
      setIsLoadingTestnetStats(false);
    }
  };

  const handleUserGTHTestnetTransactions = async () => {
    try {
      setIsLoadingGthTransactions(true);
      const result = await dispatch(
        callApiPostMethod(
          'GET_USER_GTH_TESTNET_TRANSACTIONS',
          { Wallet: primaryWallet?.address },
          false, // showToaster
          false // showLoader
        )
      );
      if (result?.data?.ofTransactions) {
        setNumberOfGTHTransactions(result?.data?.ofTransactions);
      }
    } catch (error) {
      return;
    } finally {
      setIsLoadingGthTransactions(false);
    }
  };

  const checkPasskeys = async () => {
    const passkeys = await getPasskeys();
    return passkeys;
  };

  const handleChangeLanguage = async (language) => { 
    changeLanguage(language);
    if (user?.userId) {
      await updateUserLanguage({
        userId: user?.userId,
        language,
        dispatch,
      });
    } else {
      console.error('User ID is undefined');
    }
  }


  /**
  * This Function Sends a Verification Code.
  * If Sent Successfully, the Validation Code Modal will be Displayed.
  **/
  const onSendOneTimeCodeHandler = async () => {
    if(!isSessionActive) {
      try {
          setLoader(true);
           await sendOneTimeCode();
          setLoader(false);
          setShowVerificationCodeModal(true);
      } catch(e) {
        // handle error
      }
    } else {
      onCreatePasskeyHandler();
    }
  }

  /**
  * This Function Handles the Creation of a Passkey
  **/
  const onCreatePasskeyHandler = async () => {
    try {
      const passkeyArgs = verificationCode ? { oneTimeCode: verificationCode } : undefined;

      setShowVerificationCodeModal(false);

      await createPasskey(passkeyArgs)
      .then((result) => {
        setShowConnect(false);
        toast.success("Passkey Successfully Set");
      })
      .catch((error) => { 
        console.log(JSON.stringify(error, null, 2)); 
        toast.error(t('addPasskeyCodeError'));
      });

    } catch (err) {
      toast.error(t('addPasskeyCodeError'));
      console.log(err);
    }
  };
  

  return (
    <>
      <header className="Header">
          <FlexBanner
            title={t('onboardTestnetWelcome')}
            //ctaLink="https://google.com"
            ctaLink="/testnet/guide"
            ctaTitle={t('onboardTestnetHelp')}
            isCenter={true}
            crossIconSize={12}
            animationTime={1.5}
            delayToShowBanner={0}
            //wrapperStyle={{ backgroundColor: "lightblue" }}
            mainStyleTitle={{ color: "white" }}
            mainStyleLink={{ color: "lightblue" }}
            crossStyle={{ color: "red" }}
          />
        <div className="HeaderInner">
          <div className="HeaderLogo d-md-none">
            <Link
              to= "/"
            >
              <SmallLogo />
            </Link>
          </div>
          <ul>
            <li className="dynamicwidget">

                <div className={`wallet_not_connected ${isLoggedIn && !primaryWallet?.address ? '' : 'hide-dynamicwidget'}`}>
                  <ButtonCustom
                    title={
                      <>
                        <WalletIcon /> 
                        Select A Wallet
                      </>
                    }
                    onClick={() => setShowDynamicUserProfile(true)}
                  />
                </div>
     
                <div className={`HeaderDynamicWidget ${isLoggedIn && !primaryWallet?.address ? 'hide-dynamicwidget' : ''}`}>
                  <DynamicWidget variant="modal" />
                </div>

            </li>
            <li style={{ marginLeft: '0px' }}>
              { isAuthenticated && (
                <button className="HeaderActionBtn">
                  <NotificationIcon />
                </button>
              )};
              <button className="HeaderActionBtn">
                <HelpIcon />
              </button>
            </li>
            <li>
            <Dropdown style={{ marginRight: '10px' }}>
              <Dropdown.Toggle className="HeaderLanguageDDL">
                {i18n.language?.toUpperCase()}
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    Object.keys(supportedLngs).map((lang) => (<Dropdown.Item onClick={() => handleChangeLanguage(lang)}>{supportedLngs[lang]}</Dropdown.Item>))
                  }                  
              </Dropdown.Menu>
            </Dropdown>
            </li>
            { isAuthenticated && (
              <li>
                <Dropdown onToggle={handleToggleClick}>
                  <Dropdown.Toggle className="HeaderActionBtn">
                    <img src={userImage} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    { isAuthenticated && (
                      <>
                        <Dropdown.Item onClick={() => setShowProfileModal(true)}>
                          {t('profileMenuUpdateProfile')}
                        </Dropdown.Item>
                      </>
                    )}

                    { primaryWallet?.connected ? (
                      <>
                        {!isPasskeysSet && primaryWallet.connector.isEmbeddedWallet && (
                          <Dropdown.Item onClick={() => onSendOneTimeCodeHandler()}>
                            Secure Your Wallet
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item className="Header-Testnet-Stats">
                          {t('profileMenuSwapCount')} {isLoadingTestnetStats ? <DotsBounce /> : `${numberOfSwaps}`} {t('profileMenuCount')}
                        </Dropdown.Item>

                        <Dropdown.Item className="Header-Testnet-Stats">
                          {t('profileMenuAddCount')} {isLoadingTestnetStats ? <DotsBounce /> : `${numberOfAdd}`} {t('profileMenuCount')}
                        </Dropdown.Item>

                        <Dropdown.Item className="Header-Testnet-Stats">
                          {t('profileMenuRedeemCount')} {isLoadingTestnetStats ? <DotsBounce /> : `${numberOfRedeem}`} {t('profileMenuCount')}
                        </Dropdown.Item>

                        {numberOfGTHTransactions > 0 && (
                          <Dropdown.Item>
                            {t('profileMenuGTHTransactionCount')} {isLoadingGthTransactions ? <DotsBounce /> : `${numberOfGTHTransactions}`}
                          </Dropdown.Item>
                        )}
                      </>
                    ) : null}

                    <Dropdown.Item onClick={() => isAuthenticated ? disconnectWallet() : setShowConnect(true) }>
                      { isAuthenticated ? (
                        <span>{t('profileMenuDisconnect')}</span>
                      ) : (
                        <span>{t('connectWallet')}</span>
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            )};

            <li className="d-inline-flex d-md-none">
              <button
                onClick={() => setShowSidebar(!showSidebar)}
                className={`hamburger_menu ${showSidebar ? "active" : ""}`}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </li>
          </ul>
        </div>
        <div className="standalone-dynamicwidget">
          {isLoggedIn && !primaryWallet?.address ? (
            <div className="wallet_not_connected">
              <ButtonCustom
                title={
                  <>
                    <WalletIcon /> 
                    Select A Wallet
                  </>
                }
                onClick={() => setShowDynamicUserProfile(true)}
              />
            </div>
          ) : (
            <DynamicWidget variant='modal' />
          )}
        </div>
      </header>
      <ProfileModal
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        updateImage={updateImage}
      />
      {showConnect ? (
        <GetWallet
          show={showConnect}
          handleClose={() => {
            setShowConnect(false);
          }}
        />
      ) : null}

      {/* Display the loading animation */}
      { loader && (
        <div className="passkey-loader">
          <InfiniteSpinner />
        </div>
      )};

      {/* Display the email verification modal */}
      {showVerificationCodeModal && (
        <Modal
          centered
          className="profile_modal"
          backdrop= "static"
          keyboard= { false }
          show={ true }
          onHide={() => {
            setOtpError({ status: false, message: "" });
            setShowVerificationCodeModal(false);
            setVerificationCode("");
          }}
        >
        <Modal.Header>
          <Modal.Title>
            { t('addPasskeyCodeSent') }
          </Modal.Title>
          <button
            className="modal_close_btn"
            onClick={() => {
              setOtpError({ status: false, message: "" });
              setShowVerificationCodeModal(false);
              setVerificationCode("");
            }}
          >
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body> 
          <div className="otp-section">
            <div className="otp-body">
              <div className="otp-input-box otp-box">

                {otpError.status && (
                  <div className="otp-error-msg"> { otpError.message } </div>
                )}

                <div className="otp-input-section">
                  <div className="otp-input-container">
                    <div className="otp-input-container-input">
                      <input
                      placeholder="Enter Verification Code"
                      type="text"
                      className={`verificationcCodeInput`}
                      value={verificationCode}
                      onChange={(e) => {
                        e.preventDefault();
                        setVerificationCode(e.target.value);
                      }}
                      inputMode="decimal"
                      name="fname"
                      autoComplete="off"
                    />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items">
                  <ButtonCustom
                    title={t('addPasskeyClearInput')}
                    className="bordered-blue"
                    fluid
                    onClick={() => {
                      if (otpError.status) { setOtpError({ status: false, message: "" }); };
                      setVerificationCode("");
                    }}
                  />
                  <ButtonCustom
                    title={t('addPasskeyConfirmation')}
                    fluid
                    className="bordered-blue"
                    disabled={ !verificationCode }
                    onClick={() => {
                      onCreatePasskeyHandler();
                    }}
                  />
                </div>

                <div className="otp-resend">
                  <span style={{ marginRight: '5px' }}>{t('addPasskeyCodeNotReceived')}</span> 
                  <a href="javascript:void(0)"
                      onClick={(e) => {
                        toast.success(t('addPasskeySuccessResent'));
                        if (otpError.status) { setOtpError({ status: false, message: "" }); };
                          setVerificationCode("");
                        }}>{t('addPasskeyCodeResent')}
                  </a>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      )}



    </>
  );
};

export default Header;
