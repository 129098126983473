import React, { useEffect, useMemo, useState } from "react";
import { CloseIcon, DownArrowCircle, WalletIcon, InfiniteSpinner } from "../../../../Assets/Images/Icons/SvgIcons";
import { toast } from "react-hot-toast";
import ShariahIcon from '../../../../Assets/Images/Icons/shariah.png'
import { Modal } from "react-bootstrap";
import Tooltip from "../../Tooltip/Tooltip";
import ButtonCustom from "../../Button/ButtonCustom";
import InputCustom from "../../FormInputs/Input/Input";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import { fetchTokenBalance } from "../../../../LibfiServices/Hooks/usefetchTokenBalance";
import { useDispatch } from "react-redux";
import { cryptoDecimals, dynamicToDecimal, toCustomFixed, toFixed, formatNumberWithMagnitudeSuffix, validateInput } from "../../../../Services/common.service";
import { EXPLORER_LINK, hundred } from "../../../../Utils";
import TransactionModal from "../TransactionDone/TransactionModal";
import SettingModal from "../SettingModal/SettingModal";
import { TOKEN_DETAILS_SINGLE, TOKENS } from "../../../../interfaces/commonInterfaces";
import { callApiPostMethod } from "../../../../Redux/Actions/api.action";
import { AMANA_VAULTS, POOLS, PORTFOLIO_GRAPH } from "../../../../Redux/Actions/apiResponseInterfaces";
import { useTranslation } from "react-i18next";
import LiquidityPoolGraph from "../../../Pages/graph/LiquidityPoolGraph";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { addBreadcrumb } from './../../../../SentryContext';
import { addAmanaVaultHelperFunction } from "../../../../LibfiServices/Helpers/EarnAmanaVaultHelper";
import { removeAmanaVaultHelperFunction } from "../../../../LibfiServices/Helpers/RedeemAmanaVaultHelper";
import "./AmanaModal.scss";


type AmanaModal = {
  show: boolean;
  handleClose: Function;
  showTab: string;
  getAmanaVaults: Function;
  showSettingsModal: Function;
  selectedAmanaVault: AMANA_VAULTS["data"][1];
  amanaVaultData?: any;
  isRefreshing?: boolean;
};

const AmanaModal = (props: AmanaModal) => {

  // State variables
  const dispatch: any = useDispatch();
  const { primaryWallet } = useDynamicContext();
  const [show, setShow] = useState<boolean>(false);
  const [showConnect, setShowConnect] = useState<boolean>(false);
  const [settingModal, setSettingModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<boolean>();
  const [loader, setLoader] = useState<boolean>(false);
  const amanaVault: AMANA_VAULTS["data"][1] = props?.selectedAmanaVault;
  const [tokenBalance, setTokenBalance] = useState<{ tokenBalance: any; tokenBalanceConverted: any; }>({ tokenBalance: 0, tokenBalanceConverted: 0 });
  const [graphData, setGraphData] = useState<PORTFOLIO_GRAPH["data"]>([]);
  const [withdrawValue, setWithdrawValue] = useState<any>(0);
  const [estimatedAmanaVaultShare, setEstimatedAmanaVaultShare] = useState(0);
  const [insufficientLp, setinsufficientLp] = useState<boolean>(false);
  const [maxValueCheck, setmaxValueCheck] = useState<boolean>(false);
  const [inputOne, setinputOne] = useState<{ convertedValue: bigint; inputValue: string; }>({ convertedValue: BigInt(0), inputValue: "" });
  const [modalData, setModalData] = useState<{ heading: string; bodyText: string; hideCloseButton?: boolean; hideRetryButton?: boolean; status: string; txHash: string | null; }>({ heading: "", bodyText: "", hideCloseButton: false,  hideRetryButton: false, status: "", txHash: "", });
  const [estimatedMinimumReceiveTokens, setEstimatedMinimumReceiveTokens] = useState<{ tokenReceive: number; }>({ tokenReceive: 0 });
  const [fetchingBalance, setFetchingBalance] = useState<boolean>(false);
  const { t } = useTranslation();


  // Hooks

  /**
  * This is a memoized function to get details of the underlying asset of the selected amana vault
  **/
  const tokenDetails: TOKEN_DETAILS_SINGLE = useMemo(() => {
    return {
      tokenAddress: amanaVault?.underlyingAssetAddress,
      tokenName: amanaVault?.underlyingAssetName,
      isTokenNative: amanaVault?.underlyingAssetNative,
    };
  }, [amanaVault]);
  
  /**
  * This is a custom hook fetching the balance from the user's wallet
  **/
  const fetchBalance = async () => {
    setFetchingBalance(true);
    try {
      const tokenBalance = await fetchTokenBalance({ walletAddress: primaryWallet?.address, tokenDetails });
      if (tokenBalance) { 
        setTokenBalance(tokenBalance);
      }
    } catch (error) {

    }
    setFetchingBalance(false);
  };


  /**
  * This useEffect hook retrieves the token balances and calculates 
  * the input field value if any when the selected amana vault data is refreshed
  **/
  useEffect(() => {
    fetchBalance();
    getGraphData(); 

    if ( Number(inputOne.inputValue) !== 0 ) {
      handleInputs(inputOne.inputValue, false);
    } 
  }, [amanaVault]);

  /**
  * This useEffect hook checks whether the user has a LP balance or not
  **/
  useEffect(() => {
    Number(amanaVault.userVaultShares) == 0 ? setinsufficientLp(true) : setinsufficientLp(false);
  }, [amanaVault]);

  /**
  * This useEffect hook dynamically 
  * - Determines the amount of tokens to withdraw based on the withdrawal slider
  * - calculates the estimated amana vault share based on the withdrawal slider
  **/
  useEffect(() => {
    if (primaryWallet?.connected) {
      estimateTokenChangeOnWithdrawal();
      setEstimatedAmanaVaultShare(calculateEstimatedAmanaVaultShare());
    }
  }, [amanaVault, withdrawValue]);

  /**
  * This useEffect hook enables tracking of the active modal tab
  **/
  useEffect(() => {
    setActiveTab(props?.showTab == "earn" ? true : false);
  }, [props?.show, props?.showTab]);


  // Functions

  /**
  * This function clears the input boxes
  **/
  const emptyValues = async () => {
    setinputOne({
      convertedValue: BigInt(0),
      inputValue: "",
    });
  };

  /**
  * This function sets the show state to true
  **/
  const handleOpen = () => setShow(true);

  /**
  * This function resets and recalculates all values when a Transaction occurred
  **/
  const resetSelecAmanaVault = async () => {
    setWithdrawValue(0);
    emptyValues();
    props?.getAmanaVaults(); // Refresh All The Amana Vaults Data
  };

  /**
  * This function computes the estimated amana vault share of the user, displayed in the 'Redeem' tab
  */
  const calculateEstimatedAmanaVaultShare = () => {
    const estimatedAmanaVaultShare = Number(amanaVault.userVaultShares) * (1 - withdrawValue / 100 ) * 100 / Number(amanaVault.totalVaultShares);
    return estimatedAmanaVaultShare;
  };

  /**
  * This function draws a graph showing the TVL and the volume of the selected amana vault for the last 30 days
  * NB: The graph does not refresh, in contrast to the other fields in the modal
  **/
  const getGraphData = async () => {
    if (props.show && (!graphData || graphData.length === 0)) {
    setLoader(true);
      const result: PORTFOLIO_GRAPH = await dispatch(
        callApiPostMethod(
          "GET_GRAPH",
          { pairAddress: amanaVault?.amanaVaultAddress },
          false,
          false
        )
      );
      setGraphData(result?.data);
      setLoader(false);
    }
  };

  /**
  * This function checks whether the user has a LP balance or not 
  **/
  const estimateTokenChangeOnWithdrawal = async () => {

    const token0Receive: number =  amanaVault.userVaultSharesToUnderlyingAsset * ( withdrawValue / hundred );
    setEstimatedMinimumReceiveTokens({tokenReceive: token0Receive})

  };

  /**
  * This function handles input fields when the user clicks on the 'max' button
  * @param data : Input field where the user has clicked the 'max' button
  **/
  const addMaxLiquidity = async () => {
    if (!primaryWallet?.connected) return;

    setmaxValueCheck(true);
  
    if ( tokenBalance?.tokenBalanceConverted > 0 ) {
      if (tokenDetails?.isTokenNative) {

        // Deduct 0.001 as estimated gas fees for native currency
        const newBalance = toFixed(
          Number(tokenBalance?.tokenBalanceConverted) - 10000000000000000
        ); 
        
        handleInputs(newBalance > 0 ? newBalance : 0, true);

      } else {

        handleInputs(tokenBalance?.tokenBalanceConverted, true);

      }
    }
  };

  /**
  * This function handles both input fields
  * @param e : Value that the user has entered
  * @param field : Field in which user has entered the value
  * @param max : Boolean value indicating  whether user has clicked on the 'max' button or not
  **/
  const handleInputs = async (e: any, max: boolean) => {
    try {

      const inputValue = validateInput(e, "field1", amanaVault.underlyingAssetDecimals.toString(), "0");

      if (!inputValue && !inputValue?.toString().includes(".")) {

        setmaxValueCheck(false);
        setinputOne({
          convertedValue: BigInt(0),
          inputValue: "",
        });

        setTimeout(() => {
          emptyValues();
        }, 100);

      } else {
        
        const decimals = amanaVault.underlyingAssetDecimals;
        
        let convertedValue: bigint = max
          ? BigInt(inputValue)
          : BigInt( (Number(inputValue) * 10 ** decimals).toLocaleString("fullwide", { useGrouping: !1 }) );       

        let originalValue: string = max
          ? dynamicToDecimal(Number(inputValue) / 10 ** decimals, 6)
          : inputValue;

        setinputOne({
          convertedValue: convertedValue,
          inputValue: originalValue,
        })

        // Notify the user that a portion of the maximum token amount has been deducted for gas fees
        if ( max && tokenDetails?.isTokenNative ) {
          toast.success((t('earnModelGasFeesDeduction')).replace('{{tokenName}}',  tokenDetails?.tokenName+"" ), {
              id: "GasFeesRemoval",
              duration: 3000,
          });
        }
      }
    } catch (error) {
      console.error("Error in handleInputs:", error);
      console.error("Error handling input:", error);
    }
  };
  

  /**
  * This Function Manages the Addition of Liquidity from the Selected Amana Vault
  **/
  const handleAddLiquidity = async () => {
    try {
      
      if (!primaryWallet?.connected) return;

      addBreadcrumb('AmanaLiquidityAddition', '[AmanaLiquidityAddition] Add Liquidity Button Clicked', 'info')
      addBreadcrumb('AmanaLiquidityAddition', '[AmanaLiquidityAddition] Process Started - ' + tokenDetails?.tokenName, 'info');

      const addAmanaVaultResult: boolean | undefined =
        await addAmanaVaultHelperFunction(
          tokenDetails,
          inputOne,
          primaryWallet.address,
          props?.selectedAmanaVault?.amanaVaultAddress,
          handleOpen,
          setModalData,
          t
        );

        resetSelecAmanaVault();

    } catch (error) {

      //console.log("Liquidity Addition Failed...")

    }
  };

  /**
  * This Function Manages the Removal of Liquidity from the Selected Amana Vault
  **/
  const withdrawLiquidity = async () => {
    try {

      if (!primaryWallet?.connected) return;

      addBreadcrumb('AmanaLiquidityRemoval', '[AmanaLiquidityRemoval] Button Clicked', 'info')
      addBreadcrumb('AmanaLiquidityRemoval', '[AmanaLiquidityRemoval] Process Started - ' + tokenDetails?.tokenName, 'info');

      const shareDetails: TOKEN_DETAILS_SINGLE = {
        isTokenNative: props?.selectedAmanaVault?.underlyingAssetNative,
        tokenAddress: props?.selectedAmanaVault?.amanaVaultAddress,
        tokenName: props?.selectedAmanaVault?.vaultShareSymbol
      };

      const removeAmanaVaultResult: boolean | undefined =
      await removeAmanaVaultHelperFunction(
        shareDetails,
        withdrawValue,
        primaryWallet.address,
        props?.selectedAmanaVault?.amanaVaultAddress,
        handleOpen,
        setModalData,
        t
      );

      resetSelecAmanaVault();
      
    } catch (error) {

      //console.log("Liquidity Removal Failed...")
    
    }
  };

  /**
  * This function is called when the user interacts with the slider in the 'Withdraw' tab
  * If the user has no LP token, the slider will be disabled and a toast will popup
  **/
  const SliderDisabledToast = (amanaTokenName) => {
    if (insufficientLp) toast.error(t('amanaVaultsSliderDisabledError').replace('{{token}}', amanaTokenName), { id: "SliderDisabled" })
  };

  /**
  * This function allows displaying the informative toast for Embedded Wallets
  **/
  const displayToast = () => {
    toast.loading(modalData.bodyText, {
      id: "PoolInfoToast",
      style: { fontSize: '16px' }
    })
  };

  /**
  * This function allows hiding the informative toast for Embedded Wallets
  **/
  const dismiss = () => toast.dismiss("PoolInfoToast")

  /**
  * Checks whether the input fields are filled and correctly formatted
  * Returns a boolean value indicating the validity of the form.
  **/
    const isFormValid: boolean | undefined =
    inputOne?.inputValue !== "" &&
    inputOne?.inputValue?.split(".")[1] !== "";

  /**
  * Checks if the input values exceed available balances
  * Returns a boolean indicating whether there are sufficient funds
  **/
  const insufficientFunds =
    Number(tokenBalance?.tokenBalanceConverted) < Number(inputOne?.convertedValue) 
      || (maxValueCheck && Number(inputOne?.convertedValue) == 0);

  /**
  * Checks if the user has enough native tokens to pay the estimated gas fees
  * Returns a boolean indicating whether there are sufficient funds for gas fees
  **/
  const insufficientGasFees = () => {
    if (
      ( tokenDetails.isTokenNative && 
        Number(tokenBalance?.tokenBalanceConverted) - Number(inputOne?.convertedValue) < 10000000000000000 &&
        Number(tokenBalance?.tokenBalanceConverted) - Number(inputOne?.convertedValue) >= 0 ) 
    ) {
        return true;
    }
    return false;
  };


  return (
    <>
      <Modal
        backdropClassName="amanaModalBg"
        centered
        show={props?.show}
        className="amana-modal"
      >
        <button
          className="modal_close_btn"
          onClick={() => {
            emptyValues();
            setWithdrawValue(0);
            props?.handleClose();
          }}
        >
          <CloseIcon />
        </button>
        <Modal.Body>
          <div className="amana-modal-data">
            <div className="d-flex">
              <ButtonCustom
                transparent={!activeTab}
                title={t('earnModelEarnButton')}
                className={activeTab ? "bordered-green" : ""}
                onClick={() => setActiveTab(true)}
              />
              <ButtonCustom
                transparent={activeTab}
                className={!activeTab ? "bordered-green" : ""}
                title={t('earnModelRedeemButton')}
                onClick={() => {
                  setActiveTab(false);
                }}
              />
              <SettingModal
                show={settingModal}
                handleClose={() => setSettingModal(false)}
              />
            </div>
            {activeTab ? (
              <>
                <div className="amana-details">
                  <div className="amount-details-container">
                    <div className="amount-details-box-container">
                        <InputCustom
                          // shimmer={shimmerState == "field2" ? true : false}
                          placeholder={"0.00"}
                          name="value1"
                          leftIcon={
                            <>
                              <img src={amanaVault?.underlyingAssetURI} alt="" />{" "}
                              {amanaVault?.underlyingAssetName}
                            </>
                          }
                          type="text"
                          maxLength={18}
                          onChange={(e: any) => {
                            e.preventDefault();
                            handleInputs(e?.target?.value, false);
                          }}
                          value={ inputOne?.inputValue }
                        />
                      <div className="amount-details-box-details">
                        {/* <p> */}
                        <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                          <span>$</span>
                          <span>{formatNumberWithMagnitudeSuffix(amanaVault.underlyingAssetDollarValue || 0)}</span>
                        </p>
                        <p>
                          {t('earnModelBalance')}:{" "}
                          {/* <span> */}
                          <span className={`${fetchingBalance ? 'text-shadow' : ''} ${ !fetchingBalance ? 'lineUp' : '' }`}>
                            {formatNumberWithMagnitudeSuffix(tokenBalance?.tokenBalance)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  {primaryWallet?.connected ? (
                    <ButtonCustom
                      title={t('earnModelMaximizeDeposit')}
                      fluid
                      onClick={() => {
                        addMaxLiquidity();
                      }}
                      disabled={ fetchingBalance || tokenBalance?.tokenBalance == 0 }
                    />
                  ) : null}
                  <div className="user-assets-container">
                    <h4 className="user-assets-title">{t('amanaVaultsYourAmanaHolding')}</h4>
                    <table className="user-assets-table">
                      <tbody>
                        <tr>
                          <td className={`user-assets-table-column1 ${props.isRefreshing ? 'greyedout' : ''}`}>
                            <p>{(t('amanaVaultsShares')).replace('{{NumberOfShares}}', formatNumberWithMagnitudeSuffix(amanaVault.userVaultShares))}</p>
                          </td>
                          <td className="user-assets-table-column2">
                            <button className="user-assets-table-down-arrow-btn">
                              <DownArrowCircle />
                            </button>
                          </td>
                          <td>
                            <div className="user-assets-table-tokens">
                              <img src={amanaVault?.underlyingAssetURI} alt="" />
                                {/* <p> */}
                                <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                                  {formatNumberWithMagnitudeSuffix(amanaVault.userVaultSharesToUnderlyingAsset)} {amanaVault?.underlyingAssetName}
                                </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="user-assets-amana-vault-share">
                      <p>{t('earnModelPoolShare')}:</p>
                      {/* <p> */}
                      <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                        <span>
                          {`${amanaVault?.userShare 
                              ? `${amanaVault?.userShare > 0 && amanaVault?.userShare < 0.01
                                  ? "< 0.01"
                                  : toCustomFixed(amanaVault?.userShare || 0, 2)
                                }`
                              : "0.00"
                          }`}
                        </span>
                        <span>%</span>
                      </p>
                    </div>
                  </div>

                  {primaryWallet?.connected ? (
                    <ButtonCustom
                      title={
                        //walletAddress && !insufficientFunds 
                        //  ? t('earnModelAddLiquidity')
                        //  : t('earnModelInsufficientFund')
                        primaryWallet?.connected && !insufficientFunds && !insufficientGasFees()
                          ? t('earnModelAddLiquidity')
                          : insufficientGasFees()
                            ? (t('earnModelInsufficientGasFees')).replace('{{tokenName}}', amanaVault?.underlyingAssetName ) 
                            : t('earnModelInsufficientFund')
                      }
                      fluid
                      onClick={() => handleAddLiquidity()}
                      disabled={!isFormValid || insufficientFunds || insufficientGasFees()}
                    />
                  ) : (
                    <ButtonCustom
                      fluid
                      title={
                        <>
                          <WalletIcon /> {t('connectWallet')}
                        </>
                      }
                      show={showConnect}
                      setShow={setShowConnect}
                      onClick={() => setShowConnect(true)}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="redeem-details">
                  <h2>
                    <span>{withdrawValue}</span>
                    <span>%</span>
                  </h2>
                  {/* <p className="lp_text">
                    LP Tokens: {formatNumberWithMagnitudeSuffix(lpTokensToBurn)} / {formatNumberWithMagnitudeSuffix(divideWithDecimal(userLpBalance, 18))}
                  </p> */}

                  <div onClick={() => SliderDisabledToast(amanaVault?.vaultShareName)}>
                    <Slider
                      max={100}
                      min={0}
                      // dots
                      step={1}
                      onChange={(value: any) => {
                        SliderDisabledToast(amanaVault?.vaultShareName);
                        setWithdrawValue(value);
                        }}
                      disabled={insufficientLp}
                      value={withdrawValue}
                      reverse={document.body.dir === 'rtl'}
                      dotStyle={{
                        width: "8px",
                        height: "8px",
                        border: "1px solid #4169EF",
                        backgroundColor: "#241F47",
                      }}
                      activeDotStyle={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: " #4169EF",
                      }}
                      marks={document.body.dir === "rtl" ?
                        { 0: "%0", 25: "%25", 50: "%50", 75: "%75", 100: "%100" }
                        : { 0: "0%", 25: "25%", 50: "50%", 75: "75%", 100: "100%" }
                      }
                    />
                  </div>

                  <button className="redeem-down-arrow-btn">
                    <DownArrowCircle />
                  </button>
                  <div className="redeem-balance-container">
                    <table className="redeem-balance-table">
                      <tbody>
                        <tr>
                          <td></td>
                          <td><h4>{t('earnModelWithdrawing')}</h4></td>
                          <td><h4>{t('earnModelTotalHeld')}</h4></td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="token-data">
                              <img className="redeem-balance-table-token-image" src={amanaVault?.underlyingAssetURI} alt="" />
                                <p className="redeem-balance-table-token">{amanaVault?.underlyingAssetName}</p>
                                <sup>
                                  <Tooltip icon={<img className="redeem-balance-table-shariah-image" src={ShariahIcon} />}>
                                    <p>{t('earnModelApprovedBySharishBoard')}</p>
                                  </Tooltip>
                                </sup>
                            </h4>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(cryptoDecimals(
                                estimatedMinimumReceiveTokens?.tokenReceive
                              )) || 0}
                            </p>
                          </td>
                          <td>
                            {/* <p> */}
                            <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>
                              {formatNumberWithMagnitudeSuffix(amanaVault.userVaultSharesToUnderlyingAsset)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="hyphen-text"></p>
                  <div className="user-assets-amana-vault-share">
                      <p>{t('earnModelEstPoolShare')}:</p>
                      {/* <p> */}
                      <p className={`${props.isRefreshing ? 'greyedout' : ''}`}>  
                        <span>
                          {`${estimatedAmanaVaultShare 
                              ? `${estimatedAmanaVaultShare > 0 && estimatedAmanaVaultShare < 0.01
                                  ? "< 0.01"
                                  : toCustomFixed(estimatedAmanaVaultShare, 2)
                                }`
                              : "0.00"
                          }`}
                        </span>
                        <span>%</span>
                      </p>
                  </div>
                  {primaryWallet?.connected ? (
                    <ButtonCustom
                      title={
                        !insufficientLp ? t('earnModelWithdraw') : t('amanaVaultsInsufficientTokens').replace('{{token}}', amanaVault?.vaultShareName)
                      }
                      className="wallet_btn"
                      fluid
                      onClick={() => withdrawLiquidity()}
                      disabled={
                        !withdrawValue || !primaryWallet?.connected || insufficientLp
                      }
                    />
                  ) : (
                    <ButtonCustom
                      title={
                        <>
                              <WalletIcon /> {t('connectWallet')}
                        </>
                      }
                      className="wallet_btn"
                      show={showConnect}
                      fluid
                      setShow={setShowConnect}
                      onClick={() => setShowConnect(true)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
            <>
              <div className="modal-earn-chart">
                <Link
                  to={`${EXPLORER_LINK}address/${amanaVault?.amanaVaultAddress}`}
                  target="blank"
                  className="view_link"
                >
                  {t('amanaVaultsModalViewTrx')}
                </Link>
                <div className="chart_img">
                  {/* <img src={Graphimg} alt="" /> */}
                  {loader ? (
                    // <Shimmer height={272} fluid />
                    <InfiniteSpinner />
                  ) : (
                    <LiquidityPoolGraph data={graphData} />
                  )}
                </div>
              </div>
            </>
        </Modal.Body>
      </Modal>

      {show && (
        <>
          {primaryWallet?.connector.isEmbeddedWallet && modalData?.status === "pending" ? displayToast() : dismiss()}

          {(!primaryWallet?.connector.isEmbeddedWallet || modalData?.status !== "pending") && (
            <TransactionModal
              show={show}
              modalData={modalData}
              handleClose={() => setShow(false)}
              handleFunction={activeTab ? handleAddLiquidity : withdrawLiquidity}
            />
          )}
        </>
      )}

    </>
  );
};

export default AmanaModal;
