import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./ShariahTokenPage.scss";
import { LeftArrow } from "../../../../Assets/Images/Icons/SvgIcons";
import { useTranslation } from "react-i18next";
import { cryptoDecimals } from "../../../../Services/common.service";
import { stringify } from "querystring";


const Shariah = () => {

  // Value from the Shariah Overview Page - Start

  const { t } = useTranslation();
  const { state } = useLocation();
  const tokenName = state?.token.token_name;
  const tokenSymbol = state?.token.token_symbol;
  const tokenImage = state?.token.token_image;
  const degrees = state?.token.speedometer;
  const tokenDescription = state?.token.about;
  const price = state?.token.price;
  const volume = state?.token.volume;
  const supply = state?.token.supply;
  const shariahReview = state?.token.shariah_review;
  const sliders = [
    { key: 'Fundamental', label: t('shariahFundamental'), value: state?.token.fundamental_score },
    { key: 'Functionality', label: t('shariahFunctionality'), value: state?.token.function_score },
    { key: 'Transparency', label: t('shariahTransparency'), value: state?.token.transparency_score },
    { key: 'Reputation', label: t('shariahReputation'), value: state?.token.reputation_score },
    { key: 'Volatility', label: t('shariahVolatility'), value: state?.token.volatility_score }
  ]
  const buttonsData = [
    { id: 1, name: t('shariahFundamental'), text: state?.token.fundamental_overview },
    { id: 2, name: t('shariahFunctionality'), text: state?.token.function_overview },
    { id: 3, name: t('shariahTransparency'), text: state?.token.transparency_overview },
    { id: 4, name: t('shariahReputation'), text: state?.token.reputation_overview },
    { id: 5, name: t('shariahVolatility'), text: state?.token.volatility_overview }
  ]
  // Value from the Shariah Overview Page - End

  // Manager Back button
  const navigate = useNavigate();

  useEffect(() => {
    if (sliders) {
      updateCompliance(degrees);
      document.documentElement.style.setProperty('--recommendation-degrees', `${degrees}deg`);

      sliders.forEach(slider => {
        document.documentElement.style.setProperty(`--${slider.key.toLowerCase()}-percantage`, `${slider.value}%`);
        document.documentElement.style.setProperty(`--${slider.key.toLowerCase()}-color`, updateGradientColor(slider.value));
      });
    }
  }, []);


  // Manage Speedometer
  const updateCompliance = (degrees) => {
    let compliance;
    let textColor;
    if (degrees <= 0 && degrees > -36) {
      compliance = t('shariahCompliant');
      textColor = "#498DE1";
    } else if (degrees <= -36 && degrees > -90) {
      compliance = t('shariahQuestionable');
      textColor = "yellow";
    } else {
      compliance = t('shariahNotCompliant');
      textColor = "#CD6868";
    }
    return { compliance, textColor };
  };

  const { compliance, textColor } = updateCompliance(degrees);


  // Manage Slider
  function updateGradientColor(value) {
    let SliderColor;
    if (value >= 0 && value < 50) {
      SliderColor = "#CD6868";
    } else if (value >= 50 && value < 80) {
      SliderColor = "yellow";
    } else {
      SliderColor = "#498DE1";
    }
    return SliderColor;
  }

  // Manager Buttons
  const [selectedButton, setSelectedButton] = useState(1); // Initial selected button

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
  };

  if (state === null || state === undefined) {
    window.location.href = "/shariah/tokens";
    return null;
  }

  return (

    <div>

      {/* BACK BUTTON */}
      <div className="shariah-token-analysis-back-button">
        <h3>
          <button onClick={() => navigate("/shariah/tokens")}>
            <LeftArrow />
          </button>
          {t('shariahBackButton')}
        </h3>
      </div>

      <div className="shariah-token-analysis-section">
        {/* HEADER */}
        <div className="shariah-token-analysis-header-token-section">
          <img
            className="shariah-token-analysis-header-token-icon"
            src={tokenImage}
            alt=""
          />
          <div className="content">
            <h1>
              <span>{tokenName}</span>
              <span className="mx-1"></span>
              <span>({tokenSymbol}</span>
              <span>)</span>
            </h1>
          </div>
        </div>

        {/* TOKEN INFORMATION */}
        <div>
          <table className="shariah-token-analysis-table">
            <tbody>
              <tr>
                <th>{t('shariahPrice')}</th>
                <th>{t('shariahMarketCap')}</th>
                <th>{t('shariahSupply')}</th>
              </tr>
              <tr>
                <td>
                  <span>$</span>
                  <span>{cryptoDecimals(price)}</span>
                </td>
                <td>{cryptoDecimals(volume)}</td>
                <td>{cryptoDecimals(supply)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div className="shariah-token-analysis-container">
            <div className="shariah-token-analysis-container-graph">
              {/* SPEEDOMETER */}
              <div className="shariah-token-analysis-speedometer-section">
                <div className="shariah-token-analysis-speedometer-container-titles">
                  <div className="shariah-token-analysis-speedometer-container-titles-row">
                    {/* 
                    <span className="speedometer-container-titles-row-title">
                      Not Compliant
                    </span>
                    <span className="speedometer-container-titles-row-title">
                      Compliant
                    /span> 
                    */}
                  </div>
                </div>
                <div className="shariah-token-analysis-speedometer-container-graph style" id="style">
                  <div className="shariah-token-analysis-speedometer-container-graph-scale">
                    <div className="shariah-token-analysis-speedometer-container-graph-scale-border">
                    </div>
                    <div className="shariah-token-analysis-speedometer-maskWrap-container">
                      <div className="shariah-token-analysis-speedometer-mask">
                        <div className="shariah-token-analysis-speedometer-mask-color">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shariah-token-analysis-speedometer-container-arrow">
                    <div className="shariah-token-analysis-speedometer-arrow">
                      <span className="shariah-token-analysis-speedometer-icon" role="img">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 77" width="8" height="77">
                          <path fill="currentColor" d="M4 0h1l.93 69.5a4 4 0 1 1-3.86 0L3 0h1z">
                          </path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="shariah-token-analysis-speedometer-title">
                  <span style={{ color: textColor }}>
                    {compliance}
                  </span>
                </div>
              </div>

              {/* CRITERIA */}
              <div className="shariah-token-analysis-sliders-section">
                {sliders.map((slider, index) => (
                  <div className="shariah-token-analysis-sliders-container" key={index}>
                    <p>{slider.label}</p>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={slider.value}
                      className={`gauge-slider slider-${slider.key.toLowerCase()}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="shariah-token-analysis-description-section">
              <h2>{(t('shariahWhatIsHeading')).replace('{{tokenName}}', tokenName)}</h2>
              <p><div dangerouslySetInnerHTML={{ __html: tokenDescription }}></div></p>
            </div>
          </div>
        </div>

        <div className="shariah-token-analysis-buttons-container">
          {buttonsData.map(({ id, name }) => (
            <button
              key={id}
              className={`button ${selectedButton === id ? 'selected' : 'non-selected'}`}
              onClick={() => handleButtonClick(id)}
            >
              {name}
            </button>
          ))}
        </div>
        <div className="shariah-token-analysis-panel">
          <div className="shariah-token-analysis-panel-content">
            <p>
              <div dangerouslySetInnerHTML={{ __html: buttonsData.find(button => button.id === selectedButton)?.text }}></div>
            </p>
          </div>
        </div>
        <div className="shariah-token-analysis-review">
            <p>
              <div dangerouslySetInnerHTML={{ __html: shariahReview }}></div>
            </p>
        </div>

        <div className="shariah-token-analysis-disclaimer">
          <p>{t('shariahDisclaimer')}</p>
        </div>
      </div>
    </div>
  );
}

export default Shariah;
