import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { SENTRY_KEY } from "../src/Utils";
import App from "./App";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "rc-slider/assets/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import "./i18n";

Sentry.init({
  dsn: SENTRY_KEY,
  maxBreadcrumbs: 50,
  autoSessionTracking: false,
  defaultIntegrations: false,
  tracePropagationTargets: [],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Suspense>
    <App />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
