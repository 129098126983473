import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import mawhibaOnboarding from "../../../../Assets/Mawhiba/mawhiba.jpg";
import { WalletIcon } from "../../../../Assets/Images/Icons/SvgIcons";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { useDynamicContext, DynamicWidget, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import "./MawhibaOnboarding.scss";


const MawhibaOnboarding = () => {

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { primaryWallet, setShowDynamicUserProfile } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();

  return (

    <>
    <div className="mawhiba-onboarding-header" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }} >
      <img src={mawhibaOnboarding} alt="Welcome to the Mawhiba Program" className="mawhiba-onboarding-image"/>
      <h2>Mawhiba Incentive Program</h2>
      <h3>Unlock the Gift of Possibilities in Islamic Finance with libfi.io</h3>
      {!isLoggedIn ? (
        <div className="HeaderDynamicWidget">
          <DynamicWidget variant="modal" />
        </div>
      ) : (
        !primaryWallet?.address ? (
          <div className="wallet_not_connected">
            <ButtonCustom
              title={
                <>
                  <WalletIcon />
                  Select A Wallet
                </>
              }
              onClick={() => setShowDynamicUserProfile(true)}
            />
          </div>
        ) : (
          <div className="wallet_not_connected">
            <ButtonCustom
              title="Enter Mawhiba"
              onClick={() => navigate('/mawhiba/overview')}
            />
          </div>
        )
      )}
    </div>

    <div className="mawhiba-onboarding-text-container">
      <div className="mawhiba-onboarding-text">
        <p>Welcome to the innovative world of Libfi! We are thrilled to introduce our groundbreaking Mawhiba Incentive Program. This initiative stands as a testament to our commitment to embracing cutting-edge technology and reflects our unwavering dedication to fostering an inclusive and participatory community.</p>
        <p>At Libfi, we believe in empowering our users with a diverse array of opportunities to grow and succeed. Through the Mawhiba Incentive Program, you will unlock a world of possibilities in Islamic finance, designed to enrich your experience and reward your participation.</p>
        <p>Participate in engaging community tasks by connecting your social platforms and reaching out to your network. By actively contributing to our community, you not only help spread the word about Libfi but also earn rewards through our incentive program. Your involvement is key to building a vibrant and thriving community that benefits everyone.</p>
        <p>Explore the future of decentralized finance with our Decentralized Exchange (DEX), where you can trade with confidence and ease. Dive into our lending pools, offering you the chance to lend and borrow in a secure and Sharia-compliant environment.</p>
        <p>Enhance your knowledge and skills through the Libfi Academy, a comprehensive educational platform that provides valuable insights and learning resources on Islamic finance and decentralized technologies. Whether you are a novice or an expert, our academy is designed to support your continuous growth and development.</p>
        <p>Join us on this exciting journey and be part of a community that values innovation, inclusivity, and growth. The Mawhiba Incentive Program is your gateway to discovering new opportunities, learning, and earning in the dynamic world of Islamic finance.</p>
        <p>Welcome to Libfi. Unlock the gift of possibilities today!</p>
      </div>
    </div>

    </>
  );
};

export default MawhibaOnboarding;