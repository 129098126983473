import React, { useEffect, useState } from "react";
import { useEmbeddedWallet, useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { Container } from "react-bootstrap";
import CommonHeading from "../../../Common/Heading/Heading";
import ButtonCustom from "../../../Common/Button/ButtonCustom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserOnboardingData, } from "../../../../Redux/Slices/user.slice";
import { CloseIcon, PasskeyIcon, PlusIcon, InfiniteSpinner } from "../../../../Assets/Images/Icons/SvgIcons";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { createUserProgress } from "../../../../Redux/Actions/user.action";
import { DATA_INSERTED } from "../../../../Redux/Actions/apiResponseInterfaces";
import "./PasskeyPage.scss";


const PasskeyPage = () => {

  // State variables

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createPasskey, isSessionActive, sendOneTimeCode } = useEmbeddedWallet();
  const { user, isAuthenticated } = useDynamicContext();
  const [verificationCode, setVerificationCode] = useState<string>();
  const [showVerificationCodeModal, setShowVerificationCodeModal] = useState(false);
  const [result, setResult] = useState<string>("");
  const [loader, setLoader] = useState<Boolean>(false);
  const [otpError, setOtpError] = useState<{ status: boolean, message: string }>({ status: false, message: "" });

  
  // Hooks

  /**
  * This UseEffect Hook Redirects the User to the Next Onboarding 
  * Step once the User has successfully Setup a Passkey
  **/
  useEffect(() => {
    if (result) {
      handleSubmit();
    };
  }, [result]);


  // Functions

  /**
  * This Function Sends a Verification Code.
  * If Sent Successfully, the Validation Code Modal will be Displayed.
  **/
  const onSendOneTimeCodeHandler = async () => {
    if(!isSessionActive) {
      try {
          setLoader(true);
           await sendOneTimeCode();
          setLoader(false);
          setShowVerificationCodeModal(true);
      } catch(e) {
        // handle error
      }
    } else {
      onCreatePasskeyHandler();
    }
  }

  /**
  * This Function Handles the Creation of a Passkey
  **/
  const onCreatePasskeyHandler = async () => {
    try {
      if (verificationCode) {
        const otc = verificationCode;

        setShowVerificationCodeModal(false);

        await createPasskey({ oneTimeCode: otc })
        .then((result) => setResult(result))
        .catch((error) => { 
          console.log(JSON.stringify(error, null, 2)); 
          toast.error(t('addPasskeyCodeError')) 
        });
      }
    } catch (err) {
      toast.error(t('addPasskeyCodeError'));
      console.log(err);
    }
  };

  /**
  * This function updates the user profile 
  * and redirects the user to the next page
  **/ 
  const handleSubmit = async ()  => {
    const response: DATA_INSERTED | false = await createUserProgress({
      userId: user?.userId,
      value: 75,
      label: "/onboarding/add-profile",
      dispatch
    });
    if (response) { 
      dispatch(setUserOnboardingData({ progress: 75, path: "/onboarding/add-profile" }));
      navigate("/onboarding/add-profile");
    }
  };


  return (
    <section className="PasskeyPage">
      <Container>
        <CommonHeading
          heading={ `${t('addPasskeyHeader')} ` }
          subText={ `${t('addPasskeySubtext')} ` }
          centered
          className="PasskeyPage-Heading"
        />

        <div className="PasskeyPage-Container">

          <div className="PasskeyPage-Container-Icon">
            <div className="PasskeyPage-Icon">
              <PasskeyIcon />
            </div>
          </div>

          <div className="PasskeyPage-Container-Text">
            <div className="PasskeyPage-Container-item">
              <div className="PasskeyPage-Container-item-title">
                <p className="PasskeyPage-Container-item-title-primary">Convenient and Fast</p>
                <p className="PasskeyPage-Container-item-title-secondary">Use Face ID or Touch ID to complete transactions quickly.</p>
              </div>
            </div>
            <div className="PasskeyPage-Container-item">
              <div className="PasskeyPage-Container-item-title">
                <p className="PasskeyPage-Container-item-title-primary">Increased Security</p>
                <p className="PasskeyPage-Container-item-title-secondary">Passkeys are encrypted end-to-end on your device or password manager to prevent phishing attempts.</p>
              </div>
            </div>
          </div>

          <div className="PasskeyPage-Container-Buttons">
            <div className="PasskeyPage-Container-Buttons-Inner">          
              <ButtonCustom
                title={t('skipPasskeyButton')}
                fluid
                className="bordered-blue"
                onClick={(e) => {
                  handleSubmit();
                }}
              />
              <ButtonCustom
                title={t('addPasskeyButton')}
                className="bordered-blue"
                fluid
                onClick={() => {
                  onSendOneTimeCodeHandler();
                }}
              />
            </div>
          </div>

        </div>
      </Container>

      {/* Display the loading animation */}
      { loader && (
        <div className="passkey-loader">
          <InfiniteSpinner />
        </div>
      )};

      {/* Display the email verification modal */}
      {showVerificationCodeModal && (
        <Modal
          centered
          className="profile_modal"
          backdrop= "static"
          keyboard= { false }
          show={ true }
          onHide={() => {
            setOtpError({ status: false, message: "" });
            setShowVerificationCodeModal(false);
            setVerificationCode("");
          }}
        >
        <Modal.Header>
          <Modal.Title>
            { t('addPasskeyCodeSent') }
          </Modal.Title>
          <button
            className="modal_close_btn"
            onClick={() => {
              setOtpError({ status: false, message: "" });
              setShowVerificationCodeModal(false);
              setVerificationCode("");
            }}
          >
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body> 
          <div className="otp-section">
            <div className="otp-body">
              <div className="otp-input-box otp-box">

                {otpError.status && (
                  <div className="otp-error-msg"> { otpError.message } </div>
                )}

                <div className="otp-input-section">
                  <div className="otp-input-container">
                    <div className="otp-input-container-input">
                      <input
                      placeholder="Enter Verification Code"
                      type="text"
                      className={`verificationcCodeInput`}
                      value={verificationCode}
                      onChange={(e) => {
                        e.preventDefault();
                        setVerificationCode(e.target.value);
                      }}
                      inputMode="decimal"
                      name="fname"
                      autoComplete="off"
                    />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items">
                  <ButtonCustom
                    title={t('addPasskeyClearInput')}
                    className="bordered-blue"
                    fluid
                    onClick={() => {
                      if (otpError.status) { setOtpError({ status: false, message: "" }); };
                      setVerificationCode("");
                    }}
                  />
                  <ButtonCustom
                    title={t('addPasskeyConfirmation')}
                    fluid
                    className="bordered-blue"
                    disabled={ !verificationCode }
                    onClick={() => {
                      onCreatePasskeyHandler();
                    }}
                  />
                </div>

                <div className="otp-resend">
                  <span style={{ marginRight: '5px' }}>{t('addPasskeyCodeNotReceived')}</span> 
                  <a href="javascript:void(0)"
                      onClick={(e) => {
                        toast.success(t('addPasskeySuccessResent'));
                        if (otpError.status) { setOtpError({ status: false, message: "" }); };
                          setVerificationCode("");
                        }}>{t('addPasskeyCodeResent')}
                  </a>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        </Modal>
      )}

    </section>
  );
};

export default PasskeyPage;
