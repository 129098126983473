import { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DownArrow, SearchIconTwo } from "../../../../Assets/Images/Icons/SvgIcons";
import InputCustom from "../Input/Input";
import "./Dropdown.scss";

type CustomDropdown = {
  options: any[],          // List of options available for selection
  selectedOption: any,     // Currently selected option
  onChange: any,           // Function to handle changes in selection
  title?: string,          // Optional title for the select component
  className?: string,      // Optional CSS class for styling
  id?: string,             // Optional id for the select component
  dropIcon?: boolean,      // Optional flag to display a dropdown icon
  labelField?: string,     // Optional field name for option labels
  displayField?: string,   // Optional field name for displaying the option
  valueField?: string,     // Optional field name for the option value
  imgField?: string,       // Optional field name for option icon/image
  lastItem?: any,          // Optional item (jsx component) to be shown at the end of the list
  enableSearch?: boolean   // Optional flag to enable search functionality
};

const CustomDropdown = ({
  options,
  className,
  id,
  dropIcon = false,
  title,
  selectedOption,
  labelField = "label",
  displayField,
  valueField = "value",
  imgField = "img",
  onChange,
  lastItem,
  enableSearch = false
}: CustomDropdown) => {

  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");

  // Filtered Options for searched value
  const [filteredOptions, setFileteredOptions] = useState<any[]>(options);

  /**
   * When the Searching text changes, it sets the filteredOptions to display the filtered results.
   */
  useEffect(() => {
    if (search) {
      setFileteredOptions(options.filter(o => o?.[labelField]?.toLowerCase().includes(search?.toLowerCase())))
    } else {
      setFileteredOptions(options);
    }
  }, [search, options])

  /**
   * Once options is selected it will reset the search and it will emit onChange event with selected option
   * 
   * @param option Selected Option
   */
  const handleChange = (option: any) => {
    setSearch("");
    onChange(option);
  }

  return (
    <>
      <div className={`custom_dropdown ${className || ""}`}>

        {/* If title is available then display it as a label */}
        {title && <Form.Label>{title}</Form.Label>}

        <Dropdown>
          <Dropdown.Toggle id={id}>
            {/* Selected option will be visible with icon if available */}
            {selectedOption && <div className="ddl_option">
              {selectedOption?.[imgField] && <img src={selectedOption?.[imgField]} alt="icon" />}

              {/* If displayField is seperate then the labelField then show displayField value */}
              {displayField ? selectedOption?.[displayField] : selectedOption?.[labelField]}
            </div>}

            {/* Show dropdown arrow if dropIcon is passed as true */}
            {dropIcon && (
              <span className="dropdown_icon">
                <DownArrow />
              </span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              // Show Search Text box at start to search in the dropdown list
              enableSearch && <div className="ddl-search">
                <InputCustom
                  className="ddl-search-input"
                  leftIcon={<SearchIconTwo />}
                  value={search}
                  placeholder={t('selectSearchPlaceholder')}
                  onChange={(e: any) => setSearch(e.target.value)}
                />
              </div>
            }
            <div className="dropdown_menu_inner">
              {filteredOptions?.map((item) => (
                <Dropdown.Item
                  key={item?.[valueField]}
                  onClick={() => handleChange(item)}
                  disabled={item?.[valueField] === selectedOption?.[valueField]}  //Disable the item if that is already selected
                  className={item?.[valueField] === selectedOption?.[valueField] ? "active" : ""} // Add active class to item if that is already selected
                >
                  <div className="ddl_option">{item?.[imgField] && <img src={item?.[imgField]} alt="icon" />} {item[labelField]}</div>
                </Dropdown.Item>
              ))}
            </div>

            {/* Display lastItem provided if any */}
            {lastItem && (
              <div className="menu_last_item">
                <Dropdown.Item>{lastItem}</Dropdown.Item>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default CustomDropdown;
