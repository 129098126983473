import { Breadcrumb  } from '@sentry/react';
import * as Sentry from "@sentry/react";

type SeverityLevel = Breadcrumb['level'];


export const addBreadcrumb = (category: string, message: string, level: SeverityLevel = 'info', data?:{}) => {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
    data
  });
};

export const captureException = (error?) => {
  Sentry.captureException(error);
};

