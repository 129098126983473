import React, { useEffect, useState } from "react";
import { IdleTimerProvider } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { handleUserProgress, createUserProgress, addWallet, removeWallet, removeStorage } from "../../../Redux/Actions/user.action";
import { setUserPersonalisedLock, userDetails, reset, setUserOnboardingData } from "../../../Redux/Slices/user.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { GET_USER, DATA_INSERTED } from "../../../Redux/Actions/apiResponseInterfaces";
import { useDynamicContext, useUserWallets, useSwitchWallet, useEmbeddedWallet, useIsLoggedIn, useDynamicEvents } from "@dynamic-labs/sdk-react-core";
import toast from 'react-hot-toast';
import "./AuthLayout.scss";


const AuthLayout = () => {

  // State variables

  const dispatch: Dispatch<any> = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isAuthenticated, handleLogOut, primaryWallet, setShowDynamicUserProfile, showDynamicUserProfile } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const [lock, setLock] = useState(true);
  const [lockVisitor, setLockVisitor] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const userLockValue: any = useSelector((state: any) => state?.user?.lock);
  const userwalletAddress: any = useSelector( (state: any) => state?.user?.walletAddress );
  const removedWalletAddress: any = useSelector( (state: any) => state?.user?.removedWalletAddress );
  const shariah: any = useSelector( (state: any) => state?.user?.userDetails?.shariah || false );


  // Hooks

  /**
  * This useEffect hook adjusts the lock state of the sidebar based on the window size.
  * If the window width is below 767 pixels, the sidebar is unlocked; 
  * Otherwise, the sidebar is set based on user's profile information and locked for visitors.
  **/
  window.onresize = () => {
    if (document.body.clientWidth < 767) {
      setLock(false);
    } else if (isLoggedIn) {
      setLock(lockVisitor);
      setLockVisitor(lockVisitor);
    } else if (!isLoggedIn) {
      setLock(lockVisitor);
    }
  };

  useEffect(() => {
    if (document.body.clientWidth < 767) {
      setLock(false);
    } else if (isLoggedIn) {
      setLock(lockVisitor);
      setLockVisitor(lockVisitor);
    } else if (!isLoggedIn) {
      setLock(lockVisitor);
    }
  }, []);

  
  /**
  * This useEffect hook handles user inactivity by clearing storage, resetting state, and logging out.
  **/
  const onIdle = () => {
    removeStorage();
    localStorage.clear();
    dispatch(reset());
    handleLogOut();
  };

  /**
  * This useEffect hook manages the sidebar's lock state
  **/
  useEffect(() => {
    if (isLoggedIn) {
      setLock(userLockValue == "false" ? false : true);
      setLockVisitor(userLockValue == "false" ? false : true);
    } else {
      setLock(lockVisitor);
    }
  }, [userLockValue]);

  /**
  * This useEffect hook triggers the verifyUserProgress function 
  * when the user becomes authenticated
  **/
  useEffect(() => {
    if (isAuthenticated) {
      if (user?.userId?.length) {
        verifyUserProgress();
        setTimeout(() => {
          verifyUserProgress();
        }, 2000);
      }
    }
  }, [isAuthenticated, user]);

  /**
  * This useEffect hook manages the information 
  * toast when the user is connected with no selected wallet
  **/
  useEffect(() => {
    if (showDynamicUserProfile && isLoggedIn && !primaryWallet?.address) {
      toast.loading("Please Select One of Your Registered Wallets", {
        id: "pendingWalletSelectionToast",
        style: { fontSize: '16px' }
      });
    }
    if (!showDynamicUserProfile) {
      toast.dismiss("pendingWalletSelectionToast");
    }
  }, [showDynamicUserProfile]);


  // Event Listeners

  /**
  * This event listener triggers when the user adds a new wallet in Dynamic.xyz 
  **/ 
  useDynamicEvents("walletAdded", async (args) => {
    try {
      const result: DATA_INSERTED | false = await addWallet({
        userId: user?.userId,
        address: args?.address,
        dispatch
      }); 
      console.log("Wallet Addition Complete...");
    } catch (error) {
      console.error("Wallet Addition Failed...");
    }    
  });

  /**
  * This useEffect hook handles the removal of the 
  * wallet in the user profile if removed from Dynamic.xyz
  **/ 
  useEffect(() => {
    if (removedWalletAddress) {
      removeWalletFromUserProfile(removedWalletAddress);
    }
  }, [removedWalletAddress]);

  /**
  * This event listener triggers when the user logs out from Dynamic.xyz 
  **/ 
    useDynamicEvents("logout", async () => {
      dispatch(reset());
      console.log("Logout Complete...");
    });


  // Functions

  /**
  * This Function redirects users to the appropriate page based on their profile information.
  * Note: Users who have completed the onboarding process are redirected upon logging in.
  **/
  const verifyUserProgress = async () => {

    // This retrieves the user profile based on the user ID from Dynamic.xyz
    const result: GET_USER | undefined = await handleUserProgress({
      dispatch,
      userId: user?.userId,
      from: "MainL",
    });

    if (result?.message === "No User Found") {
      const verifiedCredentials = user?.verifiedCredentials
      const result: DATA_INSERTED | false = await createUserProgress({
        userId: user?.userId,
        ...( verifiedCredentials?.length === 1 && verifiedCredentials[0].email ? { email: user?.email } : {} ),
        dispatch,
      });
    }

    if (!result?.data?.value) {
      dispatch(setUserOnboardingData({ progress: 0, path: "/onboarding" }));
      navigate("/onboarding");
    } else {
      dispatch(userDetails(result.data));
      dispatch(setUserPersonalisedLock(result?.data?.lock));
      dispatch(setUserOnboardingData({ 
        progress: result?.data?.value, 
        path: result?.data?.label 
      }));
      // This ensures that any user who has completed the onboarding process will not be redirected to the homepage
      if ( result?.data?.label?.includes("onboarding") ) {
        navigate(`${result?.data?.label}`);
      }
    }
  };

  /**
  * This Function allows removing a Wallet Address from the user profile
  **/
  const removeWalletFromUserProfile = async (removedWalletAddress) => {
    try {
      const result: DATA_INSERTED | false = await removeWallet({
        userId: user?.userId,
        address: removedWalletAddress,
        dispatch
      }); 
      console.log("Wallet Removal Complete...");
    } catch (error) {
      console.error("Wallet Removal Failed...");
    }
  };

  
  return (
    <>
      <IdleTimerProvider
        timeout={1000 * 60 * 60 * 4}
        crossTab={true}
        onIdle={onIdle}
      >
        <main
          className={`AuthLayout ${showSidebar ? "activeSidebar" : ""} ${lock ? "active" : ""} ${shariah ? "shariahBackground" : ""}`}
        >
          <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          <div
            onClick={() => setShowSidebar(false)}
            className={`d-md-none d-block ${
              showSidebar ? "show" : ""
            } sidebar_backdrop`}
          />
          <Sidebar lock={lock} setLock={setLock} setShowSidebar={setShowSidebar} setLockVisitor={setLockVisitor} />
          <div className="AuthLayoutBox">
            <div className="AuthLayoutInner">
              <Outlet />
            </div>
          </div>
        </main>
      </IdleTimerProvider>
    </>
  );
};

export default AuthLayout;
