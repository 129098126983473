export interface ReserveHelper {
  tokenOneAddress: string | undefined;
  tokenTwoAddress: string | undefined;
  input1: number;
  input2: number;
  selectedField: String;
  max?: boolean;
  dispatch: Function;
}

export interface newReserveHelper {
  pairAddress: string;
  tokenOneAddress: string | undefined;
  decimals0: number;
  tokenTwoAddress: string | undefined;
  decimals1: number;
  input1: number;
  input2: number;
  selectedField: string;
  max?: boolean;
}


export interface TOKEN_DETAILS {
  isToken0Native: boolean;
  isToken1Native: boolean;
  token0Address: string;
  token1Address: string;
  token0Name?: string;
  token1Name?: string;
}

export interface TOKEN_DETAILS_SINGLE {
  isTokenNative: boolean;
  tokenAddress: string;
  tokenName?: string;
}

export enum Status {
    Pending = 'pending',
    Complete = 'complete',
}

export type StatusCheck = Status | "";

export interface CHECK_STATE {
  status: StatusCheck;
  outcome: string;
}

export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

export interface createUserData {
  value?: number;
  label?: string;
  userId?: string;
  address?: string;
  email?: string;
  name?: string;
  firstname?: string;
  lastname?: string;
  birthDate?: Value;
  embeddedWallet?: boolean;
  kyc?: { isCompleted?: boolean, id?: string, documents?: { documentCheck?: { id?: string, status?: StatusCheck, outcome?: string }, enhancedIdCheck?: { id?: string, status?: StatusCheck, outcome?: string } } };
  passkey?: boolean;
  dispatch: any;
  image?: string;
  termsncond?: boolean;
  slippage?: string;
  deadlineInMin?: string;
  signature?: string;
  language?: string;
  shariah?: boolean;
}
export interface TOKENS {
  name?: string | undefined;
  symbol: string | undefined;
  address: string | undefined;
  abi: any;
  img?: string | undefined;
  isSelected?: boolean;
  isNative?: boolean;
  id?: string | undefined;
  decimals?: number | undefined;
}
export interface CALLDATA {
  amountIn: string;
  amountOutMin: string;
  tokenOneAddress: string;
  tokenTwoAddress: string;
  to: string;
  deadline: string;
}
export interface GET_AMOUNTS_DATA {
  token0Address: string;
  token1Address: string;
  amountIn: bigint;
  dispatch: any;
  max?: boolean;
}

export interface COMMON_DATA {
  tokenOneAddress: string | undefined;
  tokenTwoAddress: string | undefined;
  dispatch: any;
}

export interface MY_ASSETS { 
  token0Name: string, 
  token1Name?: string, 
  price: number,
  balance: number,
  value: number,
  change: number,
  img0: string | undefined,
  img1?: string | undefined,
  isLPToken?: boolean,
  isShariahCompliant?: boolean
}

export interface TIME_LEFT {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface commonDynamicSwapTokens {
  tokenDetails: TOKEN_DETAILS;
  input1: { convertedValue: bigint; inputValue: string; };
  input2: { convertedValue: bigint; inputValue: string; };
  walletAddress: string;
  selectedField: string;
  t: (key: string) => string;
}

export interface amanaVaultAllowance {
  tokenDetails: TOKEN_DETAILS_SINGLE;
  inputValue: string;
  walletAddress: string;
  amanaVaultAddress: string;
  t: (key: string) => string;
}
