import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import Loader from "./Components/Common/Loader/Loader";
import { DynamicContextProvider, FilterChain } from '@dynamic-labs/sdk-react-core';
import { removedWalletAddress } from "./Redux/Slices/user.slice";
import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DYNC_ENV_KEY } from "../src/Utils";
import { useTranslation } from "react-i18next";
import { router } from "../src/router"
import { WagmiConfig } from "./WagmiConfig";



const Application: React.FC = () => {
  
  {/*
  const manageCache = async () => {
    try {
      const latest_version: string = packageJson?.version;
      const old_libfi_version: string | null =
        localStorage?.getItem("libfi_version");
      console.log(
        "latest_version==>",
        latest_version,
        "old_version==>",
        old_libfi_version
      );
      if (old_libfi_version && latest_version != old_libfi_version) {
        // localStorage.clear();
          (reset());
        removeStorage();
        // window.location.reload();
      }
      if (!old_libfi_version) {
        localStorage?.setItem("libfi_version", latest_version);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    manageCache();
  }, []);
  */}


  // Dynamic.xyz Setup
  const dynamicEnvId: string = DYNC_ENV_KEY;
  const { t, i18n } = useTranslation();
  const theme = useSelector((state: any) => state?.theme?.theme);
  const [cssOverrides, setCssOverrides] = useState('');
  

  useEffect(() => {
    document.body.className = `theme-${theme}`;
  }, [theme]);

  useEffect(() => {
      const language = i18n?.languages[0];
      generateCssOverrides(language);
  }, [ i18n.languages ]);


  // Function to generate CSS overrides based on current language
  const generateCssOverrides = (language) => {
    let overrides = ` .dynamic-shadow-dom { z-index: 9999; }
    
                      .dynamic-footer, .powered-by-dynamic { display: none; }
                      .tos-and-pp__footer { display: none; } 
                      
                      .dynamic-shadow-dom-content {

                        .input__container .input {
                          font-size: 1.5rem;
                        }

                        .input__container--dense .input:placeholder-shown~.input__label {
                          font-size: 1.5rem;
                        }

                        .input__container--dense .input:focus~.input__label, .input__container .input__label {
                          font-size: 1.0rem;
                          top: 0.1rem;
                        }

                        .qr-code-wrapper__scan-issue-button {
                          font-size: unset;
                        }
                        
                        .header__icon svg {
                          height: 2rem;
                          width: 2rem;
                        }

                        .text-button {
                          font-size: 1.5rem;
                        }

                        .header__tooltip {
                          font-size: 1.2rem;
                        }

                        .badge__dot {
                          width: unset;
                        }

                        .badge__container {
                          font-size: 1.0rem;
                        }

                        .balance-container {
                          display: none;
                        }

                        .dynamic-widget-wallets {                      
                          height: 100%;
                        }
                        
                        .options-switcher dynamic-widget-header__switcher {
                          display: none;
                        }

                        .dynamic-widget-header__switcher {
                          display: none;
                        }

                        .wallet__menu {
                          opacity: 100%;
                        }

                        .dynamic-widget-wallet-header__wallet-actions {
                          display: none;
                        }

                        .dynamic-widget-wallet-header__wallet-info {
                          display: none;
                        }

                        .checkbox__container {
                          height: 3rem;
                          width: 3rem;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }

                        .checkbox__icon, .checkbox--visible {
                          height: 2.0rem;
                          width: 2.0rem;
                        }

                        .dynamic-widget-inline-controls {
                          display: flex;
                          align-items: center;
                          justify-content: center;

                          img {
                            height: 2rem !important;
                            width: 2rem !important;
                          }
                        }

                        .dynamic-widget-inline-controls__network-picker {
                          margin-right: 0.5rem;
                        } 

                        .network-picker-network-control__anchor {
                          padding-top: 0px;
                        }

                        .network__container {
                          display: flex;Fcl
                          align-items: center;
                          height: 100%;
                        }

                        .dynamic-widget-inline-controls__network-picker-checkbox {
                          display: none;
                        }

                        .network__title-copy {
                          font-size: 1.2rem;
                        }

                        .dynamic-widget-modal {
                          transform: translate(-50%,-50%);
                          min-width: 35rem;
                          width: unset;

                          .active-wallet-information {
                            display: none;
                          }

                          .divider__full-width, .detected-new-wallet-modal__log-out-button {
                            display: none;
                          }
                        }

                        .wallet__container:not(:last-child) {
                          border-bottom-left-radius: calc(var(--dynamic-border-radius) / 2);
                          border-bottom-right-radius: calc(var(--dynamic-border-radius) / 2);
                          padding-bottom: 0.75rem;
                          margin-bottom: 5px;
                        }

                        .connect-button {
                          margin-right: 10px;
                        }

                        .primary-not-connected__log-out-button {
                          display: none;
                        }

                        .typography--body-normal {
                          color: white;
                        }

                        .modal {
                          .sign-message-confirmation__message-container { max-height: none; margin-bottom: 1rem; }
                          .text-button { font-size: 1.2rem; }
                          .modal-card { border: 1px solid #332e55 }

                          .wallet-used-view__logout {
                            display: none;
                          }

                          .icon-with-spinner__container {
                            height: 8rem !important;
                            width: 8rem !important;

                            .icon-with-spinner__icon-container {
                                height: 80% !important;
                                width: 80% !important;

                                svg {
                                    height: 100%;
                                    width: 100%;
                                }
                            }

                            .icon-with-spinner__animation {
                              height: 8rem !important;
                              width: 8rem !important;

                              svg {
                                height: 4rem !important;
                                width: 4rem !important;
                              }
                            }
                          }
                        }

                        @media (max-width: 640px) {
                          .modal {
                              top: 50% !important;
                              transform: translate(-50%, -50%) !important;
                          }

                          .modal-card--sharp-mobile-bottom-radius:last-child {
                            border-radius: var(--dynamic-border-radius) !important;
                          }
                        }

                      }
                    `;

    // Example of css overrides for Arabic (placeholders, icons, ect.)
    if ( language === 'ar' ) {
      overrides += 
      `
        /* Right-align the email address input's placeholder */
        .input__container--dense .input:placeholder-shown~.input__label {
          text-align: right;
          right: .75rem;
        }

        /* Right-align wallet names */
        .list-tile__children {
          text-align: right;
        }

        .list-tile-animated-trailing__child {
          right: unset;
          left: 0;
        }

        .list-tile-animated-trailing__hover-element {
          right: unset;
          left: 0;

          /* Rotate hover SVG */
          svg {
            transform: rotate(180deg);
          }
        }

        .list-item-button__trailing-child {
          right: unset;
          left: 0;        
        }

        /* Rotate hover SVG inside "View All Wallets" */
        .list-item-button__trailing {
          svg {
            transform: rotate(180deg);
          }
        }

        /* Rotate hover SVG the "Select Your Wallet" dropdown */
        .modal-header__leading {
          svg {
            transform: rotate(180deg);
          }
        }

      `;
    }

    setCssOverrides(overrides);
  };

  const queryClient = new QueryClient();
  const dispatch: any = useDispatch();

  return (
    <>
      <DynamicContextProvider
        theme='dark' // Theme can be light or dark
        settings={{
          environmentId: dynamicEnvId,
          walletConnectors: [ EthereumWalletConnectors ],
          walletsFilter: FilterChain('EVM'), // Only display EVM compatible wallets
          recommendedWallets: [
            { walletKey: "metamask" }
          ],
          privacyPolicyUrl: "/privacy-policy",
          termsOfServiceUrl: "/terms-of-service",
          cssOverrides: cssOverrides,
          events: {
            onAuthSuccess: (args) => {
              console.log("Login/Sign In Complete...");
              //console.log("onAuthSuccess was called", args);
            },
            onLogout: (args) => {
              //console.log('onLogout was called', args);
            },
            onWalletRemoved: async (args) => {
              const walletAddress = args.wallet.address;
              dispatch(removedWalletAddress(walletAddress));             
            },
            onAuthFlowCancel: () => {
              console.log("Authentication Cancelled...");
            },
            onAuthFailure: (method, reason) => {
              if (reason === "user-cancelled") {
                console.log("Validation Request Cancelled...");
              } else {
                console.log("Authentication Failed...")
              }
            }
          },
      }}>
        <WagmiProvider config={WagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <DynamicWagmiConnector>
              <RouterProvider router={router} fallbackElement={<Loader />} />
            </DynamicWagmiConnector>
          </QueryClientProvider>
        </WagmiProvider>
      </DynamicContextProvider>
    </>
  );
};

export default Application;
